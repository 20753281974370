import React, { useState } from "react";
import Sidebar from "../Chat/Sidebar";
import { Table } from "react-bootstrap";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const TransactionMessage = () => {
    const navigate = useNavigate();

    
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleItemClick = (action) => {
        console.log(action);
        setDropdownVisible(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/');
    };

    return (
        <>
            <Sidebar />
            <div id="main">
            <div className="container-fluid bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong>Transaction Message</strong></h3>
                        </div>
                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <div style={{ position: 'relative' }}>
                                <MdAccountCircle
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisible && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div>
                                        <div onClick={handleLogout} className="dropdown-item">
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>SerialNo</th>
                            <th>GroupId</th>
                            <th>Template Name</th>
                            <th>Action</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Id</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default TransactionMessage;
