import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { BsGraphUpArrow } from "react-icons/bs";
import { IoIosArrowDroprightCircle, IoMdArrowDropright, IoMdContact, IoMdPerson } from 'react-icons/io';
import { RiMessage2Fill, RiWechat2Fill, RiWechatFill } from "react-icons/ri";
import { MdCreate, MdSettings, MdViewInAr } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { RiShieldUserFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { MdWarehouse } from "react-icons/md";
import A1 from '../Images/logo-w.png';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { GoPlus } from "react-icons/go";
import Chat from './Chat';
import { TbArrowBadgeRightFilled, TbMessage2 } from "react-icons/tb";
import { Tooltip } from 'react-tooltip'
import { FaSackDollar } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi";
import { TbAdjustments } from "react-icons/tb";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { HiTemplate } from "react-icons/hi";


const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [username, setusername] = useState(true);
    // const [usertype, setusertype] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const type = sessionStorage.getItem('username');
        setusername(type);
    }, []);

    const usernames = sessionStorage.getItem('username')
    console.log(usernames)

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');

        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        navigate('/');
    };
    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    /////////////////////////////////////////////////////////////////

    const [contacts, setContacts] = useState([]); // State for storing contacts
    const [showModal, setShowModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(); // State for dropdown visibility
    const [dropdownOpenn, setDropdownOpenn] = useState();
    const handleModalShow = () => setShowModal(true);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [groups, setGroups] = useState([]); // Array to hold group data
    const [mobile, setMobile] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]); // State for selected contacts
    const [openGroupId, setOpenGroupId] = useState(null); // State to track which group dropdown is open

    const fetchContacts = async () => {
        const response = await fetch('https://demochatapi.emedha.in/api/contacts');
        const data = await response.json();
        setContacts(data);
    };
    console.log(contacts)
    useEffect(() => {
        fetchContacts();
    }, []);

    const [adminUsers, setAdminUsers] = useState([]);
    const [groupsId, setGroupsId] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedAdminUserId, setSelectedAdminUserId] = useState('');

    useEffect(() => {
        const fetchGroupsId = async () => {
            try {
                const response = await fetch('https://demochatapi.emedha.in/api/groups'); // Adjust your API endpoint
                const data = await response.json();
                console.log('Fetched groups:', data); // Log the fetched data

                // Ensure you're setting groupsId to an array
                if (Array.isArray(data)) {
                    setGroupsId(data);
                } else if (data && Array.isArray(data.groups)) { // If your data is wrapped
                    setGroupsId(data.groups);
                } else {
                    console.error('Expected an array but got:', data);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroupsId();
    }, []);


    useEffect(() => {
        const fetchAdminUsers = async () => {
            const response = await fetch('https://demochatapi.emedha.in/api/departuser'); // Adjust your API endpoint
            const data = await response.json();
            setAdminUsers(data);
        };


        fetchAdminUsers();
    }, []);


    console.log(groupsId);
    console.log(adminUsers);

    const handleModalClose = () => {
        setShowModal(false);

    };
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
    };
    const toggleDropdownn = () => {
        setDropdownOpenn(!dropdownOpenn); // Toggle dropdown visibility
    };

    const fetchGroups = async () => {
        const response = await fetch('https://demochatapi.emedha.in/api/groups');
        const data = await response.json();
        setGroups(data.groups);

    };

    useEffect(() => {
        fetchGroups();
    }, []);

    const [userIds, setUserIds] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');

    useEffect(() => {
        const fetchUserIds = async () => {
            const response = await fetch('https://demochatapi.emedha.in/api/departuser');
            const data = await response.json();
            setUserIds(data); // Adjust according to the structure of your API response
        };

        fetchUserIds();
    }, []);

    console.log(userIds);
    const toggleContactSelection = (contact) => {
        setSelectedContacts(prevSelected => {
            if (prevSelected.includes(contact)) {
                return prevSelected.filter(c => c !== contact); // Remove if already selected
            } else {
                return [...prevSelected, contact]; // Add if not selected
            }
        });
    };

    const openChat = (contact) => {
        navigate(`/chat/${contact.id}`); // Navigate to chat with the contact's ID
    };



    const toggleGroupDropdown = (groupId) => {
        setOpenGroupId(openGroupId === groupId ? null : groupId);
    };

    const handleGroupClick = (groupId) => {
        navigate(`/groupchat/${groupId}`); // Navigate to the group chat
    };

    const userData = JSON.parse(localStorage.getItem('userData'));
    const loginEmail = userData ? userData.email : null; // Assuming 'email' is the key where you store the login email

    const userId = localStorage.getItem('userId')
    const userIdInt = parseInt(userId, 10);
    const token = localStorage.getItem('token');

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <>
            <div id="mySidebar" className={`sidebar ${isOpen ? '' : 'closed'}`}  >

                <div className="sidebar-header "  >
                    <h3> <img src={A1} style={{ height: '60px', padding: '14px' }} /></h3>
                    <button className="toggle-btn " onClick={toggleNav}> <TbArrowBadgeRightFilled style={{ backgroundColor: 'transparent', fontSize: '20px' }} />
                        <i className="fas fa-bars"></i>
                    </button>


                </div>

                {usernames !== userIdInt && token && (
                    <>

                        <div className="tooltip-container" >
                            <a href="/dashboard">
                                <i><BsGraphUpArrow /></i>
                                <span>Dashboard</span>
                            </a>
                            {!isOpen && <span className="tooltip">Dashboard</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/addAdmin">
                                <i><IoMdContact /></i>
                                <span> Admins</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Admins</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/credits">
                                <i><FaSackDollar /></i>
                                <span> Credits</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Credits</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/transaction">
                                <i><FaMoneyBillTransfer /></i>
                                <span> Transaction</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Transaction</span>}
                        </div>

                        <div className="custom-tooltip-dropdown-container">
                            <div className="tooltip-container" >
                                <a onClick={() => setIsDropdownOpen(!isDropdownOpen)} className=" custom-tooltip-trigger">
                                    <i><HiTemplate /></i>
                                    <span>Template  <IoMdArrowDropright style={{ fontSize: '35px' }} className={isDropdownOpen ? 'rotate' : ''} /></span>
                                </a>
                                {!isOpen && <span className="tooltip">Template</span>}
                            </div>
                            {isDropdownOpen && (
                                <div className="custom-dropdown-menu ">
                                   
                                    <div className="tooltip-container">
                                        <a href='/create' className="custom-dropdown-item" >
                                            <i><MdCreate /></i><span>Create</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">Create</span>}
                                    </div>
                                  
                                    <div className="tooltip-container">
                                        <a href='/view' className="custom-dropdown-item" >
                                            <i><MdViewInAr /></i><span>View</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">View</span>}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tooltip-container">
                            <a href="/transactionMessage">
                                <i><TbMessage2 /></i>
                                <span> Transaction Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  Transaction <br></br>Message</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/message">
                                <i><TbMessage2 /></i>
                                <span> Message</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Message</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/sendUserMessage">
                            <i> <RiWechat2Fill /></i> 
                                <span> SendUser Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendUser <br></br>Message</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/sendMGR">
                                <i><RiWechatFill /></i>
                                <span> SendManager Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendManager <br></br>Message</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href='/manageGroups' onClick={toggleDropdownn}><i ><HiUserGroup /></i> <span> Group</span></a>{!isOpen && <span className="tooltip"> Group</span>}

                        </div>


                        <div className="tooltip-container">
                            <a href='/manageContacts' onClick={toggleDropdown}><i ><RiMessage2Fill /></i> <span> Contact</span> </a>{!isOpen && <span className="tooltip"> Contact</span>}

                        </div>


                        <div className="tooltip-container">
                            <a href="/forgotpassword"><i className="fas fa-envelope"><TbAdjustments /></i> <span>  Password</span></a>{!isOpen && <span className="tooltip">  Password</span>}
                        </div>
                        
                    </>
                )}

                {usernames === userIdInt && (

                    <>
                        <div className="tooltip-container">
                            <a href="/dashbord">
                                <i><BsGraphUpArrow /></i>
                                <span>Dashboard</span>
                            </a>
                            {!isOpen && <span className="tooltip">Dashboard</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/transaction">
                                <i><FaMoneyBillTransfer /></i>
                                <span> Transaction</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Transaction</span>}
                        </div>

                        <div className="custom-tooltip-dropdown-container">
                            <div className="tooltip-container" >
                                <a onClick={() => setIsDropdownOpen(!isDropdownOpen)} className=" custom-tooltip-trigger">
                                    <i><HiTemplate /></i>
                                    <span>Template  <IoMdArrowDropright style={{ fontSize: '35px' }} className={isDropdownOpen ? 'rotate' : ''} /></span>
                                </a>
                                {!isOpen && <span className="tooltip">Template</span>}
                            </div>
                            {isDropdownOpen && (
                                <div className="custom-dropdown-menu ">
                                    {/* onClick={() => console.log('Create clicked')} */}
                                    <div className="tooltip-container">
                                        <a href='/create' className="custom-dropdown-item" >
                                            <i><MdCreate /></i><span>Create</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">Create</span>}
                                    </div>
                                    {/* onClick={() => console.log('View clicked')} */}
                                    <div className="tooltip-container">
                                        <a href='/view' className="custom-dropdown-item" >
                                            <i><MdViewInAr /></i><span>View</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">View</span>}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="tooltip-container">
                            <a href="/message">
                                <i><TbMessage2 /></i>
                                <span> Message</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Message</span>}
                        </div>


                        <div className="tooltip-container">
                            <a href='/manageGroups' onClick={toggleDropdownn}><i ><HiUserGroup /></i> <span> Group</span></a>{!isOpen && <span className="tooltip"> Group</span>}

                        </div>

                        <div className="tooltip-container">
                            <a href='/manageContacts' onClick={toggleDropdown}><i ><RiMessage2Fill /></i> <span> Contact</span> </a>{!isOpen && <span className="tooltip"> Contact</span>}

                        </div>
                        <div className="tooltip-container">
                            <a href="/sendUserMessage">
                            <i> <RiWechat2Fill /></i> 
                                <span> SendUser Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendUser <br></br>Message</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/sendMGR">
                                <i><RiWechatFill /></i>
                                <span> SendManager Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendManager <br></br>Message</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/adminforgotPassword"><i className="fas fa-envelope"><TbAdjustments /></i> <span> Change Password</span></a>{!isOpen && <span className="tooltip"> Change Password</span>}
                        </div>
                       
                    </>
                )}

                {usernames !== userIdInt && !token && (
                    <>
                        <div className="tooltip-container">
                            <a href="/dashbord">
                                <i><BsGraphUpArrow /></i>
                                <span>Dashboard</span>
                            </a>
                            {!isOpen && <span className="tooltip">Dashboard</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/transaction">
                                <i><FaMoneyBillTransfer /></i>
                                <span> Transaction</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Transaction</span>}
                        </div>
                        <div className="custom-tooltip-dropdown-container">
                            <div className="tooltip-container" >
                                <a onClick={() => setIsDropdownOpen(!isDropdownOpen)} className=" custom-tooltip-trigger">
                                    <i><HiTemplate /></i>
                                    <span>Template  <IoMdArrowDropright style={{ fontSize: '35px' }} className={isDropdownOpen ? 'rotate' : ''} /></span>
                                </a>
                                {!isOpen && <span className="tooltip">Template</span>}
                            </div>
                            {isDropdownOpen && (
                                <div className="custom-dropdown-menu ">
                                  
                                    <div className="tooltip-container">
                                        <a href='/create' className="custom-dropdown-item" >
                                            <i><MdCreate /></i><span>Create</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">Create</span>}
                                    </div>
                                 
                                    <div className="tooltip-container">
                                        <a href='/view' className="custom-dropdown-item" >
                                            <i><MdViewInAr /></i><span>View</span>
                                        </a>
                                        {!isOpen && <span className="tooltip">View</span>}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tooltip-container">
                            <a href="/message">
                                <i><TbMessage2 /></i>
                                <span> Message</span>
                            </a>

                            {!isOpen && <span className="tooltip"> Message</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href='/manageGroups' onClick={toggleDropdownn}><i ><HiUserGroup /></i> <span>Manage Group</span></a>{!isOpen && <span className="tooltip">Manage Group</span>}

                        </div>
                        <div className="tooltip-container">
                            <a href='/manageContacts' onClick={toggleDropdown}><i ><RiMessage2Fill /></i> <span>Manage Contact</span> </a>{!isOpen && <span className="tooltip">Manage Contact</span>}

                        </div>
                        <div className="tooltip-container">
                            <a href="/sendUserMessage">
                            <i> <RiWechat2Fill /></i> 
                                <span> SendUser Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendUser <br></br>Message</span>}
                        </div>

                        <div className="tooltip-container">
                            <a href="/sendMGR">
                                <i><RiWechatFill /></i>
                                <span> SendManager Message</span>
                            </a>

                            {!isOpen && <span className="tooltip">  SendManager <br></br>Message</span>}
                        </div>
                        <div className="tooltip-container">
                            <a href="/adminforgotPassword"><i className="fas fa-envelope"><TbAdjustments /></i> <span> Change Password</span></a>{!isOpen && <span className="tooltip"> Change Password</span>}
                        </div>
                        </>
                )}
            </ div>
        </>
    );
};
export default Sidebar;
