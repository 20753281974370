// import React, { useEffect, useState } from 'react';
// import './View.css';
// import Sidebar from '../Chat/Sidebar';
// import { FaLock, FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
// import { MdAccountCircle } from 'react-icons/md';
// import { IoIosNotifications } from 'react-icons/io';
// import { useNavigate } from 'react-router-dom';
// import Pagination from 'react-bootstrap/Pagination';

// const View = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [modalVisible, setModalVisible] = useState(false);
//     const [templateDetails, setTemplateDetails] = useState(null);
    
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 5;
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('https://emedha.com/echatbot/get-template-status.php?limit=10');
//                 if (!response.ok) throw new Error('Network response was not ok');
//                 const result = await response.json();
//                 setData(result.data || []);
//             } catch (error) {
//                 setError(error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };

//     const handleLogout = () => {
//         localStorage.clear();
//         sessionStorage.removeItem('username');
//         navigate('/');
//     };

//     const fetchTemplateDetails = async (name) => {
//         try {
//             const response = await fetch(`https://emedha.com/echatbot/get-template.php?name=${name}`);
//             if (!response.ok) throw new Error('Network response was not ok');
//             const result = await response.json();
//             const template = result.data ? result.data.find(item => item.name === name) : null;
//             if (template) {
//                 setTemplateDetails(template);
//                 setModalVisible(true);
//             } else {
//                 console.log('Template not found');
//                 setTemplateDetails(null);
//             }
//         } catch (error) {
//             console.error('Error fetching template details:', error);
//             setError(error);
//         }
//     };

//     const closeModal = () => {
//         setModalVisible(false);
//         setTemplateDetails(null);
//     };

//     const totalPages = Math.ceil(data.length / itemsPerPage);
//     const handlePageChange = (page) => {
//         if (page < 1 || page > totalPages) return;
//         setCurrentPage(page);
//     };

//     const renderPaginationItems = () => {
//         const items = [];
//         items.push(
//             <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//                 &lt;
//             </Pagination.Item>
//         );

//         const pageRange = 1;
//         const startPage = Math.max(1, currentPage - pageRange);
//         const endPage = Math.min(totalPages, currentPage + pageRange);

//         for (let i = startPage; i <= endPage; i++) {
//             items.push(
//                 <Pagination.Item
//                     key={i}
//                     active={i === currentPage}
//                     onClick={() => handlePageChange(i)}
//                 >
//                     {i}
//                 </Pagination.Item>
//             );
//         }

//         items.push(
//             <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//                 &gt;
//             </Pagination.Item>
//         );

//         return items;
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

//     if (loading) return <div>Loading...</div>;
//     if (error) return <div>Error: {error.message}</div>;

//     const replacePlaceholders = (text, values) => {
//         const parts = text.split(/(\d+)/g); // Split by placeholder pattern
//         return parts.map((part, index) => {
//             const match = part.match(/(\d+)/);
//             if (match) {
//                 const placeholderIndex = parseInt(match[1], 10) - 1; // Convert to zero-based index
//                 return (
//                     <strong key={index}>
//                         {values[placeholderIndex] || part} {/* Keep the original placeholder if value is undefined */}
//                     </strong>
//                 );
//             }
//             return (
//                 <span key={index}>
//                     {part} {/* Render regular text */}
//                 </span>
//             );
//         });
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id='main'>
//                 <div className="bg-light shadow">
//                     <div className="row justify-content-between align-items-center">
//                         <div className="col-auto">
//                             <h3 className="mt-2"><strong>View Template</strong></h3>
//                         </div>
//                         <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//                             <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
//                             <div style={{ position: 'relative' }}>
//                                 <MdAccountCircle
//                                     size={30}
//                                     onClick={toggleDropdown}
//                                     style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
//                                 />
//                                 {dropdownVisible && (
//                                     <div className="dropdown-menu show" style={{
//                                         position: 'absolute',
//                                         right: 0,
//                                         backgroundColor: 'white',
//                                         border: '1px solid #ccc',
//                                         boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                                         zIndex: 1,
//                                     }}>
//                                         <div onClick={() => console.log('Edit Profile')} className="dropdown-item">
//                                             <FaUserEdit style={{ marginRight: '8px' }} />
//                                             Edit Profile
//                                         </div>
//                                         <div onClick={() => console.log('Change Password')} className="dropdown-item">
//                                             <FaLock style={{ marginRight: '8px' }} />
//                                             Change Password
//                                         </div>
//                                         <div onClick={handleLogout} className="dropdown-item">
//                                             <FaSignOutAlt style={{ marginRight: '8px' }} />
//                                             Logout
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <table>
//                     <thead>
//                         <tr>
//                             <th>Id</th>
//                             <th>Name</th>
//                             <th>Status</th>
//                             <th>View Template</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {currentItems.map((item, index) => (
//                             <tr key={item.id}>
//                                 <td>{indexOfFirstItem + index + 1}</td>
//                                 <td>{item.name}</td>
//                                 <td>{item.status}</td>
//                                 <td>
//                                     <button
//                                         style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}
//                                         onClick={() => fetchTemplateDetails(item.name)}
//                                     >
//                                         View Template
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>

//                 <Pagination className='d-flex flex-row justify-content-end'>
//                     {renderPaginationItems()}
//                 </Pagination>

//                 {/* Modal for Template Details */}
//                 {modalVisible && (
//                     <div style={modalStyles}>
//                         <div className="modal-content">
//                             <span className="close" onClick={closeModal}>&times;</span>
//                             {templateDetails ? (
//                                 <div>
//                                     <h2>Template Details</h2>
//                                     <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//                                         <tbody>
//                                             <tr>
//                                                 <td><strong>Template Name:</strong></td>
//                                                 <td>{templateDetails.name}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td><strong>Template Status:</strong></td>
//                                                 <td>{templateDetails.status}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td><strong>Language:</strong></td>
//                                                 <td>{templateDetails.language}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td><strong>Message:</strong></td>
//                                                 <td>
//                                                 {templateDetails.components && Array.isArray(templateDetails.components) ? (
//                                                     templateDetails.components.map((component, index) => (
//                                                         <div key={index}>
//                                                             {component.type === "BODY" && (
//                                                                 <span>
//                                                                     {replacePlaceholders(component.text, component.example.body_text[0])}
//                                                                 </span>
//                                                             )}
//                                                             {component.type === "BUTTONS" && (
//                                                                 <span>
//                                                                     {component.buttons && Array.isArray(component.buttons) ? (
//                                                                         component.buttons.map((button, btnIndex) => (
//                                                                             <span key={btnIndex} style={{ marginRight: '10px' }}>
//                                                                                 {button.type === "URL" ? (
//                                                                                     <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
//                                                                                         {button.text}
//                                                                                     </a>
//                                                                                 ) : (
//                                                                                     <a href={`tel:${button.phone_number}`} style={{ textDecoration: 'none', color: 'blue' }}>
//                                                                                         {button.text}
//                                                                                     </a>
//                                                                                 )}
//                                                                             </span>
//                                                                         ))
//                                                                     ) : null}
//                                                                 </span>
//                                                             )}
//                                                         </div>
//                                                     ))
//                                                 ) : (
//                                                     <span>No body available</span>
//                                                 )}
//                                                 </td>
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             ) : (
//                                 <p>Loading details...</p>
//                             )}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };

// // Basic styles for the modal
// const modalStyles = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 1000,
// };

// export default View;



import React, { useEffect, useState } from 'react';
import './View.css';
import Sidebar from '../Chat/Sidebar';
import { FaLock, FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
import { MdAccountCircle } from 'react-icons/md';
import { IoIosNotifications } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';

const View = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [templateDetails, setTemplateDetails] = useState(null);
    
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://emedha.com/echatbot/get-template-status.php?limit=10');
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                setData(result.data || []);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.removeItem('username');
        navigate('/');
    };

    const fetchTemplateDetails = async (name) => {
        try {
            const response = await fetch(`https://emedha.com/echatbot/get-template.php?name=${name}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            const template = result.data ? result.data.find(item => item.name === name) : null;
            if (template) {
                setTemplateDetails(template);
                setModalVisible(true);
            } else {
                console.log('Template not found');
                setTemplateDetails(null);
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
            setError(error);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setTemplateDetails(null);
    };

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
    };

    const renderPaginationItems = () => {
        const items = [];
        items.push(
            <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                &lt;
            </Pagination.Item>
        );

        const pageRange = 1;
        const startPage = Math.max(1, currentPage - pageRange);
        const endPage = Math.min(totalPages, currentPage + pageRange);

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                &gt;
            </Pagination.Item>
        );

        return items;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const replacePlaceholders = (text, values) => {
        const parts = text.split(/(\d+)/g);
        return parts.map((part, index) => {
            const match = part.match(/(\d+)/);
            if (match) {
                const placeholderIndex = parseInt(match[1], 10) - 1;
                return (
                    <strong key={index}>
                        {values[placeholderIndex] || part}
                    </strong>
                );
            }
            return (
                <span key={index}>
                    {part}
                </span>
            );
        });
    };

    return (
        <>
            <Sidebar />
            <div id='main'>
            <div className="container-fluid bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong>View Template</strong></h3>
                        </div>
                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <div style={{ position: 'relative' }}>
                                <MdAccountCircle
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisible && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        <div onClick={() => console.log('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => console.log('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div>
                                        <div onClick={handleLogout} className="dropdown-item">
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Id</th>
                            <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Name</th>
                            <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Status</th>
                            <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>View Template</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={item.id}>
                                <td>{indexOfFirstItem + index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.status}</td>
                                <td>
                                    <Button style={{ backgroundColor: 'rgb(125, 133, 195)'}}
                                        variant="primary"
                                        onClick={() => fetchTemplateDetails(item.name)}
                                    >
                                        View Template
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Pagination className='d-flex flex-row justify-content-end'>
                    {renderPaginationItems()}
                </Pagination>

                {/* Bootstrap Modal for Template Details */}
                <Modal size='lg' show={modalVisible} onHide={closeModal}>
    <Modal.Header closeButton>
        <Modal.Title>Template Details</Modal.Title>
    </Modal.Header>
    <Modal.Body >
        {templateDetails ? (
         
         <table >
         <tbody>
            <thead>
                <tr>
                    <th style={{ border: '1px solid #ddd'}}>
                    <strong>Template Name:</strong>
                   </th>
                   <th style={{ border: '1px solid #ddd'}}>
                    <strong>Language:</strong>
                    </th>
                    <th  style={{ border: '1px solid #ddd'  }}>
                    <strong>Message:</strong>
                    </th>
                   
                </tr>
            </thead>
            <tbody>
            
                
                 <td style={{ border: '1px solid #ddd', padding: '8px' }} >
                     {templateDetails.name}
                 </td>
             
             
                                  
                 <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                     {templateDetails.language}
                 </td>
          
                 <td style={{ border: '1px solid #ddd', padding: '15px' }}>
                 {templateDetails.components && Array.isArray(templateDetails.components) ? (
                                                templateDetails.components.map((component, index) => (
                                                    <div key={index}>
                                                        {component.type === "BODY" && (
                                                            <span>
                                                                {/* {replacePlaceholders(component.text, component.example.body_text[0])} */}
                                                                {component.text}
                                                            </span>
                                                        )}
                                                        {component.type === "BUTTONS" && (
                                                            <span>
                                                                {component.buttons && Array.isArray(component.buttons) ? (
                                                                    component.buttons.map((button, btnIndex) => (
                                                                        <span key={btnIndex} style={{ marginRight: '10px' }}>
                                                                            {button.type === "URL" ? (
                                                                                <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
                                                                                    {button.text}
                                                                                </a>
                                                                            ) : (
                                                                                <a href={`tel:${button.phone_number}`} style={{ textDecoration: 'none', color: 'blue' }}>
                                                                                    {button.text}
                                                                                </a>
                                                                            )}
                                                                        </span>
                                                                    ))
                                                                ) : null}
                                                            </span>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <span>No body available</span>
                                            )}                 </td>
           
             </tbody>
         </tbody>
     </table>
     
       
        ) : (
            <p>Loading details...</p>
        )}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
            Close
        </Button>
    </Modal.Footer>
</Modal>

            </div>
        </>
    );
};

export default View;
