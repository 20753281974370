

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
// import Sidebar from '../Chat/Sidebar';
// import { Col, Row, Spinner, Alert } from 'react-bootstrap';
// import { FaUsers } from 'react-icons/fa';

// const colors = ['#07526f', '#050505', '#36708e', '#4b4d56', '#122e5f', '#002c47'];

// const Dashboard = () => {
//   const [pieData, setPieData] = useState([]);
//   const [groupPieData, setGroupPieData] = useState([]);
//   const [userGroupsData, setUserGroupsData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showGroupChart, setShowGroupChart] = useState(false); // New state for showing/hiding the chart

//   useEffect(() => {
//     const fetchData = async () => {
//       const userIdss = localStorage.getItem('userId');
//       const userIdInt = parseInt(userIdss, 10);
//       setLoading(true);

//       if (userIdInt) {
//         try {
//           const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//           const userData = await userResponse.json();
//           const formattedUserData = userData.departuser.map(user => ({
//             name: user.username,
//             value: user.mobile.length,
//           }));
//           setPieData(formattedUserData);
//         } catch (error) {
//           setError('Error fetching user data');
//         }
//       }

//       try {
//         const groupResponse = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`);
//         const groupData = await groupResponse.json();
//         const formattedGroupData = groupData.groups.map(group => ({
//           name: group.groupName,
//           Id: group.groupId,
//           value: group.someCount || 1,
//         }));
//         setGroupPieData(formattedGroupData);
//       } catch (error) {
//         setError('Error fetching group data');
//       }

//       try {
//         const response = await axios.get('https://demochatapi.emedha.in/api/userGroups');
//         setUserGroupsData(response.data);
//       } catch (error) {
//         setError('Error fetching user and group data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const toggleGroupChart = () => {
//     setShowGroupChart(prevState => !prevState); // Toggle the visibility of the chart
//   };

//   if (loading) {
//     return <Spinner animation="border" />;
//   }

//   if (error) {
//     return <Alert variant="danger">{error}</Alert>;
//   }

//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <div style={{ padding: '20px' }}>
//           <h2><strong>ADMIN DASHBOARD</strong></h2>
//           <hr />
//           <Row>
//             <Col md={6} onClick={toggleGroupChart}>
//               <div className="card mb-3" style={{ backgroundColor: '#002c47', color: '#ffffff', borderRadius: '20px', cursor: 'pointer' }}>
//                 <FaUsers size={50} />
//                 <h2>{groupPieData.length > 0 ? groupPieData.length : "No groups available"}</h2>
//                 <p>Groups</p>
//               </div>
//             </Col>

//             <Col md={6}>
//               {showGroupChart && ( // Conditionally render the pie chart based on the state
//                 <>
//                   <h5>Groups</h5>
//                   <PieChart width={400} height={400}>
//                     <Tooltip />
//                     <Legend />
//                     <Pie
//                       data={groupPieData}
//                       cx={200}
//                       cy={200}
//                       labelLine={false}
//                       outerRadius={80}
//                       fill="#8884d8"
//                     >
//                       {groupPieData.map((entry, index) => (
//                         <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
//                       ))}
//                     </Pie>
//                   </PieChart>
//                 </>
//               )}
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import Sidebar from '../Chat/Sidebar';
import { Col, Row, Spinner, Alert } from 'react-bootstrap';
import { FaLock, FaSignOutAlt, FaUserEdit, FaUsers } from 'react-icons/fa';
import { IoIosNotifications } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Credits from '../Credits/Credits';
import { RiMoneyRupeeCircleFill } from 'react-icons/ri';

const colors = ['#07526f', '#050505', '#36708e', '#4b4d56', '#122e5f', '#002c47'];

const Dashboard = () => {
  const [pieData, setPieData] = useState([]);
  const [groupPieData, setGroupPieData] = useState([]);
  const [userGroupsData, setUserGroupsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showGroupChart, setShowGroupChart] = useState(false);
  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userIdss = localStorage.getItem('userId');
      const userIdInt = parseInt(userIdss, 10);
      setLoading(true);

      if (userIdInt) {
        try {
          const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
          const userData = await userResponse.json();
          const formattedUserData = userData.departuser.map(user => ({
            name: user.username,
            credits: user.credits,
            value: user.mobile.length,
          }));
          setPieData(formattedUserData);
        } catch (error) {
          setError('Error fetching user data');
        }
      }

      try {
        const groupResponse = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`);
        const groupData = await groupResponse.json();
        const formattedGroupData = groupData.groups.map(group => ({
          name: group.groupName,
          Id: group.groupId,
          value: group.someCount || 1,
        }));
        setGroupPieData(formattedGroupData);
      } catch (error) {
        setError('Error fetching group data');
      }

      try {
        const response = await axios.get('https://demochatapi.emedha.in/api/userGroups');
        setUserGroupsData(response.data);
      } catch (error) {
        setError('Error fetching user and group data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleGroupChart = () => {
    setShowGroupChart(prevState => !prevState);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleItemClick = (action) => {
    console.log(action);
    setDropdownVisible(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    navigate('/adminlogin');
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <>
      <Sidebar />
      <div id='main'>
        <div className="container-fluid bg-light p-3 shadow">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h3 className="mt-2"><strong>Admin Dashboard</strong></h3>
            </div>
            <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
              <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
              <div style={{ position: 'relative' }}>
                <MdAccountCircle
                  size={30}
                  onClick={toggleDropdown}
                  style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                />
                {dropdownVisible && (
                  <div className="dropdown-menu show" style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                  }}>
                    <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                      <FaUserEdit style={{ marginRight: '8px' }} />
                      Edit Profile
                    </div>
                    <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                      <FaLock style={{ marginRight: '8px' }} />
                      Change Password
                    </div>
                    <div onClick={handleLogout} className="dropdown-item">
                      <FaSignOutAlt style={{ marginRight: '8px' }} />
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Row className="justify-content-center">
          <Col sm={12} md={4} lg={3} className="mb-3" onClick={toggleGroupChart}>
            <div className="card" style={{ borderLeft: '6px solid orange', textAlign: 'center', fontSize: '15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
              <FaUsers size={50} style={{ backgroundColor: 'orange', color: 'white', borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <h3>{groupPieData.length > 0 ? groupPieData.length : "No groups available"}</h3>
              <p>Groups</p>
            </div>
          </Col>
          <Col sm={12} md={4} lg={3} className="mb-3">
            <div className="card" style={{ borderLeft: '6px solid orange', textAlign: 'center', fontSize: '15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
              <RiMoneyRupeeCircleFill size={50} style={{ backgroundColor: 'orange', color: 'white', borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <h2>{pieData.reduce((acc, user) => acc + user.credits, 0) || "0"}</h2>
              <p>Total Credits</p>
            </div>
          </Col>
          <Col sm={12} md={4} lg={3} className="mb-3">
            <div className="card" style={{ borderLeft: '6px solid orange', textAlign: 'center', fontSize: '15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
              <RiMoneyRupeeCircleFill size={50} style={{ backgroundColor: 'orange', color: 'white', borderRadius: '20px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
              <h2>0</h2>
              <p>Used Credits</p>
            </div>
          </Col>
        </Row>

        {showGroupChart && (
          <PieChart style={{ border: '2px solid green' }} width={400} height={400}>
            <Tooltip />
            <Legend />
            <Pie
              data={groupPieData}
              cx={200}
              cy={200}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
            >
              {groupPieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        )}
      </div>
    </>
  );
};

export default Dashboard;
