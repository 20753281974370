// import React, { useEffect, useState } from "react";
// import Sidebar from "../Chat/Sidebar";
// import { useNavigate } from "react-router-dom";
// import { Button, Dropdown, Modal, Pagination, Table } from "react-bootstrap";
// import { MdDeleteForever } from 'react-icons/md';
// import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
// import { IoIosEye } from 'react-icons/io';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const ManageContact = () => {
//     const navigate = useNavigate();
//     const [contacts, setContacts] = useState([]);
//     const [showModal, setShowModal] = useState(false);
//     const [viewModal, setViewModal] = useState(false); // State for view modal
//     const [mobile, setMobile] = useState('');
//     const [selectedGroupId, setSelectedGroupId] = useState('');
//     const [selectedAdminUserId, setSelectedAdminUserId] = useState('');
//     const [groupsId, setGroupsId] = useState([]);
//     const [adminUsers, setAdminUsers] = useState([]);
//     const [viewUser, setViewUser] = useState(null);

//     const handleModalShow = () => setShowModal(true);
//     const handleModalClose = () => {
//         setShowModal(false);
//         setMobile('');
//         setSelectedGroupId('');
//         setSelectedAdminUserId('');
//     };

//     const handleCloseViewModal = () => {
//         setViewModal(false);
//         setViewUser(null);
//     };

//     useEffect(() => {
//         const fetchGroupsId = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/groups');
//                 const data = await response.json();
//                 setGroupsId(Array.isArray(data) ? data : data.groups || []);
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         };

//         const fetchAdminUsers = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/departuser');
//                 const data = await response.json();
//                 setAdminUsers(data);
//             } catch (error) {
//                 console.error('Error fetching admin users:', error);
//             }
//         };

//         const fetchContacts = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/contacts');
//                 const data = await response.json();
//                 setContacts(data);
//             } catch (error) {
//                 console.error('Error fetching contacts:', error);
//             }
//         };

//         fetchGroupsId();
//         fetchAdminUsers();
//         fetchContacts();
//     }, []);

//     const handleView = (id) => {
//         const user = contacts.find(contact => contact.id === id);
//         if (user) {
//             setViewUser(user);
//             setViewModal(true);
//         }
//     };

//     const handleDelete = async (id) => {
//         const confirmDelete = window.confirm("Are you sure you want to delete this contact?");
//         if (confirmDelete) {
//             try {
//                 const response = await fetch(`https://demochatapi.emedha.in/api/contacts/${id}`, { method: 'DELETE' });
//                 if (response.ok) {
//                     toast.success("Contact deleted successfully");
//                     setContacts(contacts.filter(contact => contact.id !== id));
//                 } else {
//                     toast.error("Failed to delete contact");
//                 }
//             } catch (error) {
//                 console.error("Error deleting contact:", error);
//                 toast.error("Error deleting contact");
//             }
//         }
//     };

//     const handleManageContact = async (e) => {
//         e.preventDefault(); // Prevent default form submission
//         if (mobile.trim() && selectedAdminUserId) {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({ mobile, groupId: "tests", adminUserId: selectedAdminUserId }),
//                 });

//                 if (response.ok) {
//                     const newContact = await response.json();
//                     setContacts([...contacts, newContact]);
//                     handleModalClose();
//                     toast.success("Contact added successfully");
//                 } else {
//                     toast.error("Failed to add contact");
//                 }
//             } catch (error) {
//                 console.error('Failed to add contact:', error);
//                 toast.error("Error adding contact");
//             }
//         } else {
//             toast.error("Mobile number and admin user are required.");
//         }
//     };

//     const [currentPage, setCurrentPage] = useState(0);
//     const [contactsPerPage] = useState(5); // Set how many contacts per page you want

//     const pageCount = Math.ceil(contacts.length / contactsPerPage);
//     const displayedContacts = contacts.slice(currentPage * contactsPerPage, (currentPage + 1) * contactsPerPage);


//     return (
//         <>
//             <Sidebar />
//             <div className="container" id='main'>
//                 <div className="row justify-content-center mb-3">
//                     <h3>List of  Contacts</h3>
//                 </div>
//                 <div className="row justify-content-center mb-3">
//                     <div className="col-lg-12 text-start">
//                         <Button variant="primary" onClick={handleModalShow}>
//                             + Add Contacts
//                         </Button>
//                     </div>
//                 </div>

//                 <Table striped bordered hover>
//                     <thead>
//                         <tr>
//                             <th>ID</th>
//                             <th>Group ID</th>
//                             <th>Number</th>
//                             <th>Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {displayedContacts.map((contact, index) => (
//                             <tr key={contact.id}>
//                                   <td>{currentPage * contactsPerPage + index + 1}</td>
//                                 <td>{contact.groupId}</td>
//                                 <td>{contact.mobile}</td>
//                                 <td>
//                                     <Dropdown>
//                                         <Dropdown.Toggle variant="success" id="dropdown-basic">
//                                             <PiDotsThreeOutlineVerticalBold />
//                                         </Dropdown.Toggle>
//                                         <Dropdown.Menu>
//                                             <Dropdown.Item onClick={() => handleView(contact.id)}>
//                                                 <IoIosEye /> View
//                                             </Dropdown.Item>
//                                             <Dropdown.Item onClick={() => handleDelete(contact.id)}>
//                                                 <MdDeleteForever /> Delete
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//                 <ToastContainer position="top-right" autoClose={6000} />

//                 {/* Pagination Controls */}
//                 <div className="d-flex justify-content-end mt-3 p-3">
//                     <Pagination>
//                         <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
//                         <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
//                         {[...Array(pageCount)].map((_, i) => (
//                             <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
//                                 {i + 1}
//                             </Pagination.Item>
//                         ))}
//                         <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
//                         <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
//                     </Pagination>
//                 </div>



//                 <Modal show={showModal} onHide={handleModalClose}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Add New User</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <form onSubmit={handleManageContact}>
//                             <div className="mb-3">
//                                 <label htmlFor="newUserContact" className="form-label">Contact</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="newUserContact"
//                                     placeholder="Enter contact number"
//                                     value={mobile}
//                                     onChange={(e) => setMobile(e.target.value)}
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="groupId" className="form-label">Group</label>
//                                 <select
//                                     className="form-select"
//                                     id="groupId"
//                                     value={selectedGroupId}
//                                     onChange={(e) => setSelectedGroupId(e.target.value)}
//                                 >
//                                     <option value="">Select Group</option>
//                                     {groupsId.map((group) => (
//                                         <option key={group.id} value={group.id}>
//                                             {group.groupId}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="adminUserId" className="form-label">Admin User</label>
//                                 <select
//                                     className="form-select"
//                                     id="adminUserId"
//                                     value={selectedAdminUserId}
//                                     onChange={(e) => setSelectedAdminUserId(e.target.value)}
//                                 >
//                                     <option value="">Select Admin User</option>
//                                     {adminUsers.map((user) => (
//                                         <option key={user.id} value={user.id}>
//                                             {user.username}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                             <Button variant="primary btn-sm" type="submit">
//                                 Add Contact
//                             </Button>
//                         </form>
//                     </Modal.Body>
//                 </Modal>

//                 <Modal show={viewModal} onHide={handleCloseViewModal}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>User Details</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         {viewUser && (
//                             <div>
//                                  <p><strong>AdminUserId:</strong> {viewUser.adminUserId}</p>
//                                 <p><strong>Group ID:</strong> {viewUser.groupId}</p>
//                                 <p><strong>Mobile:</strong> {viewUser.mobile}</p>

//                             </div>
//                         )}
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={handleCloseViewModal}>
//                             Close
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>
//             </div>
//         </>
//     );
// };

// export default ManageContact;

import React, { useEffect, useState } from "react";
import Sidebar from "../Chat/Sidebar";
import { useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Modal, Pagination, Row, Table } from "react-bootstrap";
import { MdAccountCircle, MdDeleteForever } from 'react-icons/md';
import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
import { IoIosEye, IoIosNotifications, IoIosSearch } from 'react-icons/io';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import Swal from 'sweetalert2';
import Select from 'react-select-country-list';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
//   import PhoneInput from 'react-phone-input-2'; // or whichever PhoneInput library you're using

const ManageContact = () => {
    const navigate = useNavigate();
    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [mobile, setMobile] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedAdminUserId, setSelectedAdminUserId] = useState('');
    const [groupsId, setGroupsId] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [viewUser, setViewUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => {
        setShowModal(false);
        setMobile('');
        setSelectedGroupId('');
        setSelectedAdminUserId('');
    };

    const handleCloseViewModal = () => {
        setViewModal(false);
        setViewUser(null);
    };

    useEffect(() => {

        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/userGroups', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const data = await response.json();
                    setGroupsId(data.results || []);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };


        const fetchGroupsId = async () => {
            const userIdss = localStorage.getItem('userId')
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`, {
                        method: 'GET',

                    });
                    const data = await response.json();
                    setGroupsId(data.groups || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        const fetchAdminUsers = async () => {
            try {
                const response = await fetch('https://demochatapi.emedha.in/api/departuser');
                const data = await response.json();
                setAdminUsers(data);
                console.log(data)
            } catch (error) {
                console.error('Error fetching admin users:', error);
            }
        };


        console.log(adminUsers)

      
        fetchGroupsId();
        fetchAdminUsers();
        fetchGroups();
        // fetchContacts();
    }, []);

    //this useEffect is for all data of admins shows in superadmin

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const results = await response.json();
                    setContactss(results);
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchGroups();
    }, []); // Runs once on component mount

    // console.log(contacts);

    const [contactss, setContactss] = useState([])

    //this useEffect is for all admins that shows there data 

    useEffect(() => {
        const fetchGroups = async () => {
            const userIdss = localStorage.getItem('userId')
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
                        method: 'GET',

                    });
                    const data = await response.json();
                    setContactss(data.contacts || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        fetchGroups();
    }, []);


    console.log(contactss)

    const handleView = (id) => {
        const user = contactss.find(contact => contact.id === id);
        if (user) {
            setViewUser(user);
            setViewModal(true);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this contact?");
        if (confirmDelete) {
            try {
                const response = await fetch(`https://demochatapi.emedha.in/api/contacts/${id}`, { method: 'DELETE' });
                if (response.ok) {
                    toast.success("Contact deleted successfully");

                    setContactss(contactss.filter(contact => contact.id !== id));
                } else {
                    toast.error("Failed to delete contact");
                }
            } catch (error) {
                console.error("Error deleting contact:", error);
                toast.error("Error deleting contact");
            }
        }
    };

    const handleManageContact = async (e) => {
        const userIdss = localStorage.getItem('userId');
        const userIdInt = parseInt(userIdss, 10);
        e.preventDefault();
    
        // Validation Check
        if (!mobile.trim() || !selectedAdminUserId) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Mobile number and admin user are required.',
            });
            return;
        }
    
        // Remove the '+' from the mobile number (if it exists)
        const cleanMobile = mobile.replace(/^\+/, ''); // Removes the '+' symbol at the beginning
    
        // Now use `cleanMobile` in the API request
        try {
            const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ mobile: cleanMobile, groupId: selectedGroupId, adminUserId: userIdInt }),
            });
    
            if (response.ok) {
                const newContact = await response.json();
                setContacts([...contacts, newContact]);
                handleModalClose();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Contact added successfully!',
                }).then(() => {
                    window.location.reload(); // Reload after closing the alert
                });
            } else {
                const errorData = await response.json();
                console.error('Failed to add contact:', errorData);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorData.message || 'Failed to add contact. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error adding contact:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again.',
            });
        }
    };
    
    const [currentPage, setCurrentPage] = useState(0);
    const [contactsPerPage] = useState(10);
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const userIdss = localStorage.getItem('userId')
    const userIdInt = parseInt(userIdss, 10);

    const [searchType, setSearchType] = useState('mobile'); // Default search type

    const filteredContacts = contactss.filter(contact => {
        const searchValue = searchQuery.toLowerCase().trim(); // Normalize the search query
        if (!searchValue) return true; // Return all contacts if the search query is empty

        const matchesMobile = contact.mobile.toString().toLowerCase().includes(searchValue); // Ensure mobile is a string
        const matchesGroupId = contact.groupId.toString().toLowerCase().includes(searchValue); // Ensure groupId is a string

        return matchesMobile || matchesGroupId; // Return true if either matches
    });


    const pageCount = Math.ceil(filteredContacts.length / contactsPerPage);
    const displayedContacts = filteredContacts.slice(currentPage * contactsPerPage, (currentPage + 1) * contactsPerPage);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleItemClick = (action) => {
        console.log(action);
        setDropdownVisible(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/');
    };


    const [phoneNumber, setPhoneNumber] = useState('');


  

        const [fullMobile, setFullMobile] = useState('');
        const [localMobile, setLocalMobile] = useState('');
    
        const handlePhoneChange = (value) => {
            setFullMobile(value);
            const localNumber = value.replace(/\+\d{1,3}/, ''); // Remove the country code
            setLocalMobile(localNumber); // Store only the local number
        };




    return (
        <>
            <Sidebar />
            <div id='main'>
                <div className=" bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong> Contacts</strong></h3>
                        </div>

                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <div style={{ position: 'relative' }}>
                                <MdAccountCircle
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisible && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div>
                                        <div onClick={handleLogout} className="dropdown-item">
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <Row>

                    <Col>
                       
                        <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search "
                                className="form-control"
                                style={{
                                    paddingLeft: '40px', // Space for the icon
                                    paddingRight: '40px', // Space for any right-side elements
                                    borderRadius: '25px', // Rounded corners
                                    border: '1px solid #ced4da', // Border color

                                    transition: 'border-color 0.3s',
                                }}
                                onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
                                onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
                            />
                            <IoIosSearch

                                style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
                                size={20} // Adjust size as needed
                            />
                        </div>
                    </Col>
                    <Col>
                        <div >
                            <div className='d-flex flex-row justify-content-end m-2'>
                                <Button style={{ backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleModalShow}>
                                    + Add Contacts
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br></br>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>ID</th>

                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Group ID</th>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Number</th>

                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedContacts.map((contact, index) => (
                            <tr key={contact.id}>
                                <td>{currentPage * contactsPerPage + index + 1}</td>

                                <td>{contact.groupId}</td>
                                <td>{contact.mobile}</td>
                                {/* <td>{contact.adminUserId}</td> */}

                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ backgroundColor: 'rgb(125, 133, 195)' }} id="dropdown-basic">
                                            <PiDotsThreeOutlineVerticalBold />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleView(contact.id)}>
                                                <IoIosEye /> View
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item onClick={() => handleDelete(contact.id)}>
                                                <MdDeleteForever /> Delete
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                {/* Pagination Controls */}
                <div className='d-flex flex-row justify-content-end m-2'>
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
                        <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
                        {[...Array(pageCount)].map((_, i) => (
                            <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
                        <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
                    </Pagination>
                </div>
                <ToastContainer position="top-right" autoClose={6000} />


                <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleManageContact}>
                            {/* <div className="mb-3">
                                <label htmlFor="newUserContact" className="form-label">Contact</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="newUserContact"
                                    placeholder="Enter contact number"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div> */}

                            <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">Contact</label>
                                <PhoneInput
                                    international
                                    defaultCountry="IN"
                                    type="text" // Default to India
                                    value={mobile}
                                    id="newUserContact"
                                    onChange={setMobile}
                                    placeholder="Enter phone number"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="groupId" className="form-label">Group</label>
                                <select
                                    className="form-select"
                                    id="groupId"
                                    value={selectedGroupId}
                                    onChange={(e) => setSelectedGroupId(e.target.value)}
                                >
                                    <option value="">Select Group</option>
                                    {groupsId.map((group) => (
                                        <option key={group.groupId} value={group.groupId}>
                                            {group.groupId}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="adminUserId" className="form-label">Admin User</label>
                                <select
                                    className="form-select"
                                    id="adminUserId"
                                    value={selectedAdminUserId}
                                    onChange={(e) => setSelectedAdminUserId(e.target.value)}
                                >
                                    <option value="">Select Admin User</option>
                                   
                                   
                                    {adminUsers.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.username}
                                        </option>
                                    ))}


                                    {adminUsers.filter((user => user.id === userIdInt)).map((user) => (
                                        <option key={user.id} value={user.username}>
                                            {user.username}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            <div className="mb-3">
                                <label htmlFor="adminUserId" className="form-label">Admin User</label>
                                <select
                                    className="form-select"
                                    id="adminUserId"
                                    value={selectedAdminUserId}
                                    onChange={(e) => setSelectedAdminUserId(e.target.value)}
                                >
                                    <option value="">Select Admin User</option>

                                    {localStorage.getItem('token') ? (
                                        adminUsers.map((user) => (
                                            <option key={user.id} value={user.id}>
                                                {user.username}
                                            </option>
                                        ))
                                    ) : (
                                        adminUsers.filter(user => user.id === userIdInt).map((user) => (
                                            <option key={user.id} value={user.username}>
                                                {user.username}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>



                            <Button variant="primary btn-sm" type="submit">
                                Add Contact
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal show={viewModal} onHide={handleCloseViewModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {viewUser && (
                            <div>
                                {/* <p><strong>Admin User ID:</strong> {viewUser.username}</p> */}
                                <p><strong>Group ID:</strong> {viewUser.groupId}</p>
                                <p><strong>Mobile:</strong> {viewUser.mobile}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseViewModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default ManageContact;
