// import React, { useEffect, useState } from 'react';
// import Sidebar from '../Chat/Sidebar';
// import { IoIosSearch, IoMdContact } from 'react-icons/io';
// import { BiSolidSend } from "react-icons/bi";
// import { IoSend } from "react-icons/io5";
// import bg from './bg.jpeg';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { Col, Dropdown, Row } from 'react-bootstrap';
// import { MdArchive, MdOutlineSendAndArchive } from 'react-icons/md';

// const SendUserMessage = () => {
//     const [contacts, setContacts] = useState([]);
//     const [selectedContact, setSelectedContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [searchTerm, setSearchTerm] = useState('');
//     const [response, setResponse] = useState('');
//     const [error, setError] = useState('');
//     const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
//     const [archivedContacts, setArchivedContacts] = useState([]);
//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (!event.target.closest('.dropdown-toggle')) {
//                 setActiveDropdown(null); // Close dropdown if click is outside
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside); // Add listener for clicks outside
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside); // Clean up listener on unmount
//         };
//     }, []);

//     // Fetch contacts based on token
//     useEffect(() => {
//         const fetchContactsWithToken = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     });
//                     const results = await response.json();
//                     setContacts(results);
//                 }
//             } catch (error) {
//                 console.error('Error fetching contacts:', error);
//             }
//         };

//         fetchContactsWithToken();
//     }, []);

//     // Fetch contacts based on userId
// useEffect(() => {
//     const fetchContactsWithUserId = async () => {
//         const userId = localStorage.getItem('userId');
//         const userIdInt = parseInt(userId, 10);

//         if (userIdInt) {
//             try {
//                 const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
//                     method: 'GET',
//                 });
//                 const data = await response.json();
//                 setContacts(data.contacts || []);
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         }
//     };

//     fetchContactsWithUserId();
// }, []);

//     const handleContactClick = (contact) => {
//         setSelectedContact(contact);
//         setMessages([]); // Reset messages for the selected contact
//     };

//     const handleSendMessage = async () => {
//         if (newMessage.trim() === '' || !selectedContact) return;

//         const messageToSend = newMessage;

//         // Create a new message object to display in the chat
//         const newMessageObject = {
//             id: messages.length + 1,
//             text: messageToSend,
//             sender: 'me',
//             timestamp: new Date().toLocaleTimeString(),
//         };

//         // Update the messages state
//         setMessages((prevMessages) => [...prevMessages, newMessageObject]);

//         try {
//             const mobile = selectedContact.mobile; // Get the selected contact's mobile number
//             const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=8919171985&message=${encodeURIComponent(messageToSend)}`, {
//                 method: 'POST', // Using POST as per the API specification
//             });

//             if (!res.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const contentType = res.headers.get("content-type");
//             let data;

//             if (contentType && contentType.includes("application/json")) {
//                 data = await res.json();
//             } else {
//                 data = await res.text();
//             }

//             setResponse(data); // Handle the response from the API
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             // Clear the input field
//             setNewMessage('');
//         }
//     };

//     const filteredContacts = contacts.filter(contact =>
//         contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const filteredArchivedContacts = archivedContacts.filter(contact =>
//         contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
//     );


//     const toggleDropdown = (contactId) => {
//         if (activeDropdown === contactId) {
//             setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
//         } else {
//             setActiveDropdown(contactId); // Show the dropdown for the clicked contact
//         }
//     };


//     // const handleArchiveContact = (contactId) => {
//     //     const contactToArchive = contacts.find((contact) => contact.id === contactId);

//     //     // Remove the contact from the main contacts
//     //     setContacts((prevContacts) => prevContacts.filter((contact) => contact.id !== contactId));

//     //     // Add the contact to the archived contacts
//     //     setArchivedContacts((prevArchived) => [...prevArchived, contactToArchive]);

//     //     setActiveDropdown(null); // Close dropdown
//     // };

//     // // Unarchive the contact
//     // const handleUnarchiveContact = (contactId) => {
//     //     const contactToUnarchive = archivedContacts.find((contact) => contact.id === contactId);

//     //     // Remove the contact from archived contacts
//     //     setArchivedContacts((prevArchived) => prevArchived.filter((contact) => contact.id !== contactId));

//     //     // Add the contact back to main contacts
//     //     setContacts((prevContacts) => [...prevContacts, contactToUnarchive]);
//     // };

//     return (
//         <>
//             <Sidebar />
//             <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
//                 <div style={{ backgroundColor: '#E6E8F4', width: '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
//                     <Row> <Col> <h3 style={{ margin: '0 0 10px' }}>Contacts</h3> </Col> <Col className='text-end'> <BsThreeDotsVertical style={{ fontSize: '20px' }} /> </Col></Row>

//                     <div style={{ marginBottom: '10px', position: 'relative', width: '230px', display: 'flex', alignItems: 'center' }}>
//                         <input
//                             type="text"
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             placeholder="Search "
//                             className="form-control"
//                             style={{
//                                 paddingLeft: '40px', // Space for the icon
//                                 paddingRight: '40px', // Space for any right-side elements
//                                 borderRadius: '25px', // Rounded corners
//                                 border: '1px solid rgb(125, 133, 195)', // Border color

//                                 transition: 'border-color 0.3s',
//                             }}
//                             onFocus={(e) => e.target.style.borderColor = ' rgb(125, 133, 195)'} // Change border color on focus
//                             onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'} // Reset border color on blur
//                         />
//                         <IoIosSearch

//                             style={{ position: 'absolute', left: '10px', color: '#ced4da' }}
//                             size={20} // Adjust size as needed
//                         />
//                     </div>
//                     {/* <h4 className='text-center'> <MdArchive />  Archived</h4>  */}

//                     {filteredContacts.map(contact => (
//                         <div
//                             key={contact.id}
//                             onClick={() => handleContactClick(contact)}
//                             style={{
//                                 cursor: 'pointer',
//                                 padding: '10px',
//                                 margin: '5px',
//                                 borderBottom: '1px solid #eee',
//                                 // borderRadius:'20px'
//                                 // transition: 'background-color 0.1s',
//                             }}
//                             onMouseEnter={(e) => {
//                                 e.currentTarget.style.backgroundColor = '#7D86C3';
//                                 e.currentTarget.style.color = 'white';
//                             }}
//                             onMouseLeave={(e) => {
//                                 e.currentTarget.style.backgroundColor = '#E6E8F4';
//                                 e.currentTarget.style.color = 'black';
//                             }}
//                         >
//                                <Row>
//                                 <Col>{contact.mobile}</Col>
//                                 <Col className='text-end'>
//                                     <MdOutlineSendAndArchive
//                                         id="dropdown-custom-components"
//                                         style={{ fontSize: '20px', cursor: 'pointer' }}
//                                         onClick={(e) => {
//                                             e.stopPropagation(); // Prevent dropdown from closing
//                                             toggleDropdown(contact.id); // Toggle dropdown
//                                         }}
//                                     />
//                                     {/* <Dropdown show={activeDropdown === contact.id}>
//                                         <Dropdown.Menu>
//                                            <Dropdown.Item onClick={() => handleArchiveContact(contact.id)}> 

//                                                 Archive
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown> */}
//                                 </Col>
//                             </Row>
//                         </div>
//                     ))}
//                 </div>
//                 <div style={{ flex: 1,  display: 'flex', flexDirection: 'column' }}>
//                     {selectedContact ? (
//                         <>
//                             <div style={{ borderBottom: '1px solid #ccc' }}>
//                                 <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}><IoMdContact style={{fontSize:'30px',color:'rgb(125, 133, 195)', margin:'5px'}}/>{selectedContact.mobile}</h3>
//                             </div>
//                             <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', border: '1px solid #ccc', backgroundColor: '#E6E8F4',    backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,    borderTop: 'none' }}>
//                                 {messages.map(msg => (
//                                     <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'me' ? 'right' : 'left' }}>
//                                         <div style={{
//                                             display: 'inline-block',
//                                             padding: '8px',
//                                             borderRadius: '10px',
//                                             backgroundColor: msg.sender === 'me' ? '#dcf8c6' : '#ffffff',
//                                             maxWidth: '70%',
//                                             boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
//                                         }}>
//                                             <strong>{msg.sender === 'me' ? 'You' : selectedContact.mobile}:</strong>
//                                             <span> {msg.text} </span>
//                                             <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.timestamp}</small>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
//                                 {/* <input
//                                     type="text"
//                                     value={newMessage}
//                                     onChange={(e) => setNewMessage(e.target.value)}
//                                     placeholder="Type a message..."
//                                     style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
//                                 /> */}


//                                 <input
//                                     type="text"
//                                     value={newMessage}
//                                     onChange={(e) => setNewMessage(e.target.value)}
//                                     placeholder="Type a message...."

//                                     style={{
//                                         flex: 1, padding: '10px',
//                                         paddingLeft: '40px', // Space for the icon
//                                         paddingRight: '40px', // Space for any right-side elements
//                                         borderRadius: '25px', // Rounded corners
//                                         border: '1px solid black', // Border color


//                                     }}
//                                     onFocus={(e) => e.target.style.borderColor = 'blue'} // Change border color on focus
//                                     onBlur={(e) => e.target.style.borderColor = 'black'} // Reset border color on blur
//                                 />


//                                 <button
//                                     onClick={handleSendMessage}
//                                     style={{ marginLeft: '10px', padding: '10px 15px', backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: 'none', borderRadius: '20px' }}
//                                 >
//                                     Send
//                                     {newMessage.trim() && ( // Only show the button if there is text
//                                         <IoSend style={{ margin: '5px', }} />
//                                     )}
//                                 </button>

//                             </div>

//                         </>
//                     ) : (
//                         <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.</div>
//                     )}
//                 </div>




//                 {/* <div style={{ width: '20%', backgroundColor: '#f4f4f4', padding: '10px' }}>
//                     <h4><MdArchive /> Archived</h4>
//                     {filteredArchivedContacts.map(contact => (
//                         <div
//                             key={contact.id}
//                             style={{ cursor: 'pointer', padding: '10px', margin: '5px', borderBottom: '1px solid #eee' }}
//                         >
//                             <Row>
//                                 <Col>{contact.mobile}</Col>
//                                 <Col className='text-end'>
//                                     <Dropdown>
//                                         <Dropdown.Menu>
//                                             <Dropdown.Item href="#" onClick={() => handleUnarchiveContact(contact.id)}>
//                                                 Unarchive
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </Col>
//                             </Row>
//                         </div>
//                     ))}
//                 </div> */}
//             </div>
//         </>

//     );
// };

// export default SendUserMessage;




//main code copy below


// import React, { useState, useEffect } from 'react';
// import Sidebar from '../Chat/Sidebar';
// import { IoIosSearch, IoMdContact } from 'react-icons/io';
// import { IoSend } from 'react-icons/io5';
// import bg from './bg.jpeg';
// import { BsEmojiGrin, BsThreeDotsVertical } from 'react-icons/bs';
// import { Col, Dropdown, Row } from 'react-bootstrap';
// import { MdOutlineSendAndArchive } from 'react-icons/md';
// import EmojiPicker from 'emoji-picker-react'; // Import emoji picker component

// const SendUserMessage = () => {
//     const [contacts, setContacts] = useState([]);
//     const [selectedContact, setSelectedContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [searchTerm, setSearchTerm] = useState('');
//     const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Show/hide emoji picker
//     const [selectedEmoji, setSelectedEmoji] = useState(''); // Selected emoji for the message
//     const [response, setResponse] = useState('');
//     const [error, setError] = useState('');


//     const [departUserMobile, setDepartUserMobile] = useState(''); // Store the mobile number from departuser


//     useEffect(() => {
//         const fetchUserData = async () => {
//             const userIdss = localStorage.getItem('userId');
//             const userIdInt = parseInt(userIdss, 10);

//             if (userIdInt) {
//                 try {
//                     const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//                     const userData = await userResponse.json();

//                     // Assuming the mobile number is in userData.departuser and mapping it
//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                         setDepartUserMobile(userMobile); // Store the mobile number
//                     }
//                 } catch (error) {
//                     console.error('Error fetching departuser data:', error);
//                 }
//             }
//         };

//         fetchUserData();
//     }, []);

//     useEffect(() => {
//         const fetchGroups = async () => {
//             try {
//                 const token = localStorage.getItem('token'); // Assuming the token is stored under this key
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
//                         },
//                     });
//                     const userData = await response.json();
//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                         setDepartUserMobile(userMobile); // Store the mobile number
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         };

//         fetchGroups();
//     }, []); // Runs once on component mount

//     useEffect(() => {
//         const fetchUserData = async () => {
//             const userIdss = localStorage.getItem('userId');
//             const userIdInt = parseInt(userIdss, 10);

//             if (userIdInt) {
//                 try {
//                     const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//                     const userData = await userResponse.json();

//                     // Assuming the mobile number is in userData.departuser and mapping it
//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                         setDepartUserMobile(userMobile); // Store the mobile number
//                     }
//                 } catch (error) {
//                     console.error('Error fetching departuser data:', error);
//                 }
//             }
//         };

//         fetchUserData();
//     }, []);


//     console.log(departUserMobile);


//     useEffect(() => {
//         const fetchContactsWithUserId = async () => {
//             const userId = localStorage.getItem('userId');
//             const userIdInt = parseInt(userId, 10);

//             if (userIdInt) {
//                 try {
//                     const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
//                         method: 'GET',
//                     });
//                     const data = await response.json();
//                     setContacts(data.contacts || []);
//                 } catch (error) {
//                     console.error('Error fetching groups:', error);
//                 }
//             }
//         };

//         fetchContactsWithUserId();
//     }, []);

//     useEffect(() => {
//         const fetchContacts = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     });
//                     const results = await response.json();
//                     setContacts(results);
//                 }
//             } catch (error) {
//                 console.error('Error fetching contacts:', error);
//             }
//         };

//         fetchContacts();
//     }, []);

//     const handleContactClick = (contact) => {
//         setSelectedContact(contact);
//         setMessages([]); // Reset messages for the selected contact
//     };




//     // const handleSendMessage = async () => {
//     //     if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send

//     //     const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji

//     //     // Create a new message object to display in the chat
//     //     const newMessageObject = {
//     //         id: messages.length + 1,
//     //         text: messageToSend,
//     //         sender: 'me',
//     //         timestamp: new Date().toLocaleTimeString(),
//     //     };

//     //     // Update the messages state
//     //     setMessages((prevMessages) => [...prevMessages, newMessageObject]);

//     //     try {
//     //         const mobile = selectedContact.mobile; // Get the selected contact's mobile number
//     //         const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=${mgrmobile}&message=${encodeURIComponent(messageToSend)}`, {
//     //             method: 'POST', // Using POST as per the API specification
//     //         });

//     //         if (!res.ok) {
//     //             throw new Error('Network response was not ok');
//     //         }

//     //         const contentType = res.headers.get("content-type");
//     //         let data;

//     //         if (contentType && contentType.includes("application/json")) {
//     //             data = await res.json();
//     //         } else {
//     //             data = await res.text();
//     //         }

//     //         setResponse(data); // Handle the response from the API
//     //     } catch (err) {
//     //         setError(err.message);
//     //     } finally {
//     //         // Clear the input field and reset emoji
//     //         setNewMessage('');
//     //         setSelectedEmoji('');
//     //     }
//     // };


//     const handleSendMessage = async () => {
//         if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send

//         const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji

//         // Create a new message object to display in the chat
//         const newMessageObject = {
//             id: messages.length + 1,
//             text: messageToSend,
//             sender: 'me',
//             timestamp: new Date().toLocaleTimeString(),
//         };

//         // Update the messages state
//         setMessages((prevMessages) => [...prevMessages, newMessageObject]);

//         try {
//             const mobile = selectedContact.mobile; // Get the selected contact's mobile number
//             const mgrmobile = departUserMobile; // Use the mobile number from departuser

//             if (!mgrmobile) {
//                 throw new Error("Manager's mobile number is not available.");
//             }

//             const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=${mgrmobile}&message=${encodeURIComponent(messageToSend)}`, {
//                 method: 'POST', // Using POST as per the API specification
//             });

//             if (!res.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const contentType = res.headers.get("content-type");
//             let data;

//             if (contentType && contentType.includes("application/json")) {
//                 data = await res.json();
//             } else {
//                 data = await res.text();
//             }

//             setResponse(data); // Handle the response from the API
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             // Clear the input field and reset emoji
//             setNewMessage('');
//             setSelectedEmoji('');
//         }
//     };


//     const handleEmojiClick = (emojiObject) => {
//         // Append the selected emoji to the current message
//         setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
//         setShowEmojiPicker(false); // Close the emoji picker after selection
//     };

//     const filteredContacts = contacts.filter(contact =>
//         contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const toggleDropdown = (contactId) => {
//         if (activeDropdown === contactId) {
//             setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
//         } else {
//             setActiveDropdown(contactId); // Show the dropdown for the clicked contact
//         }
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
//                 <div style={{ backgroundColor: '#E6E8F4', width: '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
//                     <Row>
//                         <Col>
//                             <h3 style={{ margin: '0 0 10px' }}>Contacts</h3>
//                         </Col>
//                         <Col className='text-end'>
//                             <BsThreeDotsVertical style={{ fontSize: '20px' }} />
//                         </Col>
//                     </Row>

//                     <div style={{ marginBottom: '10px', position: 'relative', width: '230px', display: 'flex', alignItems: 'center' }}>
//                         <input
//                             type="text"
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             placeholder="Search"
//                             className="form-control"
//                             style={{
//                                 paddingLeft: '40px',
//                                 paddingRight: '40px',
//                                 borderRadius: '25px',
//                                 border: '1px solid rgb(125, 133, 195)',
//                                 transition: 'border-color 0.3s',
//                             }}
//                             onFocus={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
//                             onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
//                         />
//                         <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#ced4da' }} size={20} />
//                     </div>
//                     <div className="dropdown-scrollable" style={{
//                         maxHeight: '585px',  
//                         overflowY: 'auto',   

//                         borderRadius: '5px', 
//                     }}>
//                         {filteredContacts.map(contact => (
//                             <div
//                                 key={contact.id}
//                                 onClick={() => handleContactClick(contact)}
//                                 style={{
//                                     cursor: 'pointer',
//                                     padding: '10px',
//                                     margin: '5px',
//                                     borderBottom: '1px solid #eee',
//                                 }}
//                                 onMouseEnter={(e) => {
//                                     e.currentTarget.style.backgroundColor = '#7D86C3';
//                                     e.currentTarget.style.color = 'white';
//                                 }}
//                                 onMouseLeave={(e) => {
//                                     e.currentTarget.style.backgroundColor = '#E6E8F4';
//                                     e.currentTarget.style.color = 'black';
//                                 }}
//                             >
//                                 <Row>
//                                     <Col>{contact.mobile}</Col>
//                                     <Col className='text-end'>
//                                         <MdOutlineSendAndArchive
//                                             id="dropdown-custom-components"
//                                             style={{ fontSize: '20px', cursor: 'pointer' }}
//                                             onClick={(e) => {
//                                                 e.stopPropagation(); // Prevent dropdown from closing
//                                                 toggleDropdown(contact.id); // Toggle dropdown
//                                             }}
//                                         />
//                                     </Col>
//                                 </Row>
//                             </div>
//                         ))}
//                     </div>

//                 </div>

//                 <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
//                     {selectedContact ? (
//                         <>
//                             <div style={{ borderBottom: '1px solid #ccc' }}>
//                                 <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}>
//                                     <IoMdContact style={{ fontSize: '30px', color: 'rgb(125, 133, 195)', margin: '5px' }} />
//                                     {selectedContact.mobile}
//                                 </h3>
//                             </div>

//                             <div style={{
//                                 flex: 1,
//                                 overflowY: 'scroll',
//                                 padding: '10px',
//                                 border: '1px solid #ccc',
//                                 backgroundColor: '#E6E8F4',
//                                 backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,
//                                 borderTop: 'none'
//                             }}>
//                                 {messages.map(msg => (
//                                     <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'me' ? 'right' : 'left' }}>
//                                         <div style={{
//                                             display: 'inline-block',
//                                             padding: '8px',
//                                             borderRadius: '10px',
//                                             backgroundColor: msg.sender === 'me' ? '#dcf8c6' : '#ffffff',
//                                             maxWidth: '70%',
//                                             boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
//                                         }}>
//                                             <strong>{msg.sender === 'me' ? 'You' : selectedContact.mobile}:</strong>
//                                             <span> {msg.text} </span>
//                                             <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.timestamp}</small>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>


//                             <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
//                                 <div style={{ position: 'relative' }}>
//                                     <button
//                                         style={{
//                                             padding: '5px',
//                                             backgroundColor: '#E6E8F4',
//                                             border: '1px solid #ccc',
//                                             borderRadius: '50%',
//                                             cursor: 'pointer'
//                                         }}
//                                         onClick={() => setShowEmojiPicker(!showEmojiPicker)}
//                                     >
//                                         <BsEmojiGrin style={{ color: 'black', fontSize: '28px' }} />
//                                     </button>

//                                     {/* Emoji picker will now appear above the button */}
//                                     {showEmojiPicker && (
//                                         <div style={{
//                                             position: 'absolute',
//                                             bottom: '40px', // Adjust this value depending on your layout to move the picker above the button
//                                             left: '0',
//                                             zIndex: '1000', // Ensure the emoji picker is on top of other elements
//                                         }}>
//                                             <EmojiPicker onEmojiClick={handleEmojiClick} />
//                                         </div>
//                                     )}
//                                 </div>

//                                 <input
//                                     type="text"
//                                     value={newMessage}
//                                     onChange={(e) => setNewMessage(e.target.value)}
//                                     placeholder="Type a message...."
//                                     style={{
//                                         flex: 1,
//                                         padding: '10px',
//                                         paddingLeft: '40px',
//                                         paddingRight: '40px',
//                                         borderRadius: '25px',
//                                         border: '1px solid black',
//                                     }}
//                                     onFocus={(e) => e.target.style.borderColor = 'blue'}
//                                     onBlur={(e) => e.target.style.borderColor = 'black'}
//                                 />

//                                 <button
//                                     onClick={handleSendMessage}
//                                     style={{
//                                         marginLeft: '10px',
//                                         padding: '10px 15px',
//                                         backgroundColor: 'rgb(125, 133, 195)',
//                                         color: 'white',
//                                         border: 'none',
//                                         borderRadius: '20px'
//                                     }}
//                                 >
//                                     Send
//                                     {newMessage.trim() && ( // Only show the button if there is text
//                                         <IoSend style={{ margin: '5px' }} />
//                                     )}
//                                 </button>
//                             </div>
//                         </>
//                     ) : (
//                         <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.</div>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };

// export default SendUserMessage;





// duplicate 


import React, { useState, useEffect } from 'react';
import Sidebar from '../Chat/Sidebar';
import { IoIosSearch, IoMdContact } from 'react-icons/io';
import { IoArrowBack, IoSend } from 'react-icons/io5';
import bg from './bg.jpeg';
import bg3 from './bg3.png';
import { BsEmojiGrin, BsThreeDotsVertical } from 'react-icons/bs';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { MdAccountCircle, MdOutlineSendAndArchive } from 'react-icons/md';
import { BiChevronRight } from "react-icons/bi";
import EmojiPicker from 'emoji-picker-react'; // Import emoji picker component
import axios from 'axios';
import usermsg from './usermsg.png';
import usermsg1 from './usermsg1.png';
import usermsg2 from './usermsg2.png';
import './styles.css';
import { FaLock, FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FcSettings, FcSms, FcSynchronize } from 'react-icons/fc';


const SendUserMessage = ({ mobile, srcmobile }) => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Show/hide emoji picker
    const [selectedEmoji, setSelectedEmoji] = useState(''); // Selected emoji for the message
    const [response, setResponse] = useState('');
    const [error, setError] = useState('');


    const [departUserMobile, setDepartUserMobile] = useState(''); // Store the mobile number from departuser


    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();

                    // Assuming the mobile number is in userData.departuser and mapping it
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                        setDepartUserMobile(userMobile); // Store the mobile number
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const userData = await response.json();
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                        setDepartUserMobile(userMobile); // Store the mobile number
                    }
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []); // Runs once on component mount





    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         const userIdss = localStorage.getItem('userId');
    //         const userIdInt = parseInt(userIdss, 10);

    //         if (userIdInt) {
    //             try {
    //                 const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
    //                 const userData = await userResponse.json();

    //                 // Assuming the mobile number is in userData.departuser and mapping it
    //                 if (userData.departuser && userData.departuser.length > 0) {
    //                     const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
    //                     setDepartUserMobile(userMobile); // Store the mobile number
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching departuser data:', error);
    //             }
    //         }
    //     };

    //     fetchUserData();
    // }, []);


    // const fetchUserData = async () => {
    //     const userIdss = localStorage.getItem('userId');
    //     const userIdInt = parseInt(userIdss, 10);

    //     if (userIdInt) {
    //         try {
    //             const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
    //             const userData = await userResponse.json();

    //             // Assuming the mobile number is in userData.departuser and mapping it
    //             if (userData.departuser && userData.departuser.length > 0) {
    //                 const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
    //                 setDepartUserMobile(userMobile); // Store the mobile number
    //                 console.log("departUserMobile set to:", userMobile);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching departuser data:', error);
    //         }
    //     }
    // };

    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();

                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile;
                        setDepartUserMobile(userMobile);
                        console.log("departUserMobile set to:", userMobile);
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };

        fetchUserData();
    }, []);



    console.log(departUserMobile);


    useEffect(() => {
        const fetchContactsWithUserId = async () => {
            const userId = localStorage.getItem('userId');
            const userIdInt = parseInt(userId, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
                        method: 'GET',
                    });
                    const data = await response.json();
                    setContacts(data.contacts || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        fetchContactsWithUserId();
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const results = await response.json();
                    setContacts(results);
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchContacts();
    }, []);



    const handleSendMessage = async () => {
        if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send

        const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji
       
        // const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        
        // const newMessageObject = {
        //     id: messages.length + 1,
        //     msg: messageToSend,
        //  sender: 'you',
        //     timestamp: new Date().toLocaleString(undefined, options), // Custom formatting
             
        // };

        // // Update the messages state
        // setMessages((prevMessages) => [...prevMessages, newMessageObject]);

        try {
            const mobile = selectedContact.mobile; // Get the selected contact's mobile number
            const mgrmobile = departUserMobile; // Use the mobile number from departuser

            if (!mgrmobile) {
                throw new Error("Manager's mobile number is not available.");
            }

            const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=${mgrmobile}&message=${encodeURIComponent(messageToSend)}`, {
                method: 'POST', // Using POST as per the API specification
            });

            if (!res.ok) {

                throw new Error('Network response was not ok');
            }

            const contentType = res.headers.get("content-type");
            let data;

            if (contentType && contentType.includes("application/json")) {
                data = await res.json();
            } else {
                data = await res.text();
            }

            setResponse(data); // Handle the response from the API
            fetchMessages(selectedContact);
        } catch (err) {
            setError(err.message);
        } finally {
            // Clear the input field and reset emoji
            setNewMessage('');
            setSelectedEmoji('');
        }
    };



    const handleEmojiClick = (emojiObject) => {
        // Append the selected emoji to the current message
        setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false); // Close the emoji picker after selection
    };

    const filteredContacts = contacts.filter(contact =>
        contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleDropdown = (contactId) => {
        if (activeDropdown === contactId) {
            setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
        } else {
            setActiveDropdown(contactId); // Show the dropdown for the clicked contact
        }
    };
    // const handleContactClick = (contact) => {
    //     setSelectedContact(contact);
    //     setMessages([]); // Reset messages for the selected contact
    // };
    // Fetch messages when a contact is clicked
    // const handleContactClick = async (contact) => {
    //     setSelectedContact(contact);

    //     console.log("Selected contact:", contact);
    //     console.log("departUserMobile:", departUserMobile);  // Ensure departUserMobile is not empty

    //     if (!departUserMobile) {
    //       console.error("Error: Depart User Mobile is not set.");
    //       return;
    //     }

    //     try {
    //       const res = await axios.get('http://localhost:3009/api/getMessages', {
    //         params: {
    //           mgrmobile: departUserMobile,  // Use departUserMobile
    //           srcmobile: contact.mobile,    // Use selected contact's mobile
    //         },
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem('token')}`,
    //         },
    //       });

    //       console.log("Messages fetched successfully:", res.data);
    //       setMessages(res.data.messages || []);
    //     } catch (error) {
    //       console.error("Error fetching messages:", error);
    //     }
    //   };

    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState('');
    const [contact, setContact] = useState({});


    const fetchMessagess = async (contact) => {
        try {
            setLoading(true);
            setError(''); // Clear previous errors

            const token = localStorage.getItem('token');
            if (!token) {
                setError('No token found. Please log in.');
                return;
            }

            const response = await axios.get('http://localhost:3009/api/messages', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: { srcmobile: contact }
            });

            if (response.data.result) {
                setMessages(response.data.result);
            } else {
                setMessages([]);
            }
        } catch (err) {
            console.error('Error fetching messages:', err);
            setError('Error fetching messages. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedContact) {
            fetchMessagess(selectedContact);
        }
    }, [selectedContact, srcmobile]);


        // Fetch messages between selected contact and departUserMobile
        const fetchMessages = async (contact) => {
            try {
                const res = await axios.get('https://demochatapi.emedha.in/api/getMessages', {
                    params: {
                        srcmobile: departUserMobile,
                        mgrmobile: contact.mobile,
                      
                        // srcmobile: encodeURIComponent(departUserMobile), // Ensure srcmobile is properly encoded
                        // mgrmobile: encodeURIComponent(contact.mobile), 
                    }
                });

                // If srcmobile matches selectedContact, set messages to display on the left side
                // if (contact.mobile === selectedContact) {
                //     // Set messages to the state
                // }
                console.log('API Response:', res.data);
                setMessages(res.data.messages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
                //  fetchMessages();
        };

   

        const handleContactClick = (contact) => {
            // fetchMessagess(contact);
            setSelectedContact(contact);
            fetchMessages(contact);
            fetchMessagess(contact);
           
    };



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default newline behavior
            handleSendMessage(); // Call the send message function
        }
    };

    const [isMobile, setIsMobile] = useState(false);


    // Detect mobile screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialize on component mount

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showContacts, setShowContacts] = useState(true);

    const handleBackClick = () => {
        window.location.reload();
        setShowContacts(false); // Hide contacts when back is clicked
    };



    const getDateLabel = (date) => {
        const today = new Date();
        const msgDate = new Date(date);

        const isToday = today.toDateString() === msgDate.toDateString();
        if (isToday) return 'Today';

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const isYesterday = yesterday.toDateString() === msgDate.toDateString();
        if (isYesterday) return 'Yesterday';

        return msgDate.toLocaleDateString(undefined, { weekday: 'long', month: 'short', day: 'numeric' });
    };

    // Function to group messages by date
    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, msg) => {
            const dateLabel = getDateLabel(msg.tdate);
            if (!acc[dateLabel]) acc[dateLabel] = [];
            acc[dateLabel].push(msg);
            return acc;
        }, {});
    };

    const groupedMessages = groupMessagesByDate(messages);


    const navigate = useNavigate();
    const [dropdownVisiblee, setDropdownVisiblee] = useState(false);

    const toggleDropdownn = () => {
        setDropdownVisiblee(!dropdownVisiblee);
    };

    const handleItemClick = (action) => {
        console.log(action);
        setDropdownVisiblee(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/');
    };



    return (
        <>
            <Sidebar />
            <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
                {!isMobile || !selectedContact ? (
                    <div style={{ backgroundColor: '#E6E8F4', width: isMobile ? '100%' : '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
                        <Row>
                            <Col>
                                <h4 style={{ margin: '0 0 10px', marginBottom: '20px' }}>Contacts</h4>
                            </Col>
                            <Col className='text-end'>
                                {/* <BsThreeDotsVertical style={{ fontSize: '20px' }} /> */}
                                <div style={{ position: 'relative' }}>
                                    <BsThreeDotsVertical

                                        size={30}
                                        onClick={toggleDropdownn}
                                        style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                    />
                                    {dropdownVisiblee && (
                                        <div className="dropdown-menu show" style={{
                                            position: 'absolute',
                                            right: 0,
                                            padding:'10px',
                                         
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                            zIndex: 1,
                                        }}>
                                            {/* <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div> */}
                                            <div onClick={handleLogout} className="dropdown-item">
                                                <FcSettings style={{ width: '20px', marginRight: '8px' }} />
                                                Logout
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
<br></br>
                        <div className="d-flex align-items-center mb-2" style={{ position: 'relative', width: '100%', }}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search"
                                className="form-control"
                                style={{
                                    height:'50px',
                                    paddingLeft: '40px',
                                    paddingRight: '40px',
                                    borderRadius: '25px',
                                    border: '1px solid rgb(125, 133, 195)',
                                    transition: 'border-color 0.3s',
                                }}
                                onFocus={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                                onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                            />
                            <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#ced4da' }} size={20} />
                        </div>

                        <div className="dropdown-scrollable" style={{
                            maxHeight: '585px',
                            overflowY: 'auto',

                            borderRadius: '5px',
                        }}>
                            {filteredContacts.map(contact => (
                                <div
                                    key={contact.id}
                                    onClick={() => handleContactClick(contact)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '9px',
                                        margin: '5px',
                                        borderBottom: '1px solid gray',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#7D86C3';
                                        e.currentTarget.style.borderRadius = '10px'
                                        e.currentTarget.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#E6E8F4';
                                        e.currentTarget.style.color = 'black';
                                    }}
                                >
                                    <Row>
                                        <Col> {contact.mobile}</Col>
                                        <Col className='text-end'>


                                            <BiChevronRight

                                                id="dropdown-custom-components"
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent dropdown from closing
                                                    toggleDropdown(contact.id); // Toggle dropdown
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>

                    </div>
                ) : null}
                <div style={{ width: isMobile ? '60%' : '37%', flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {selectedContact ? (
                        <>
                            <div style={{ borderBottom: '1px solid #ccc' }}>
                                <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}>
                                    <IoArrowBack style={{ cursor: 'pointer' }} onClick={handleBackClick} />
                                    <img src={usermsg2} style={{ margin: '5px', height: '28px' }} />
                                    {/* <IoMdContact style={{ fontSize: '30px', color: 'rgb(125, 133, 195)', margin: '5px' }} /> */}
                                    {selectedContact.mobile}
                                    <div style={{ marginLeft: '26px', fontSize: '20px' }}>
                                        {Object.keys(groupedMessages).length > 0 && Object.values(groupedMessages).some(messages => messages.length > 0) ? (
                                            (() => {
                                                // Get the last message from the last group (most recent)
                                                const latestMessage = Object.values(groupedMessages)
                                                    .flat()
                                                    .sort((a, b) => new Date(b.tdate) - new Date(a.tdate))[0]; // Sorting by date descending

                                                const formattedDate = new Date(latestMessage.tdate);
                                                const timeOptions = { hour: '2-digit', minute: '2-digit' };
                                                const time = formattedDate.toLocaleTimeString(undefined, timeOptions); // Format time without seconds

                                                // Calculate the difference in time between now and the latest message
                                                const now = new Date();
                                                const timeDifferenceInSeconds = Math.abs(now - formattedDate) / 1000; // Difference in seconds
                                                const timeDifferenceInMinutes = timeDifferenceInSeconds / 60; // Difference in minutes
                                                const timeDifferenceInHours = timeDifferenceInMinutes / 60; // Difference in hours

                                                let status = 'Offline'; // Default status

                                                if (timeDifferenceInSeconds < 300) { // Less than 5 minutes
                                                    status = 'Online';
                                                } else if (timeDifferenceInSeconds < 900) { // Less than 15 minutes
                                                    status = 'Active';
                                                } else if (timeDifferenceInMinutes < 60) { // Less than 60 minutes (1 hour)
                                                    status = `Last seen: ${Math.floor(timeDifferenceInMinutes)} minutes ago`; // Show minutes ago
                                                } else if (timeDifferenceInHours < 24) { // Less than 24 hours
                                                    const hoursAgo = Math.floor(timeDifferenceInHours);
                                                    status = `Last seen: ${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
                                                } else if (timeDifferenceInHours >= 24 && timeDifferenceInHours < 48) { // Yesterday
                                                    status = 'Last seen: Yesterday';
                                                } else { // Older than 48 hours
                                                    const lastSeenTime = formattedDate.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' });
                                                    status = `Last seen: ${lastSeenTime}`;
                                                }

                                                return (
                                                    <div>
                                                        {/* Display the status */}
                                                        <span style={{ margin: '5px', color: status === 'Online' ? 'green' : 'gray', fontSize: '18px' }}>
                                                            {status === 'Online' ? 'Online' : status} {/* Only show status, not time */}
                                                        </span>

                                                        {/* Show time if not online */}
                                                        {/* {status !== 'Online' && (
                                                        <span style={{ color: 'gray', fontSize: '15px' }}>
                                                            {time}
                                                        </span>
                                                    )} */}
                                                    </div>
                                                );
                                            })()
                                        ) : (
                                            <span style={{ margin: '5px', color: 'gray', fontSize: '15px' }}>Offline</span>
                                        )}

                                    </div>
                                </h3>
                            </div>

                            {/* <div style={{
                                flex: 1,
                                overflowY: 'scroll',
                                padding: '10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#E6E8F4',
                                backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,
                                borderTop: 'none'
                            }}> */}
                            {/* {messages.map(msg => (
                                    <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'You' ? 'right' : 'left' }}>
                                        <div style={{
                                            display: 'inline-block',
                                            padding: '8px',
                                            borderRadius: '10px',
                                            backgroundColor: msg.sender === 'you' ? '#dcf8c6' : '#ffffff',
                                            maxWidth: '70%',
                                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                        }}>
                                            <strong>{msg.sender === 'You' ? mobile : selectedContact.mobile}:</strong>
                                            <span> {msg.msg} </span>
                                            <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.tdate}</small>
                                        </div>
                                    </div>
                                ))} */}

                            {/* {messages.map((msg) => {
                                    // Format tdate (assuming it's in a format like "HH:mm:ss")
                                    const formattedDate = new Date(msg.tdate);
                                    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                                    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

                                    const date = formattedDate.toLocaleDateString(undefined, dateOptions);
                                    const time = formattedDate.toLocaleTimeString(undefined, timeOptions);

                                    // Check the structure of selectedContact and make sure it's correct
                                    console.log('selectedContact:', selectedContact);

                                    // Assuming selectedContact is an object and has a 'mobile' property
                                    const selectedMobile = selectedContact?.mobile; // Adjust according to your structure

                                    console.log(`selectedMobile: ${selectedMobile}, msg.mgrmobile: ${msg.mgrmobile}`);

                                    // Ensure both selectedMobile and msg.srcmobile are strings for comparison
                                    const isSender = String(msg.mgrmobile) === String(selectedMobile);
                                    const senderText = isSender ? selectedMobile : msg.mgrmobile;
                                    return (
                                        <div key={msg.tdate} style={{ display: 'flex', justifyContent: isSender ? 'flex-start' : ' flex-end', margin: '5px 0' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '8px',
                                                borderRadius: '10px',
                                                backgroundColor: isSender ? '#ffffff' : '#dcf8c6', // White for sender, green for others
                                                maxWidth: '70%',
                                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                            }}>
                                                {/* <strong className='text-start'>
                                                    {isSender ? selectedMobile : msg.srcmobile}:
                                                </strong> 
                                                  <strong className='text-start'>
                                                    {senderText}: 
                                                </strong>

                                                <br />
                                                <span>{msg.msg}</span>
                                                <br />
                                                <small style={{ fontSize: '10px', marginLeft: '10px', color: 'gray' }}>
                                                    {msg.timestamp || `${date} ${time}`}
                                                </small>
                                            </div>
                                        </div>
                                    );
                                })} */}

                            {/* {messages.map((msg) => {
                                    // Format tdate (assuming it's in a format like "HH:mm:ss")
                                    const formattedDate = new Date(msg.tdate);
                                    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                                    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

                                    const date = formattedDate.toLocaleDateString(undefined, dateOptions);
                                    const time = formattedDate.toLocaleTimeString(undefined, timeOptions);

                                    // Check the structure of selectedContact and make sure it's correct
                                    // console.log('selectedContact:', selectedContact);

                                    // Assuming selectedContact is an object and has a 'mobile' property
                                    const selectedMobile = selectedContact?.mobile; // Adjust according to your structure

                                    // console.log(`selectedMobile: ${selectedMobile}, msg.mgrmobile: ${msg.mgrmobile}`);

                                    // Ensure both selectedMobile and msg.mgrmobile are strings for comparison
                                    const isSender = String(msg.srcmobile) === String(selectedMobile);
                                    const senderText = isSender ? selectedMobile : msg.srcmobile;
                                    return (
                                        <div key={msg.tdate} style={{ display: 'flex', justifyContent: isSender ? 'flex-end' : 'flex-start', margin: '5px 0' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '8px',
                                                borderRadius: '10px',
                                                backgroundColor: isSender ? '#ffffff' : '#dcf8c6', // White for sender, green for others
                                                maxWidth: isMobile ? '60%' : '70%',
                                                fontSize: isMobile ? '15px' : '17px',
                                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                            }}>
                                                {/* <strong >
                                                    {isSender ? selectedMobile : msg.mgrmobile}
                                                </strong>   
                                                <strong className='text-start'>
                                                    {senderText}:
                                                </strong>

                                                <br />
                                                <span>{msg.msg}</span>
                                                <br />
                                                <small style={{ fontSize: isMobile ? '8px' : '13px', marginLeft: '10px', color: 'gray' }}>
                                                    {msg.timestamp || `${date} ${time}`}
                                                </small>
                                            </div>
                                        </div>
                                    );
                                })} */}

                            <div style={{
                                flex: 1,
                                overflowY: 'scroll',
                                padding: '10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#E6E8F4',
                                backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg3})`,
                                borderTop: 'none',
                                paddingBottom: '50px',
                            }}>
                                {Object.keys(groupedMessages).map((dateLabel) => (
                                    <div key={dateLabel}>
                                        {/* Date Label */}
                                        <div style={{
                                            textAlign: 'center',
                                            margin: '20px 0',
                                            fontSize: '14px',
                                            color: '#888',
                                        }}>
                                            <strong>{dateLabel}</strong>
                                        </div>

                                        {/* Messages for the specific date */}
                                        {groupedMessages[dateLabel].map((msg) => {
                                            const formattedDate = new Date(msg.tdate);
                                            const timeOptions = { hour: '2-digit', minute: '2-digit' };
                                            const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                                            const time = formattedDate.toLocaleTimeString(undefined, timeOptions);  // Time without seconds
                                            const date = formattedDate.toLocaleDateString(undefined, dateOptions);

                                            const selectedMobile = selectedContact?.mobile;
                                            const isSender = String(msg.srcmobile) === String(selectedMobile);
                                            const senderText = isSender ? selectedMobile : msg.srcmobile;
                                            // console.log(isSender)
                                            return (
                                                <div key=  { ` ${date}`} style={{
                                                    
                                                    display: 'flex',
                                                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                                                    marginBottom: '10px',
                                                    alignItems: 'flex-end',
                                                }}>

                                                    {/* Avatar for sender */}
                                                    {isSender && (
                                                        <div style={{
                                                            marginRight: '10px',
                                                            alignSelf: 'flex-start',
                                                        }}>
                                                            <img
                                                                src={usermsg} // Dynamic sender avatar
                                                                alt="Sender Avatar"
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    border: '2px solid #ccc',
                                                                }} />
                                                        </div>
                                                    )}

                                                    {/* Message Bubble */}
                                                    <div className={`message-bubble ${isSender ? 'sender' : 'receiver'}`} style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        position: 'relative',
                                                        padding: '10px 15px',
                                                        borderRadius: '20px',
                                                        maxWidth: '70%',
                                                        fontSize: '17px',
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                        backgroundColor: isSender ? '#0084FF' : '#ffffff',
                                                        color: isSender ? '#fff' : '#333',
                                                    }}>

                                                        {/* Sender or receiver message */}
                                                        <strong style={{ fontSize: '16px', marginBottom: '5px' }}>{senderText}</strong>

                                                        <p style={{ margin: '0', lineHeight: '1.5' }}>{msg.msg}</p>

                                                        {/* Date and Time - Displaying inside the message bubble */}
                                                        <small style={{
                                                            fontSize: '12px',
                                                            color: 'black',
                                                            marginTop: '8px', // Spacing between message content and timestamp
                                                            textAlign: 'right', // Align timestamp for sender
                                                        }}>
                                                            {msg.timestamp || ` ${time}`}  {/* Display without seconds */}
                                                        </small>
                                                    </div>

                                                    {/* Avatar for receiver */}
                                                    {!isSender && (
                                                        <div style={{
                                                            marginLeft: '10px',
                                                            alignSelf: 'flex-end',
                                                        }}>
                                                            <img
                                                                src={usermsg1} // Dynamic receiver avatar
                                                                alt="Receiver Avatar"
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    border: '2px solid #ccc',
                                                                }} />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>





                            {/* </div> */}


                            <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{
                                            padding: '5px',
                                            backgroundColor: '#E6E8F4',
                                            border: '1px solid #ccc',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                    >
                                        <BsEmojiGrin style={{ color: 'black', fontSize: '28px' }} />
                                    </button>

                                    {/* Emoji picker will now appear above the button */}
                                    {showEmojiPicker && (
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '40px', // Adjust this value depending on your layout to move the picker above the button
                                            left: '0',
                                            zIndex: '1000', // Ensure the emoji picker is on top of other elements
                                        }}>
                                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                                        </div>
                                    )}
                                </div>

                                <input
                                    type="text"
                                    value={newMessage}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="Type a message...."
                                    style={{
                                        width: isMobile ? '60px' : '40px',
                                        fontSize: isMobile ? '12px' : '20px',
                                        flex: 1,
                                        padding: isMobile ? '5px' : '10px',

                                        paddingLeft: '40px',
                                        paddingRight: '40px',
                                        borderRadius: isMobile ? '15px' : '25px',
                                        border: '1px solid black',
                                    }}
                                    onFocus={(e) => e.target.style.borderColor = 'blue'}
                                    onBlur={(e) => e.target.style.borderColor = 'black'}
                                />

                                <button
                                    onClick={handleSendMessage}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '10px 15px',
                                        backgroundColor: 'rgb(125, 133, 195)',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '20px'
                                    }}
                                >
                                    Send
                                    {newMessage.trim() && ( // Only show the button if there is text
                                        <IoSend style={{ margin: '5px' }} />
                                    )}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            {!isMobile && !selectedContact && (
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.
                                    <div className="loader-container">
                                        <div className="loader">
                                        <FcSynchronize className="loader-icon" />
                                        </div>
                                    </div></div>
                            )}
                        </>
                    )}
                </div>
            </div>



        </>
    );
};

export default SendUserMessage;

