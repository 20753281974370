// import React, { useEffect, useState } from 'react';
// import './styles.css';
// import axios from 'axios';
// import { IoSend } from 'react-icons/io5';
// import { BsEmojiGrin, BsThreeDotsVertical } from 'react-icons/bs';
// import EmojiPicker from 'emoji-picker-react';
// import { IoIosSearch, IoMdContact } from 'react-icons/io';
// import { MdOutlineSendAndArchive } from 'react-icons/md';
// import { Col, Row } from 'react-bootstrap';
// import Sidebar from '../Chat/Sidebar';
// import bg from './bg.jpeg';

// const SendMGR = ({ srcmobile,mobile }) => {
//   const [status, setStatus] = useState("");
//   const [message, setMessage] = useState("");
//   const [srcMobile, setSrcMobile] = useState("");  // State to capture source mobile
//   const [destMobile, setDestMobile] = useState("");  // State to capture destination mobile
//   const [contacts, setContacts] = useState([]);
//     const [selectedContact, setSelectedContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [searchTerm, setSearchTerm] = useState('');
//     const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Show/hide emoji picker
//     const [selectedEmoji, setSelectedEmoji] = useState(''); // Selected emoji for the message
//     const [response, setResponse] = useState('');
//     const [error, setError] = useState('');


//     const [departUserMobile, setDepartUserMobile] = useState(''); // Store the mobile number from departuser


//     useEffect(() => {
//         const fetchUserData = async () => {
//             const userIdss = localStorage.getItem('userId');
//             const userIdInt = parseInt(userIdss, 10);

//             if (userIdInt) {
//                 try {
//                     const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//                     const userData = await userResponse.json();

//                     // Assuming the mobile number is in userData.departuser and mapping it
//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                         setDepartUserMobile(userMobile); // Store the mobile number
//                     }
//                 } catch (error) {
//                     console.error('Error fetching departuser data:', error);
//                 }
//             }
//         };

//         fetchUserData();
//     }, []);

//     useEffect(() => {
//         const fetchGroups = async () => {
//             try {
//                 const token = localStorage.getItem('token'); // Assuming the token is stored under this key
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
//                         },
//                     });
//                     const userData = await response.json();
//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                         setDepartUserMobile(userMobile); // Store the mobile number
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         };

//         fetchGroups();
//     }, []); // Runs once on component mount





//     // useEffect(() => {
//     //     const fetchUserData = async () => {
//     //         const userIdss = localStorage.getItem('userId');
//     //         const userIdInt = parseInt(userIdss, 10);

//     //         if (userIdInt) {
//     //             try {
//     //                 const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//     //                 const userData = await userResponse.json();

//     //                 // Assuming the mobile number is in userData.departuser and mapping it
//     //                 if (userData.departuser && userData.departuser.length > 0) {
//     //                     const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//     //                     setDepartUserMobile(userMobile); // Store the mobile number
//     //                 }
//     //             } catch (error) {
//     //                 console.error('Error fetching departuser data:', error);
//     //             }
//     //         }
//     //     };

//     //     fetchUserData();
//     // }, []);


//     const fetchUserData = async () => {
//         const userIdss = localStorage.getItem('userId');
//         const userIdInt = parseInt(userIdss, 10);

//         if (userIdInt) {
//             try {
//                 const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//                 const userData = await userResponse.json();

//                 // Assuming the mobile number is in userData.departuser and mapping it
//                 if (userData.departuser && userData.departuser.length > 0) {
//                     const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
//                     setDepartUserMobile(userMobile); // Store the mobile number
//                     console.log("departUserMobile set to:", userMobile);
//                 }
//             } catch (error) {
//                 console.error('Error fetching departuser data:', error);
//             }
//         }
//     };

//     useEffect(() => {
//         const fetchUserData = async () => {
//             const userIdss = localStorage.getItem('userId');
//             const userIdInt = parseInt(userIdss, 10);

//             if (userIdInt) {
//                 try {
//                     const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
//                     const userData = await userResponse.json();

//                     if (userData.departuser && userData.departuser.length > 0) {
//                         const userMobile = userData.departuser[0].mobile;
//                         setDepartUserMobile(userMobile);
//                         console.log("departUserMobile set to:", userMobile);
//                     }
//                 } catch (error) {
//                     console.error('Error fetching departuser data:', error);
//                 }
//             }
//         };

//         fetchUserData();
//     }, []);



//     console.log(departUserMobile);


//     useEffect(() => {
//         const fetchContactsWithUserId = async () => {
//             const userId = localStorage.getItem('userId');
//             const userIdInt = parseInt(userId, 10);

//             if (userIdInt) {
//                 try {
//                     const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
//                         method: 'GET',
//                     });
//                     const data = await response.json();
//                     setContacts(data.contacts || []);
//                 } catch (error) {
//                     console.error('Error fetching groups:', error);
//                 }
//             }
//         };

//         fetchContactsWithUserId();
//     }, []);

//     useEffect(() => {
//         const fetchContacts = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     });
//                     const results = await response.json();
//                     setContacts(results);
//                 }
//             } catch (error) {
//                 console.error('Error fetching contacts:', error);
//             }
//         };

//         fetchContacts();
//     }, []);


//   const sendSMS = async () => {
//     if (!srcMobile || !destMobile) {
//       setStatus("Please enter both source and destination mobile numbers.");
//       return;
//     }
//     if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send

//     const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
//     // Create a new message object to display in the chat
//     const newMessageObject = {
//         id: messages.length + 1,
//         msg: messageToSend,
//         sender: 'me',
//         timestamp: new Date().toLocaleString(undefined, options), // Custom formatting
//         // tdate: 
//     };

//     setMessages((prevMessages) => [...prevMessages, newMessageObject]);
//     try {
//       const response = await fetch(`https://emedha.com/chatapp/sendmgr.php?srcmobile=${srcMobile}&destmobile=${destMobile}&message=${message}`);
//       const data = await response.json();  // Assuming API returns JSON

//       if (response.ok) {
//         setStatus("Message Sent Successfully!");
//       } else {
//         setStatus("Failed to Send Message.");
//       }
//     } catch (error) {
//       setStatus("Error: " + error.message);
//     }
//   };


//   const handleEmojiClick = (emojiObject) => {
//     // Append the selected emoji to the current message
//     setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
//     setShowEmojiPicker(false); // Close the emoji picker after selection
// };

// const filteredContacts = contacts.filter(contact =>
//     contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
// );

// const toggleDropdown = (contactId) => {
//     if (activeDropdown === contactId) {
//         setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
//     } else {
//         setActiveDropdown(contactId); // Show the dropdown for the clicked contact
//     }
// };


// const [loading, setLoading] = useState(false);
// // const [error, setError] = useState('');

// const fetchMessagess = async (contact) => {
//   try {
//     setLoading(true);
//     setError(''); // Clear previous errors

//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError('No token found. Please log in.');
//       return;
//     }

//     const response = await axios.get('http://localhost:3009/api/messages', {
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//       params: { srcmobile: contact }
//     });

//     if (response.data.result) {
//       setMessages(response.data.result);
//     } else {
//       setMessages([]);
//     }
//   } catch (err) {
//     console.error('Error fetching messages:', err);
//     setError('Error fetching messages. Please try again later.');
//   } finally {
//     setLoading(false);
//   }
// };

//   useEffect(() => {
//     if (selectedContact) {
//       fetchMessagess(selectedContact);
//     }
//   }, [selectedContact, srcmobile]);

// const handleContactClick = (contact) => {
//     // fetchMessagess(contact);
//     setSelectedContact(contact);
//     fetchMessagess(contact);

//     // Fetch messages between selected contact and departUserMobile
//     const fetchMessages = async () => {
//         try {
//             const res = await axios.get('http://localhost:3009/api/getMessages', {
//                 params: {
//                     mgrmobile: departUserMobile,
//                     srcmobile: contact.mobile,
//                 }
//             });

//             setMessages(res.data.messages);
//         } catch (error) {
//             console.error('Error fetching messages:', error);
//         }
//     };

//     fetchMessages();
// };


//   return (
//     // <div>
//     //   <h2>Send SMS</h2>
//     //   <div>
//     //     <label htmlFor="srcMobile">Source Mobile:</label>
//     //     <input
//     //       type="text"
//     //       id="srcMobile"
//     //       placeholder="Enter source mobile"
//     //       value={srcMobile}
//     //       onChange={(e) => setSrcMobile(e.target.value)}
//     //     />
//     //   </div>
//     //   <div>
//     //     <label htmlFor="destMobile">Destination Mobile:</label>
//     //     <input
//     //       type="text"
//     //       id="destMobile"
//     //       placeholder="Enter destination mobile"
//     //       value={destMobile}
//     //       onChange={(e) => setDestMobile(e.target.value)}
//     //     />
//     //   </div>
//     //   <div>
//     //     <textarea
//     //       placeholder="Type your message here"
//     //       value={message}
//     //       onChange={(e) => setMessage(e.target.value)}
//     //     ></textarea>
//     //   </div>
//     //   <br />
//     //   <button onClick={sendSMS}>Send Message</button>
//     //   <p>Status: {status}</p>

//     //   <h2>Items List</h2>
//     //   <div className="item-list">
//     //     {(items || []).map((item, index) => (
//     //       <div className="item" key={index}>
//     //         <h3>{item.title}</h3>
//     //         <p>{item.description}</p>
//     //       </div>
//     //     ))}
//     //   </div>
//     // </div>

//     <>
//     <Sidebar />
//     <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
//         <div style={{ backgroundColor: '#E6E8F4', width: '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
//             <Row>
//                 <Col>
//                     <h3 style={{ margin: '0 0 10px' }}>Contacts</h3>
//                 </Col>
//                 <Col className='text-end'>
//                     <BsThreeDotsVertical style={{ fontSize: '20px' }} />
//                 </Col>
//             </Row>

//             <div className="d-flex align-items-center mb-2" style={{ position: 'relative', width: '100%' }}>
//                 <input
//                     type="text"
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     placeholder="Search"
//                     className="form-control"
//                     style={{
//                         paddingLeft: '40px',
//                         paddingRight: '40px',
//                         borderRadius: '25px',
//                         border: '1px solid rgb(125, 133, 195)',
//                         transition: 'border-color 0.3s',
//                     }}
//                     onFocus={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
//                     onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
//                 />
//                 <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#ced4da' }} size={20} />
//             </div>

//             <div className="dropdown-scrollable" style={{
//                 maxHeight: '585px',
//                 overflowY: 'auto',

//                 borderRadius: '5px',
//             }}>
//                 {filteredContacts.map(contact => (
//                     <div
//                         key={contact.id}
//                         onClick={() => handleContactClick(contact)}
//                         style={{
//                             cursor: 'pointer',
//                             padding: '10px',
//                             margin: '5px',
//                             borderBottom: '1px solid #eee',
//                         }}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.backgroundColor = '#7D86C3';
//                             e.currentTarget.style.color = 'white';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.backgroundColor = '#E6E8F4';
//                             e.currentTarget.style.color = 'black';
//                         }}
//                     >
//                         <Row>
//                             <Col>{contact.mobile}</Col>
//                             <Col className='text-end'>
//                                 <MdOutlineSendAndArchive
//                                     id="dropdown-custom-components"
//                                     style={{ fontSize: '20px', cursor: 'pointer' }}
//                                     onClick={(e) => {
//                                         e.stopPropagation(); // Prevent dropdown from closing
//                                         toggleDropdown(contact.id); // Toggle dropdown
//                                     }}
//                                 />
//                             </Col>
//                         </Row>
//                     </div>
//                 ))}
//             </div>

//         </div>

//         <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
//             {selectedContact ? (
//                 <>
//                     <div style={{ borderBottom: '1px solid #ccc' }}>
//                         <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}>
//                             <IoMdContact style={{ fontSize: '30px', color: 'rgb(125, 133, 195)', margin: '5px' }} />
//                             {selectedContact.mobile}
//                         </h3>
//                     </div>

//                     <div style={{
//                         flex: 1,
//                         overflowY: 'scroll',
//                         padding: '10px',
//                         border: '1px solid #ccc',
//                         backgroundColor: '#E6E8F4',
//                         backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,
//                         borderTop: 'none'
//                     }}>
//                         {/* {messages.map(msg => (
//                             <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'You' ? 'right' : 'left' }}>
//                                 <div style={{
//                                     display: 'inline-block',
//                                     padding: '8px',
//                                     borderRadius: '10px',
//                                     backgroundColor: msg.sender === 'you' ? '#dcf8c6' : '#ffffff',
//                                     maxWidth: '70%',
//                                     boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
//                                 }}>
//                                     <strong>{msg.sender === 'You' ? mobile : selectedContact.mobile}:</strong>
//                                     <span> {msg.msg} </span>
//                                     <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.tdate}</small>
//                                 </div>
//                             </div>
//                         ))} */}
//                         {messages.map(msg => {
//                             // Format tdate (assuming it's in a format like "HH:mm:ss")
//                             const formattedDate = new Date(msg.tdate);
//                             const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
//                             const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

//                             const date = formattedDate.toLocaleDateString(undefined, dateOptions);
//                             const time = formattedDate.toLocaleTimeString(undefined, timeOptions);
//                             // const isCurrentUser = msg.sender === 'me';

//                             return (
//                                 <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'You' ? 'left' : ' right' }}>
//                                     <div style={{
//                                         display: 'inline-block',
//                                         padding: '8px',
//                                         borderRadius: '10px',
//                                         backgroundColor: msg.sender === 'You' ? '#ffffff' : '#dcf8c6',
//                                         maxWidth: '70%',
//                                         boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
//                                     }}>
//                                         <strong className='text-start'>{msg.sender === 'You' ? mobile : selectedContact.mobile}:</strong>
//                                         <br></br>
//                                         <span> {msg.msg} </span>
//                                         {/* <span>{msg.timestamp}</span> */}
//                                         <small style={{fontSize:'10px', marginLeft: '10px', color: 'gray' }}>
//                                         {/* {`${date} ${time}`}  */}
//                                         {msg.sender ? msg.timestamp : `${date} ${time}`} {/* Conditional rendering */}

//                                         </small>
//                                     </div>
//                                 </div>
//                             );
//                         })}

//                     </div>


//                     <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
//                         <div style={{ position: 'relative' }}>
//                             <button
//                                 style={{
//                                     padding: '5px',
//                                     backgroundColor: '#E6E8F4',
//                                     border: '1px solid #ccc',
//                                     borderRadius: '50%',
//                                     cursor: 'pointer'
//                                 }}
//                                 onClick={() => setShowEmojiPicker(!showEmojiPicker)}
//                             >
//                                 <BsEmojiGrin style={{ color: 'black', fontSize: '28px' }} />
//                             </button>

//                             {/* Emoji picker will now appear above the button */}
//                             {showEmojiPicker && (
//                                 <div style={{
//                                     position: 'absolute',
//                                     bottom: '40px', // Adjust this value depending on your layout to move the picker above the button
//                                     left: '0',
//                                     zIndex: '1000', // Ensure the emoji picker is on top of other elements
//                                 }}>
//                                     <EmojiPicker onEmojiClick={handleEmojiClick} />
//                                 </div>
//                             )}
//                         </div>

//                         <input
//                             type="text"
//                             value={newMessage}
//                             onChange={(e) => setNewMessage(e.target.value)}
//                             placeholder="Type a message...."
//                             style={{
//                                 flex: 1,
//                                 padding: '10px',
//                                 paddingLeft: '40px',
//                                 paddingRight: '40px',
//                                 borderRadius: '25px',
//                                 border: '1px solid black',
//                             }}
//                             onFocus={(e) => e.target.style.borderColor = 'blue'}
//                             onBlur={(e) => e.target.style.borderColor = 'black'}
//                         />

//                         <button
//                             onClick={sendSMS}
//                             style={{
//                                 marginLeft: '10px',
//                                 padding: '10px 15px',
//                                 backgroundColor: 'rgb(125, 133, 195)',
//                                 color: 'white',
//                                 border: 'none',
//                                 borderRadius: '20px'
//                             }}
//                         >
//                             Send
//                             {newMessage.trim() && ( // Only show the button if there is text
//                                 <IoSend style={{ margin: '5px' }} />
//                             )}
//                         </button>
//                     </div>
//                 </>
//             ) : (
//                 <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.</div>
//             )}
//         </div>
//     </div>
// </>
//   );
// };

// export default SendMGR;









import React, { useEffect, useState } from 'react';
import './styles.css';
import Sidebar from '../Chat/Sidebar';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { BsEmojiGrin, BsThreeDotsVertical } from 'react-icons/bs';
import { IoIosSearch, IoMdContact } from 'react-icons/io';
import bg from './bg.jpeg';
import bg1 from './bg1.jpg';
import bg3 from './bg3.png';
import { MdAccountCircle, MdOutlineSendAndArchive } from 'react-icons/md';
import { IoArrowBack, IoSend } from 'react-icons/io5';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import twemoji from 'twemoji';
import { useNavigate } from 'react-router-dom';
// import './SendMGR.css';
import usermsg1 from './usermsg1.png';
import usermsg from './usermsg.png';
import usermsg2 from './usermsg2.png';
import { RiCheckDoubleLine } from 'react-icons/ri';
import { BiChevronRight } from 'react-icons/bi';
import { FcCheckmark, FcSettings } from "react-icons/fc";

const SendMGR = ({ items, mobile, srcmobile }) => {
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [srcMobile, setSrcMobile] = useState("");  // State to capture source mobile
    const [destMobile, setDestMobile] = useState("");  // State to capture destination mobile
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Show/hide emoji picker
    const [selectedEmoji, setSelectedEmoji] = useState('');
    const [newMessage, setNewMessage] = useState('');

    const [departUserMobile, setDepartUserMobile] = useState(''); // Store the mobile number from departuser

    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();

                    // Assuming the mobile number is in userData.departuser and mapping it
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                        setDepartUserMobile(userMobile); // Store the mobile number
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const userData = await response.json();
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                        setDepartUserMobile(userMobile); // Store the mobile number
                    }
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []); // Runs once on component mount

    const fetchUserData = async () => {
        const userIdss = localStorage.getItem('userId');
        const userIdInt = parseInt(userIdss, 10);

        if (userIdInt) {
            try {
                const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                const userData = await userResponse.json();

                // Assuming the mobile number is in userData.departuser and mapping it
                if (userData.departuser && userData.departuser.length > 0) {
                    const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                    setDepartUserMobile(userMobile); // Store the mobile number
                    console.log("departUserMobile set to:", userMobile);
                }
            } catch (error) {
                console.error('Error fetching departuser data:', error);
            }
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();

                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile;
                        setDepartUserMobile(userMobile);
                        console.log("departUserMobile set to:", userMobile);
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    console.log(departUserMobile);

    useEffect(() => {
        const fetchContactsWithUserId = async () => {
            const userId = localStorage.getItem('userId');
            const userIdInt = parseInt(userId, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
                        method: 'GET',
                    });
                    const data = await response.json();
                    setContacts(data.contacts || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        fetchContactsWithUserId();
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const results = await response.json();
                    setContacts(results);
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchContacts();
    }, []);

    const [response, setResponse] = useState('');

    const sendSMS = async () => {
        if (!newMessage.trim()) {
            setStatus("Please enter a message.");
            return;  // Exit if message is empty
        }

        // Combine text message and emoji (if there's any selected emoji)
        const messageToSend = newMessage + selectedEmoji;  // Assuming selectedEmoji is available

        // const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };

        // // Create a message object to show in the chat UI
        // const newMessageObject = {
        //     id: messages.length + 1,  // Incremental ID for each new message
        //     msg: messageToSend,       // Text content of the message
        //     // sender: 'me',             // Assuming the sender is 'me'
        //     timestamp: new Date().toLocaleString(undefined, options),  // Custom timestamp
        // };

        // // Update the state to include the newly added message in the chat UI
        // setMessages((prevMessages) => [...prevMessages, newMessageObject]);

        try {
            const mgrmobile  = departUserMobile;
            const  mobile = selectedContact.mobile;  // Manager's mobile number
             // Selected contact's mobile number

            if (!mgrmobile) {
                throw new Error("Both source and destination mobile numbers are required.");
            }

            // Sending the message via the API call
            const res = await fetch(`https://emedha.com/chatapp/sendmgr.php?srcmobile=${mgrmobile}&destmobile=${mobile}&message=${encodeURIComponent(messageToSend)}`, {
                method: 'POST',  // Using POST method as per the API specification
            });

            // Check if the response is OK
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const contentType = res.headers.get("content-type");
            let data;

            // If the response is JSON, parse it; otherwise, handle it as plain text
            if (contentType && contentType.includes("application/json")) {
                data = await res.json();
            } else {
                data = await res.text();
            }

            // Handle the API response here
            setResponse(data);  // Update state with the response (for success or failure feedback)
            fetchMessages(selectedContact);
        } catch (err) {
            // Handle errors (network issues, missing data, etc.)
            console.error('Error in sendSMS:', err);  // Log detailed error
            setStatus("Error: " + err.message);  // Show error message to user
        } finally {
            // Clear the input field and reset emoji
            setNewMessage('');  // Clear the message input field
            setSelectedEmoji('');  // Reset the emoji picker (if applicable)
        }
    };
    console.log(response);

    const [searchTerm, setSearchTerm] = useState('');
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleDropdown = (contactId) => {
        if (activeDropdown === contactId) {
            setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
        } else {
            setActiveDropdown(contactId); // Show the dropdown for the clicked contact
        }
    };
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);

    const filteredContacts = contacts.filter(contact =>
        contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleEmojiClick = (emojiObject) => {
        // Append the selected emoji to the current message
        setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false); // Close the emoji picker after selection
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default newline behavior
            sendSMS(); // Call the send message function
        }
    };
    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();

                    // Assuming userData.departuser is an array, and we need the first one
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile;
                        setDepartUserMobile(userMobile);  // Set the departUserMobile
                        setSrcMobile(userMobile);  // Set srcMobile directly here
                        console.log("departUserMobile set to:", userMobile);  // Debug log to verify it's being set
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [contact, setContact] = useState({});

    const fetchMessagess = async (contact) => {
        try {
            setLoading(true);
            setError(''); // Clear previous errors

            const token = localStorage.getItem('token');
            if (!token) {
                setError('No token found. Please log in.');
                return;
            }

            const response = await axios.get('http://localhost:3009/api/messages', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: { srcmobile: contact }
            });

            if (response.data.result) {
                setMessages(response.data.result);
            } else {
                setMessages([]);
            }
        } catch (err) {
            console.error('Error fetching messages:', err);
            setError('Error fetching messages. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (selectedContact) {
            fetchMessagess(selectedContact);
        }
    }, [selectedContact, srcmobile]);


    // const handleContactClick = (contact) => {
    //     setSelectedContact(contact);
    //     setDestMobile(contact.mobile);  // Ensure this updates destMobile correctly
    //     setMessages([]);  // Reset messages for the selected contact
    //     console.log("Contact selected:", contact.mobile);  // Debug log to ensure this is working
    //  };
    const [showDropdown, setShowDropdown] = useState(false);



        // Fetch messages between selected contact and departUserMobile
        const fetchMessages = async (contact) => {
            try {
                const res = await axios.get('https://demochatapi.emedha.in/api/getMessages', {
                    params: {
                        mgrmobile   : departUserMobile,
                         srcmobile    : contact.mobile,
                    }
                });

                // If srcmobile matches selectedContact, set messages to display on the left side
                // if (contact.mobile === selectedContact) {
                //     // Set messages to the state
                // }
                console.log('API Response:', res.data);
                setMessages(res.data.messages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        // fetchMessages();
        const handleContactClick = (contact) => {
            // setShowDropdown(false);
             fetchMessages(contact);
            setSelectedContact(contact);
    
            fetchMessagess(contact);
    };




    // Detect mobile screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialize on component mount

        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const navigate = useNavigate();



    // const handleBackClick = () => {


    //     navigate('/sendMGR'); 
    // };


    const [isMobile, setIsMobile] = useState(false); // Mobile detection state
    const [showContacts, setShowContacts] = useState(true); // State to toggle the contact list visibility

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialize on component mount

        return () => window.removeEventListener('resize', handleResize);
    }, []);




    const handleBackClick = () => {
        window.location.reload();
        setShowContacts(false); // Hide contacts when back is clicked
    };




    const getDateLabel = (date) => {
        const today = new Date();
        const msgDate = new Date(date);

        const isToday = today.toDateString() === msgDate.toDateString();
        if (isToday) return 'Today';

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const isYesterday = yesterday.toDateString() === msgDate.toDateString();
        if (isYesterday) return 'Yesterday';

        return msgDate.toLocaleDateString(undefined, { weekday: 'long', month: 'short', day: 'numeric' });
    };

    // Function to group messages by date
    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, msg) => {
            const dateLabel = getDateLabel(msg.tdate);
            if (!acc[dateLabel]) acc[dateLabel] = [];
            acc[dateLabel].push(msg);
            return acc;
        }, {});
    };

    const groupedMessages = groupMessagesByDate(messages);



    const [dropdownVisiblee, setDropdownVisiblee] = useState(false);

    const toggleDropdownn = () => {
        setDropdownVisiblee(!dropdownVisiblee);
    };

    const handleItemClick = (action) => {
        console.log(action);
        setDropdownVisiblee(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/');
    };



    return (
        <>
            <Sidebar />
            <div id='main' style={{ display: 'flex', height: '100vh' }}>
                {!isMobile || !selectedContact ? (
                    <div style={{ backgroundColor: '#E6E8F4', width: isMobile ? '100%' : '17%', borderRight: '1px solid #ccc', padding: '10px' }}>
                        <Row >
                            <Col>
                                <h4 style={{ margin: '0 0 10px', marginBottom: '20px' }}>Contacts</h4>
                            </Col>
                            <Col className='text-end'>
                                {/* <BsThreeDotsVertical style={{ fontSize: '20px' }} /> */}
                                <div style={{ position: 'relative' }}>
                                <BsThreeDotsVertical

                               
                                    size={30}
                                    onClick={toggleDropdownn}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisiblee && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        {/* <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div> */}
                                        <div onClick={handleLogout} className="dropdown-item">
                                        <FcSettings style={{width:'20px', marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                            </Col>
                        </Row>
                        <br></br>

                        <div className="d-flex align-items-center mb-2" style={{ position: 'relative', width: '100%' }}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search"
                                className="form-control"
                                style={{
                                    height:'50px',
                                    paddingLeft: '40px',
                                    paddingRight: '40px',
                                    borderRadius: '25px',
                                    border: '1px solid rgb(125, 133, 195)',
                                    transition: 'border-color 0.3s',
                                }}
                                onFocus={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                                onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                            />
                            <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#ced4da' }} size={20} />
                        </div>

                        <div className="dropdown-scrollable" style={{
                            maxHeight: '585px',
                            overflowY: 'auto',

                            borderRadius: '5px',
                        }}>
                            {/* {filteredContacts.map(contact => (
                                <div
                                    key={contact.id}
                                    onClick={() => handleContactClick(contact)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        margin: '5px',
                                        borderBottom: '1px solid #eee',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#7D86C3';
                                        e.currentTarget.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#E6E8F4';
                                        e.currentTarget.style.color = 'black';
                                    }}
                                >
                                    <Row>
                                        <Col> {contact.mobile} {contact.tdate}</Col>
                                        <Col className='text-end'>
                                            <MdOutlineSendAndArchive
                                                id="dropdown-custom-components"
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent dropdown from closing
                                                    toggleDropdown(contact.id); // Toggle dropdown
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))} */}

                            {filteredContacts.map(contact => (
                                <div
                                    key={contact.id}
                                    onClick={() => handleContactClick(contact)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        margin: '5px',
                                        borderBottom: '1px solid gray',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#7D86C3';
                                        e.currentTarget.style.borderRadius = '10px'
                                        e.currentTarget.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#E6E8F4';
                                        e.currentTarget.style.color = 'black';
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            {contact.mobile}

                                        </Col>
                                        <Col className='text-end'>

                                            <BiChevronRight
                                                id="dropdown-custom-components"
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent dropdown from closing
                                                    toggleDropdown(contact.id); // Toggle dropdown
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}


                        </div>

                    </div>
                ) : null}
                <div style={{ width: isMobile ? '60%' : '37%', flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {selectedContact ? (
                        <>
                            <div style={{ borderBottom: '1px solid #ccc' }}>

                                <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}>
                                    <IoArrowBack style={{ cursor: 'pointer' }} onClick={handleBackClick} />  <img src={usermsg2} style={{ margin: '5px', height: '28px' }} />
                                    {/* <IoMdContact style={{ fontSize: '30px', color: 'rgb(125, 133, 195)', margin: '5px' }} /> */}

                                    {selectedContact.mobile}
                                    <div style={{ marginLeft: '26px', fontSize: '20px' }}>
                                        {Object.keys(groupedMessages).length > 0 && Object.values(groupedMessages).some(messages => messages.length > 0) ? (
                                            (() => {
                                                // Get the last message from the last group (most recent)
                                                const latestMessage = Object.values(groupedMessages)
                                                    .flat()
                                                    .sort((a, b) => new Date(b.tdate) - new Date(a.tdate))[0]; // Sorting by date descending

                                                const formattedDate = new Date(latestMessage.tdate);
                                                const timeOptions = { hour: '2-digit', minute: '2-digit' };
                                                const time = formattedDate.toLocaleTimeString(undefined, timeOptions); // Format time without seconds

                                                // Calculate the difference in time between now and the latest message
                                                const now = new Date();
                                                const timeDifferenceInSeconds = Math.abs(now - formattedDate) / 1000; // Difference in seconds
                                                const timeDifferenceInMinutes = timeDifferenceInSeconds / 60; // Difference in minutes
                                                const timeDifferenceInHours = timeDifferenceInMinutes / 60; // Difference in hours

                                                let status = 'Offline'; // Default status

                                                if (timeDifferenceInSeconds < 300) { // Less than 5 minutes
                                                    status = 'Online';
                                                } else if (timeDifferenceInSeconds < 900) { // Less than 15 minutes
                                                    status = 'Active';
                                                } else if (timeDifferenceInMinutes < 60) { // Less than 60 minutes (1 hour)
                                                    status = `Last seen: ${Math.floor(timeDifferenceInMinutes)} minutes ago`; // Show minutes ago
                                                } else if (timeDifferenceInHours < 24) { // Less than 24 hours
                                                    const hoursAgo = Math.floor(timeDifferenceInHours);
                                                    status = `Last seen: ${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
                                                } else if (timeDifferenceInHours >= 24 && timeDifferenceInHours < 48) { // Yesterday
                                                    status = 'Last seen: Yesterday';
                                                } else { // Older than 48 hours
                                                    const lastSeenTime = formattedDate.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' });
                                                    status = `Last seen: ${lastSeenTime}`;
                                                }

                                                return (
                                                    <div>
                                                        {/* Display the status */}
                                                        <span style={{ margin: '5px', color: status === 'Online' ? 'green' : 'gray', fontSize: '18px' }}>
                                                            {status === 'Online' ? 'Online' : status} {/* Only show status, not time */}
                                                        </span>

                                                        {/* Show time if not online */}
                                                        {/* {status !== 'Online' && (
                                                        <span style={{ color: 'gray', fontSize: '15px' }}>
                                                            {time}
                                                        </span>
                                                    )} */}
                                                    </div>
                                                );
                                            })()
                                        ) : (
                                            <span style={{ margin: '5px', color: 'gray', fontSize: '15px' }}>Offline</span>
                                        )}

                                    </div>

                                </h3>
                            </div>





                            {/* {isMobile && showDropdown && (     onClick={() => setShowDropdown(!showDropdown)}
              <Dropdown.Menu show style={{ position: 'absolute', zIndex: '1000' }}>
                {contacts.map((contact) => (
                  <Dropdown.Item
                    key={contact.id}
                    onClick={() => handleContactClick(contact)}
                  >
                    {contact.mobile}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )} */}
                            {/* <div style={{
                                flex: 1,
                                overflowY: 'scroll',
                                padding: '10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#E6E8F4',
                                backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,
                                borderTop: 'none'
                            }}>

                                {messages.map((msg) => {
                                    // Format tdate (assuming it's in a format like "HH:mm:ss")
                                    const formattedDate = new Date(msg.tdate);
                                    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                                    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

                                    const date = formattedDate.toLocaleDateString(undefined, dateOptions);
                                    const time = formattedDate.toLocaleTimeString(undefined, timeOptions);
                                    const selectedMobile = selectedContact?.mobile; // Adjust according to your structure
                                    const isSender = String(msg.srcmobile) === String(selectedMobile);
                                    const senderText = isSender ? selectedMobile : msg.srcmobile;
                                    return (
                                        <div key={msg.tdate} style={{ display: 'flex', justifyContent: isSender ? 'flex-start' : ' flex-end', margin: '5px 0' }}>
                                            <div style={{

                                                display: 'inline-block',
                                                padding: '7px',
                                                borderRadius: '10px',
                                                backgroundColor: isSender ? '#ffffff' : '#dcf8c6', // White for sender, green for others
                                                maxWidth: isMobile ? '70%' : '70%',
                                                fontSize: isMobile ? '15px' : '17px',
                                                boxShadow: '0 1.5px 2px rgba(0, 0, 0, 0.1)',
                                            }}>
                                                {/* <strong >
                                                    {isSender ? selectedMobile : msg.mgrmobile}
                                                </strong>   
                                                <strong className='text-start'>
                                                  {senderText}:
                                                </strong>

                                                <br />
                                                <span>{msg.msg}</span>
                                                <br />
                                                <small style={{ fontSize: isMobile ? '8px' : '13px', marginLeft: '10px', color: 'black' }}>
                                                    {msg.timestamp || `${date} ${time}`}
                                                </small>
                                            </div>
                                        </div>
                                    );
                                })}  
                            </div> */}


                            <div style={{
                                flex: 1,
                                overflowY: 'scroll',
                                padding: '10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#E6E8F4',
                                backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg3})`,
                                // filter: 'blur(5px)',

                                borderTop: 'none',
                                paddingBottom: '50px',
                            }}>
                                {Object.keys(groupedMessages).map((dateLabel) => (
                                    <div key={dateLabel}>
                                        {/* Date Label */}
                                        <div style={{
                                            textAlign: 'center',
                                            margin: '20px 0',
                                            fontSize: '14px',
                                            color: '#888',
                                        }}>
                                            <strong>{dateLabel}</strong>
                                        </div>

                                        {/* Messages for the specific date */}
                                        {groupedMessages[dateLabel].map((msg) => {
                                            const formattedDate = new Date(msg.tdate);
                                            const timeOptions = { hour: '2-digit', minute: '2-digit' };
                                            const time = formattedDate.toLocaleTimeString(undefined, timeOptions);  // Time without seconds

                                            const selectedMobile = selectedContact?.mobile;
                                            const isSender = String(msg.srcmobile) === String(selectedMobile);
                                            const senderText = isSender ? selectedMobile : msg.srcmobile;

                                            return (
                                                <div key={msg.tdate} style={{
                                                    display: 'flex',
                                                    justifyContent: isSender ? 'flex-end' : 'flex-start',
                                                    marginBottom: '10px',
                                                    alignItems: 'flex-end',
                                                }}>

                                                    {/* Avatar for sender */}
                                                    {isSender && (
                                                        <div style={{
                                                            marginRight: '10px',
                                                            alignSelf: 'flex-start',
                                                        }}>
                                                            <img
                                                                src={usermsg} // Dynamic sender avatar
                                                                alt="Sender Avatar"
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    border: '2px solid #ccc',
                                                                }} />
                                                        </div>
                                                    )}

                                                    {/* Message Bubble */}
                                                    <div className={`message-bubble ${isSender ? 'sender' : 'receiver'}`} style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        position: 'relative',
                                                        padding: '10px 15px',
                                                        borderRadius: '20px',
                                                        maxWidth: '70%',
                                                        fontSize: '17px',
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                        backgroundColor: isSender ? '#0084FF' : '#ffffff',
                                                        color: isSender ? '#fff' : '#333',
                                                    }}>

                                                        {/* Sender or receiver message */}
                                                        <strong style={{ fontSize: '16px', marginBottom: '5px' }}>{senderText}</strong>

                                                        <p style={{ margin: '0', lineHeight: '1.5' }}>{msg.msg} </p>

                                                        {/* Date and Time - Displaying inside the message bubble */}
                                                        <small style={{
                                                            fontSize: '12px',
                                                            color: 'black',
                                                            marginTop: '8px', // Spacing between message content and timestamp
                                                            textAlign: 'right', // Align timestamp for sender
                                                        }}>
                                                            {msg.timestamp || ` ${time}`} <FcCheckmark style={{fontSize:'20px'}}/> {/* <RiCheckDoubleLine style={{fontSize:'15px'}} />   Display without seconds */}
                                                        </small>
                                                    </div>

                                                    {/* Avatar for receiver */}
                                                    {!isSender && (
                                                        <div style={{
                                                            marginLeft: '10px',
                                                            alignSelf: 'flex-start',
                                                        }}>
                                                            <img
                                                                src={usermsg1} // Dynamic receiver avatar
                                                                alt="Receiver Avatar"
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    border: '2px solid #ccc',
                                                                }} />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>






                            <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{
                                            padding: '5px',
                                            backgroundColor: '#E6E8F4',
                                            border: '1px solid #ccc',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                    >
                                        <BsEmojiGrin style={{ color: 'black', fontSize: '28px' }} />
                                    </button>
                                    {showEmojiPicker && (
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '40px',
                                            left: '0',
                                            zIndex: '1000',
                                        }}>
                                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                                        </div>
                                    )}
                                </div>
                                <input

                                    type="text"
                                    value={newMessage}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="Type a message...."
                                    style={{
                                        width: isMobile ? '60px' : '40px',
                                        fontSize: isMobile ? '12px' : '20px',
                                        flex: 1,
                                        padding: isMobile ? '5px' : '10px',
                                        paddingLeft: '40px',
                                        paddingRight: '40px',
                                        borderRadius: isMobile ? '15px' : '25px',
                                        border: '1px solid black',
                                    }}
                                    onFocus={(e) => e.target.style.borderColor = 'blue'}
                                    onBlur={(e) => e.target.style.borderColor = 'black'}
                                />
                                <button
                                    onClick={sendSMS}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '10px 15px',
                                        backgroundColor: 'rgb(125, 133, 195)',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '20px'
                                    }}
                                >
                                    Send
                                    {newMessage.trim() && ( // Only show the button if there is text
                                        <IoSend style={{ margin: '5px' }} />
                                    )}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            {!isMobile && !selectedContact && (
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.
                                    <div className='loader'> </div></div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>


    );
};

export default SendMGR;


