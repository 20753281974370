
// import React, { useEffect, useState } from 'react';
// import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
// import { IoIosEye } from 'react-icons/io';
// import { LuClipboardEdit } from 'react-icons/lu';
// import { MdDeleteForever } from 'react-icons/md';
// import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
// import Sidebar from '../Chat/Sidebar';
// import { Pagination } from 'react-bootstrap';
// import { BsJustify } from 'react-icons/bs';
// import './AddAdmin.css';


// const AddAdmin = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [viewModal, setViewModal] = useState(false);
//   const [formData, setFormData] = useState({ username: '', email: '', mobile: '', usertype: '', password: '', repassword: '' });
//   const [editMode, setEditMode] = useState(false);
//   const [viewUser, setViewUser] = useState(null);
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
//     console.log('Loaded users from localStorage:', storedUsers);
//     setUsers(storedUsers);
//   }, []);

//   useEffect(() => {
//     console.log('Saving users to localStorage:', users);
//     localStorage.setItem('users', JSON.stringify(users));
//   }, [users]);



//   const handleShowModal = () => {
//     setEditMode(false); // Reset to add mode
//     setFormData({ username: '', email: '', mobile: '', usertype: '', password: '', repassword: '' }); // Reset form data
//     setShowModal(true);
//   };

//   const handleCloseModal = () => setShowModal(false);
//   const handleCloseViewModal = () => setViewModal(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   if (editMode) {
//   //     // Update existing user
//   //     setUsers(users.map(user => (user.id === formData.id ? { ...formData } : user)));
//   //   } else {
//   //     const newUser = { ...formData, id: users.length ? users[users.length - 1].id + 1 : 1 };
//   //   setUsers([...users, newUser]);
//   //     // setUsers([...users, { ...formData, id: users.length + 1 }]);
//   //   }
//   //   handleCloseModal();
//   // };
//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await fetch('https://demochatapi.emedha.in/api/departuser'); // Replace with your actual API URL
//         const data = await response.json();
//         setUsers(data);
//       } catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };
//     fetchUsers();
//   }, []);

//   console.log(users);


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (editMode) {
//       // Update existing user (this should call the backend API to update)
//       await fetch(`https://demochatapi.emedha.in/api/departuser/${formData.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
//       setUsers(users.map(user => (user.id === formData.id ? { ...formData } : user)));
//     } else {
//       // Create new user (this should call the backend API to create)
//       const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ...formData, id: users.length ? users[users.length - 1].id + 1 : 1 }),
//       });
//       const newUser = await response.json();
//       setUsers([...users, newUser]);
//     }
//     handleCloseModal();
//   };



//   const handleView = (id) => {
//     const user = users.find(user => user.id === id);
//     setViewUser(user);
//     setViewModal(true);
//   };

//   const handleEdit = (id) => {
//     const user = users.find(user => user.id === id);
//     setFormData(user);
//     setEditMode(true);
//     setShowModal(true);
//   };



//   const handleDelete = async (id) => {
//     if (window.confirm("Are you sure you want to delete this user?")) {
//       try {
//         // Call the DELETE API
//         await fetch(`https://demochatapi.emedha.in/api/departuser/${id}`, {
//           method: 'DELETE',
//         });

//         // Update the local state after successful deletion
//         setUsers(users.filter(user => user.id !== id));
//       } catch (error) {
//         console.error('Error deleting user:', error);
//         alert('Failed to delete user. Please try again.');
//       }
//     }
//   };


// const [currentPage, setCurrentPage] = useState(0);
// const usersPerPage = 5; // Show 5 users per page

// const filteredUsers = users.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase()));
// const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

// const currentUsers = filteredUsers.slice(
//   currentPage * usersPerPage,
//   (currentPage + 1) * usersPerPage
// );



//   return (
//     <>
//       <Sidebar />
//       <div className="container" id='main'>
//         <div className="row justify-content-center mb-3">
//           <h3>Add Admins</h3>
//         </div>
//         <div className="row justify-content-center mb-3">
//           <div className="col-lg-12 text-start">
//             <Button variant="primary" onClick={handleShowModal}>
//               + Add Admins
//             </Button>
//           </div>
//         </div>

//         {/* Modal for User Management Form */}
//         <Modal show={showModal} onHide={handleCloseModal}>
//           <Modal.Header closeButton>
//             <Modal.Title>{editMode ? 'Edit User' : 'Add User'}</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleSubmit}>
//               <Form.Group className="mb-3" controlId="username">
//                 <Form.Label>User Name:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="username"
//                   value={formData.username}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </Form.Group>
//               {/* <Form.Group className="mb-3" controlId="email">
//                 <Form.Label>Email:</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </Form.Group> */}
//               <Form.Group className="mb-3" controlId="mobile">
//                 <Form.Label>Mobile:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobile"
//                   value={formData.mobile}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </Form.Group>
//               {/* <Form.Group className="mb-3" controlId="usertype">
//                 <Form.Label>User Type:</Form.Label>
//                 <Form.Control
//                   as="select"
//                   name="usertype"
//                   value={formData.usertype}
//                   onChange={handleInputChange}
//                   required
//                 >
//                   <option value="">Select User Type</option>
//                   <option value="Admin1">Admin1</option>
//                   <option value="Admin2">Admin2</option>
//                   <option value="Admin3">Admin3</option>
//                   <option value="Admin4">Admin4</option>
//                 </Form.Control>
//               </Form.Group> */}

//                 <>
//                   <Form.Group className="mb-3" controlId="password">
//                     <Form.Label>Password:</Form.Label>
//                     <Form.Control
//                       type="password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleInputChange}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group className="mb-3" controlId="repassword">
//                     <Form.Label>Re-enter Password:</Form.Label>
//                     <Form.Control
//                       type="password"
//                       name="repassword"
//                       value={formData.repassword}
//                       onChange={handleInputChange}
//                       required
//                     />
//                   </Form.Group>
//                 </>

//               <div className="text-center">
//                 <Button variant="primary" type="submit">
//                   {editMode ? 'Update User' : 'Add User'}
//                 </Button>
//               </div>
//             </Form>
//           </Modal.Body>
//         </Modal>

//         {/* Users Table */}
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>ID</th>
//               <th>User Name</th>
//               {/* <th>Email</th> */}
//               <th>Mobile</th>
//               {/* <th>User Type</th> */}
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentUsers.map((user, index) => (
//               <tr key={user.id}>
//                <td>{index + 1 + currentPage * usersPerPage}</td>
//                 <td>{user.username}</td>
//                 {/* <td>{user.email}</td> */}
//                 <td>{user.mobile}</td>
//                 {/* <td>{user.usertype}</td> */}
//                 <td>
//                   <Dropdown>
//                     <Dropdown.Toggle variant="success" id="dropdown-basic">
//                       <PiDotsThreeOutlineVerticalBold />
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu>
//                       <Dropdown.Item onClick={() => handleView(user.id)}>
//                         <IoIosEye /> View
//                       </Dropdown.Item>
//                       <Dropdown.Item onClick={() => handleEdit(user.id)}>
//                         <LuClipboardEdit /> Edit
//                       </Dropdown.Item>
//                       <Dropdown.Item onClick={() => handleDelete(user.id)}>
//                         <MdDeleteForever /> Delete
//                       </Dropdown.Item>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         <div className="d-flex justify-content-end mt-3 p-3 m-3">
//         <Pagination className='justify-content-center'>
//           <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
//           <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
//           {[...Array(pageCount)].map((_, i) => (
//             <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
//               {i + 1}
//             </Pagination.Item>
//           ))}
//           <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
//           <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
//         </Pagination>
//       </div>


//         {/* Handle View */}
//         <Modal show={viewModal} onHide={handleCloseViewModal}>
//           <Modal.Header closeButton>
//             <Modal.Title>User Details</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {viewUser && (
//               <div>
//                 <p><strong>User Name:</strong> {viewUser.username}</p>
//                 {/* <p><strong>Email:</strong> {viewUser.email}</p> */}
//                 <p><strong>Mobile:</strong> {viewUser.mobile}</p>
//                 {/* <p><strong>User Type:</strong> {viewUser.usertype}</p> */}
//                 <p><strong>Password:</strong> {viewUser.password}</p>

//               </div>
//             )}
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={handleCloseViewModal}>
//               Close
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default AddAdmin;


import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Table, Dropdown, Row, Col } from 'react-bootstrap';
import { IoIosEye, IoIosNotifications, IoIosSearch } from 'react-icons/io';
import { LuClipboardEdit } from 'react-icons/lu';
import { MdAccountCircle, MdDeleteForever } from 'react-icons/md';
import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
import Sidebar from '../Chat/Sidebar';
import { Pagination } from 'react-bootstrap';
import './AddAdmin.css';
import { MdOutlinePersonSearch } from "react-icons/md";
import { FaLock, FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



const AddAdmin = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [formData, setFormData] = useState({ username: '', email: '', mobile: '', usertype: '', password: '', repassword: '' });
  const [editMode, setEditMode] = useState(false);
  const [viewUser, setViewUser] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://demochatapi.emedha.in/api/departuser');
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleShowModal = () => {
    setEditMode(false);
    setFormData({ username: '', email: '', mobile: '', usertype: '', password: '', repassword: '' });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleCloseViewModal = () => setViewModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (editMode) {
  //     await fetch(`https://demochatapi.emedha.in/api/departuser/${formData.id}`, {
  //       method: 'PUT',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(formData),
  //     });
  //     setUsers(users.map(user => (user.id === formData.id ? { ...formData } : user)));
  //   } else {
  //     const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ ...formData, id: users.length ? users[users.length - 1].id + 1 : 1 }),
  //     });
  //     const newUser = await response.json();
  //     setUsers([...users, newUser]);
  //   }
  //   handleCloseModal();
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation Checks
    if (!formData.username) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please fill in the User Name.',
      });
      return;
    }
  
    if (!formData.mobile) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please fill in the Mobile Number.',
      });
      return;
    }
  
    if (!formData.password) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please fill in the Password.',
      });
      return;
    }
  
    if (!formData.repassword) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please fill in the Confirm Password.',
      });
      return;
    }
  
    if (formData.password !== formData.repassword) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Passwords does not match.',
      });
      return;
    }
  
    try {
      if (editMode) {
        const response = await fetch(`https://demochatapi.emedha.in/api/departuser/${formData.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update user.');
        }
  
        setUsers(users.map(user => (user.id === formData.id ? { ...formData } : user)));
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User updated successfully!',
        }).then(() => {
          handleCloseModal(); // Close the modal after the alert
        });
  
      } else {
        const response = await fetch('https://demochatapi.emedha.in/api/departuser', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...formData, id: users.length ? users[users.length - 1].id + 1 : 1 }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to create user.');
        }
  
        const newUser = await response.json();
        setUsers([...users, newUser]);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User created successfully!',
        }).then(() => {
          handleCloseModal(); // Close the modal after the alert
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong. Please try again.',
      });
    }
  };
  


  const handleView = (id) => {
    const user = users.find(user => user.id === id);
    setViewUser(user);
    setViewModal(true);
  };

  const handleEdit = (id) => {
    const user = users.find(user => user.id === id);
    setFormData(user);
    setEditMode(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      await fetch(`https://demochatapi.emedha.in/api/departuser/${id}`, {
        method: 'DELETE',
      });
      setUsers(users.filter(user => user.id !== id));
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;

  // const filteredUsers = users.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase()));


  const filteredUsers = users.filter(user => {
    const isActive = user.active === 1 ? 'Active' : 'Inactive';
    return (
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobile.toLowerCase().includes(searchTerm.toLowerCase()) ||
      isActive.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.credits.toString().includes(searchTerm)
    );
  });

  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
  const currentUsers = filteredUsers.slice(currentPage * usersPerPage, (currentPage + 1) * usersPerPage);

  // const [currentUsers, setCurrentUsers] = useState([]);


  const handleToggleActive = (userId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0

    fetch(`https://demochatapi.emedha.in/api/active/${userId}/status`, { // Updated endpoint
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ active: newStatus }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update status');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        // Update local state if needed
        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.id === userId ? { ...user, active: newStatus } : user
          )
        );
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };




  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleItemClick = (action) => {
    console.log(action);
    setDropdownVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('username');
    localStorage.removeItem('userId');
    navigate('/');
  };







  return (
    <>
      <Sidebar />
      <div id='main'>
              
      <div className="container-fluid bg-light p-3 shadow">
  <div className="row justify-content-between align-items-center">
    <div className="col-auto">
      <h3 className="mt-2"><strong>  Admin </strong></h3>
    </div>
    <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
      <IoIosNotifications size={30} style={{color:'rgb(125, 133, 195)'}}  />
      <div style={{ position: 'relative' }}>
        <MdAccountCircle
          size={30}
          onClick={toggleDropdown}
          style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
        />
        {dropdownVisible && (
          <div className="dropdown-menu show" style={{
            position: 'absolute',
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}>
            <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
              <FaUserEdit style={{ marginRight: '8px' }} />
              Edit Profile
            </div>
            <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
              <FaLock style={{ marginRight: '8px' }} />
              Change Password
            </div>
            <div onClick={handleLogout} className="dropdown-item">
              <FaSignOutAlt style={{ marginRight: '8px' }} />
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>


        <hr />
        <Row className="row justify-content-center mb-3">

        <Col style={{ display: 'flex', alignItems: 'center' }}>

            {/* <div style={{ position: 'relative', width: '300px' }}>
    <MdOutlinePersonSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }} />
    <input
        type="text"
        placeholder="Search by User Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control"
        style={{ paddingLeft: '30px' }} // Add padding to prevent text from overlapping the icon
    />
</div> */}
            <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
              <MdOutlinePersonSearch style={{ position: 'absolute', left: '10px', color: '#6c757d' }} />
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
                style={{
                  paddingLeft: '40px', // Adjusted for icon size
                  paddingRight: '30px', // Space for the right icon
                  borderRadius: '25px', // Rounded corners
                  border: '1px solid #ced4da', // Border color
                  transition: 'border-color 0.3s',
                }}
                onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
                onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
              />
              <IoIosSearch
                style={{ position: 'absolute', right: '10px', color: '#6c757d' }}
                size={20} // Adjust size as needed
              />
            </div>
          

          </Col>
          <Col className=" text-end ">
              <Button style={{ backgroundColor: 'rgb(125, 133, 195)' }} variant="primary" onClick={handleShowModal}>
                + Add User
              </Button>
            </Col>
        </Row>

        {/* Modal for User Management Form */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? 'Edit User' : 'Add User'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>User Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="mobile">
                <Form.Label>Mobile:</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="repassword">
                <Form.Label>Re-enter Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="repassword"
                  value={formData.repassword}
                  onChange={handleInputChange}
                  
                />
              </Form.Group>
              <div className="text-center">
                <Button variant="primary" type="submit">
                  {editMode ? 'Update User' : 'Add User'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Users Table */}
        <Table  striped bordered hover>
          <thead>
            <tr>
              <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>ID</th>
              <th  style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>User Name</th>
              <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Mobile</th>
              <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Status</th>
              <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Credits</th>
              <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1 + currentPage * usersPerPage}</td>
                <td>{user.username}</td>
                <td>{user.mobile}</td>

                <td>{user.active === 1 ? 'Active' : 'Inactive'}</td>
                <td>{user.credits}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle style={{ backgroundColor: 'rgb(125, 133, 195)' }} id="dropdown-basic">
                      <PiDotsThreeOutlineVerticalBold />
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <Dropdown.Item onClick={() => handleView(user.id)}>
                        <IoIosEye /> View
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleEdit(user.id)}>
                        <LuClipboardEdit /> Edit
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => handleDelete(user.id)}>
                        <MdDeleteForever /> Delete
                      </Dropdown.Item> */}
                      {/* {user.active === 1 ?( <Dropdown.Item > <MdDeleteForever /> active  </Dropdown.Item>)
                                            :(<Dropdown.Item > <MdDeleteForever /> Inactive </Dropdown.Item>)} */}
                      <Dropdown.Item onClick={() => handleToggleActive(user.id, user.active)}>
                        {user.active === 1 ? (
                          <><MdDeleteForever /> Inactive</>
                        ) : (
                          <><MdDeleteForever /> Active</>
                        )}
                      </Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination */}
        <div className='d-flex flex-row justify-content-end '>
          <Pagination >
            <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
            {[...Array(pageCount)].map((_, i) => (
              <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)} style={{ backgroundColor: 'rgb(125, 133, 195)' }}>
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
            <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
          </Pagination>
          </div>


        {/* Handle View */}
        <Modal show={viewModal} onHide={handleCloseViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {viewUser && (
              <div>
                <p><strong>User Name:</strong> {viewUser.username}</p>
                <p><strong>Mobile:</strong> {viewUser.mobile}</p>
                <p><strong>Password:</strong> {viewUser.password}</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddAdmin;
