// import React, { useState, useEffect } from "react";
// import Sidebar from "../Chat/Sidebar";
// import { useNavigate } from "react-router-dom";
// import { IoIosNotifications, IoIosSearch } from "react-icons/io";
// import { MdAccountCircle } from "react-icons/md";
// import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
// import './Message.css';
// import { Col, Row, Table, Button } from "react-bootstrap";

// const Message = () => {
//     const navigate = useNavigate();

//     const [userDropdownVisible, setUserDropdownVisible] = useState(false);
//     const [templateDropdownVisible, setTemplateDropdownVisible] = useState(false);
//     const [groupsDropdownVisible, setGroupsDropdownVisible] = useState(false);
//     const [templates, setTemplates] = useState([]);
//     const [groups, setGroups] = useState([]);
//     const [templateDetails, setTemplateDetails] = useState(null);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedGroup, setSelectedGroup] = useState(null);
//     const [groupSearchTerm, setGroupSearchTerm] = useState('');
//     const [messageText, setMessageText] = useState(''); // State for message text

//     // Fetch templates on component mount
//     useEffect(() => {
//         const fetchTemplates = async () => {
//             try {
//                 const response = await fetch("https://emedha.com/echatbot/get-template-status.php?limit=10");
//                 const data = await response.json();
//                 setTemplates(data.data);
//             } catch (error) {
//                 console.error("Error fetching templates:", error);
//             }
//         };
//         fetchTemplates();
//     }, []);



//     //this useEffect is for all data of admins shows in superadmin


// useEffect(() => {
//     const fetchGroups = async () => {
//         try {
//             const token = localStorage.getItem('token'); // Assuming the token is stored under this key
//             if (token) {
//                 const response = await fetch('https://demochatapi.emedha.in/api/userGroups', {
//                     headers: {
//                         'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
//                     },
//                 });
//                 const data = await response.json();
//                 setGroups(data.results || []);
//                 setSelectedGroup();
//             }
//         } catch (error) {
//             console.error('Error fetching groups:', error);
//         }
//     };

//     fetchGroups();
// }, []); // Runs once on component mount


// useEffect(() => {
//     const fetchGroups = async () => {
//         const userIdss = localStorage.getItem('userId');
//         const userIdInt = parseInt(userIdss, 10);

//         if (userIdInt) {
//             try {
//                 const response = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`);
//                 const data = await response.json();
//                 setGroups(data.groups || []);
//                 setSelectedGroup();
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         }
//     };

//     fetchGroups();
// }, []);

//     const toggleUserDropdown = () => {
//         setUserDropdownVisible(!userDropdownVisible);
//     };

// const toggleTemplateDropdown = () => {
//     setTemplateDropdownVisible(!templateDropdownVisible);
// };

// const toggleGroupsDropdown = () => {
//     setGroupsDropdownVisible(!groupsDropdownVisible);
// };

// const handleTemplateClick = async (name) => {
//     setTemplateDropdownVisible(false);
//     await fetchTemplateDetails(name);
// };

//     const fetchTemplateDetails = async (name) => {
//         try {
//             const response = await fetch(`https://emedha.com/echatbot/get-template.php?name=${name}`);
//             const data = await response.json();
//             setTemplateDetails(data.data[0]); // Assuming the API returns an array
//         } catch (error) {
//             console.error("Error fetching template details:", error);
//         }
//     };

// const handleGroupSelect = (group) => {
//     setSelectedGroup(group);
//     setGroupsDropdownVisible(false);
// };

//     const handleLogout = () => {
//         localStorage.removeItem('userData');
//         localStorage.removeItem('token');
//         localStorage.removeItem('loginEmail');
//         localStorage.removeItem('rememberMe');
//         sessionStorage.removeItem('username');
//         localStorage.removeItem('userId');
//         navigate('/');
//     };



//     const [messages, setMessages] = useState([]);
//     const [lastSentMessage, setLastSentMessage] = useState(null); // To store the last sent message

//     // Load messages from local storage on component mount


//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const handleItemClick = (action) => {
//         console.log(action);
//         setDropdownVisible(false);
//     };

//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };


//     const [messageBody, setMessageBody] = useState('');


//     // Load messages from localStorage when the component mounts
// useEffect(() => {
//     const storedMessages = JSON.parse(localStorage.getItem('messages')) || [];
//     setMessages(storedMessages);
// }, []);

//     const sendMessage = () => {
//         if (!selectedGroup || !templateDetails) return;

//         // Safely access components and ensure they are an array
//         const components = templateDetails.components || [];

//         // Collecting the message body
//         const messageBody = components
//             .filter(component => component.type === "BODY")
//             .map(component => component.text)
//             .join(' ');

//         // Collecting button details
//         const buttons = components
//             .filter(component => component.type === "BUTTONS")
//             .flatMap(component => component.buttons ? component.buttons.map(button => {
//                 return {
//                     text: button.text,
//                     type: button.type,
//                     url: button.type === "URL" ? button.url : `tel:${button.phone_number}`
//                 };
//             }) : []); // Ensure to return an empty array if buttons is undefined

//         const message = {
//             groupName: selectedGroup.groupName,
//             templateName: templateDetails.name,
//             message: messageBody || 'No body available', // Fallback message
//             buttons: buttons,
//             timestamp: new Date().toLocaleString(),
//         };

//         // Update messages state
//         setMessages((prevMessages) => {
//             const newMessages = [...prevMessages, message];
//             // Save messages to localStorage
//             localStorage.setItem('messages', JSON.stringify(newMessages));
//             return newMessages;
//         });

//         // Store the last sent message for display
//         setLastSentMessage(message);
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id='main'>
//                 <div className="container-fluid bg-light p-3 shadow">
//                     <div className="row justify-content-between align-items-center">
//                         <div className="col-auto">
//                             <h3 className="mt-2"><strong>Message</strong></h3>
//                         </div>
//                         <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//                             <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
//                             <div style={{ position: 'relative' }}>
//                                 <MdAccountCircle
//                                     size={30}
//                                     onClick={toggleDropdown}
//                                     style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
//                                 />
//                                 {dropdownVisible && (
//                                     <div className="dropdown-menu show" style={{
//                                         position: 'absolute',
//                                         right: 0,
//                                         backgroundColor: 'white',
//                                         border: '1px solid #ccc',
//                                         boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                                         zIndex: 1,
//                                     }}>
//                                         <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
//                                             <FaUserEdit style={{ marginRight: '8px' }} />
//                                             Edit Profile
//                                         </div>
//                                         <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
//                                             <FaLock style={{ marginRight: '8px' }} />
//                                             Change Password
//                                         </div>
//                                         <div onClick={handleLogout} className="dropdown-item">
//                                             <FaSignOutAlt style={{ marginRight: '8px' }} />
//                                             Logout
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <hr />
//                 <Row>
//                     <Col>
// <div>
//     <button style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} onClick={toggleTemplateDropdown}>Show Templates</button>
//     {templateDropdownVisible && (
//         <div style={{
//             position: 'absolute',
//             zIndex: 1,
//             backgroundColor: 'white',
//             border: '1px solid #ccc',
//             boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//             borderRadius: '5px',
//             padding: '10px',
//             maxHeight: '300px',
//             overflowY: 'auto',
//             transition: 'all 0.3s ease',
//         }}>
//             <div style={{ position: 'relative', marginBottom: '10px' }}>
//                 <IoIosSearch
//                     style={{
//                         position: 'absolute',
//                         left: '10px',
//                         top: '50%',
//                         transform: 'translateY(-50%)',
//                         color: '#6c757d'
//                     }}
//                     size={20}
//                 />
//                 <input
//                     type="text"
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     placeholder="Search"
//                     className="form-control"
//                     style={{
//                         paddingLeft: '40px',
//                         paddingRight: '40px',
//                         borderRadius: '25px',
//                         border: '1px solid black',
//                         transition: 'border-color 0.3s',
//                         height: '40px',
//                     }}
//                 />
//             </div>
//             <div className="dropdown-menu-scrollable">
//                 {templates
//                     .filter(template => template.name.toLowerCase().includes(searchTerm.toLowerCase()))
//                     .map((template) => (
//                         <div
//                             key={template.id}
//                             onClick={() => {
//                                 handleTemplateClick(template.name);
//                                 setSearchTerm(''); // Clear search term on click
//                             }}
//                             className="dropdown-item"
//                             style={{
//                                 padding: '8px 12px',
//                                 cursor: 'pointer',
//                             }}
//                         >
//                             {template.name}
//                         </div>
//                     ))}
//                 {templates.filter(template => template.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 && (
//                     <div className="dropdown-item" style={{ padding: '8px 12px' }}>
//                         No templates found
//                     </div>
//                 )}
//             </div>
//         </div>
//     )}
// </div>
//                     </Col>
//                     <Col>
//                         {/* Button to show groups */}
// <div>
//     <button style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} onClick={toggleGroupsDropdown}>Show Groups</button>
//     {groupsDropdownVisible && (
//         <div style={{
//             position: 'absolute',
//             zIndex: 1,
//             backgroundColor: 'white',
//             border: '1px solid #ccc',
//             boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//             borderRadius: '5px',
//             padding: '10px',
//             maxHeight: '280px',
//             overflowY: 'auto',
//             transition: 'all 0.3s ease',
//         }}>
//             <div style={{ position: 'relative', marginBottom: '10px' }}>
//                 <IoIosSearch
//                     style={{
//                         position: 'absolute',
//                         left: '10px',
//                         top: '50%',
//                         transform: 'translateY(-50%)',
//                         color: '#6c757d'
//                     }}
//                     size={20}
//                 />
//                 <input
//                     type="text"
//                     value={groupSearchTerm}
//                     onChange={(e) => setGroupSearchTerm(e.target.value)}
//                     placeholder="Search Groups"
//                     className="form-control"
//                     style={{
//                         paddingLeft: '40px',
//                         paddingRight: '40px',
//                         borderRadius: '25px',
//                         border: '1px solid black',
//                         transition: 'border-color 0.3s',
//                         height: '40px',
//                     }}
//                 />
//             </div>
//             <div className="dropdown-menu-scrollable" style={{
//                 overflowY: 'auto',
//             }}>
//                 {groups
//                     .filter(group => group.groupName.toLowerCase().includes(groupSearchTerm.toLowerCase()))
//                     .map((group) => (
//                         <div
//                             key={group.id}
//                             onClick={() => handleGroupSelect(group)}
//                             className="dropdown-item"
//                             style={{
//                                 cursor: 'pointer',
//                                 padding: '8px 12px',
//                             }}
//                         >
//                             {group.groupName}
//                         </div>
//                     ))}
//                 {groups.filter(group => group.groupName.toLowerCase().includes(groupSearchTerm.toLowerCase())).length === 0 && (
//                     <div className="dropdown-item" style={{ padding: '8px 12px' }}>
//                         No groups found
//                     </div>
//                 )}
//             </div>
//         </div>
//     )}
// </div>
//                     </Col>
//                 </Row>

//                 <div>
//                     {selectedGroup && templateDetails ? (
//                         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
//                             <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
//                                 <thead>
//                                     <tr>
//                                         <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Group Name</th>
//                                         <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Template Name</th>
//                                         <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Template Body</th>
//                                         <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Template State</th>

//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <td style={{ border: '1px solid #ddd', padding: '8px' }}>{selectedGroup.groupName}</td>
//                                         <td style={{ border: '1px solid #ddd', padding: '8px' }}>{templateDetails.name}</td>
//                                         <td style={{ border: '1px solid #ddd', padding: '8px' }}>
//                                             {templateDetails.components && Array.isArray(templateDetails.components) ? (
//                                                 templateDetails.components.map((component, index) => (
//                                                     <div key={index}>
//                                                         {component.type === "BODY" && (
//                                                             <span>
//                                                                 {/* {replacePlaceholders(component.text, component.example.body_text[0])} */}
//                                                                 {component.text}
//                                                             </span>
//                                                         )}
// {component.type === "BUTTONS" && (
//     <span>
//         {component.buttons && Array.isArray(component.buttons) ? (
//             component.buttons.map((button, btnIndex) => (
//                 <span key={btnIndex} style={{ marginRight: '10px' }}>
//                     {button.type === "URL" ? (
//                         <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
//                             {button.text}
//                         </a>
//                     ) : (
//                         <a href={`tel:${button.phone_number}`} style={{ textDecoration: 'none', color: 'blue' }}>
//                             {button.text}
//                         </a>
//                     )}
//                 </span>
//             ))
//         ) : null}
//     </span>
// )}
//                                                     </div>
//                                                 ))
//                                             ) : (
//                                                 <span>No body available</span>
//                                             )}
//                                         </td>
//                                         <td style={{ border: '1px solid #ddd', padding: '8px' }}>{templateDetails.status}</td>

//                                     </tr>
//                                 </tbody>
//                             </Table>

//                             <Button variant="primary" onClick={sendMessage} style={{ marginTop: '10px' }}>
//                     Send Message
//                 </Button>
//                         </div>


//                     ) : (
//                         <p></p>
//                     )}
//                 </div>


//                 <div>

//                 <h3>Sent Messages</h3>
//                 {/* <ul style={{ listStyleType: 'none', padding: 0 }}>
//                     {messages.map((msg, index) => (
//                         <li key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
//                             <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
//                                 Template Name: <strong>{msg.templateName}</strong>
//                             </div>
//                             <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
//                                 Group Name: <strong>{msg.groupName}</strong>
//                             </div>
//                             <div style={{ marginTop: '5px', fontSize: '15px', color: '#555' }}>
//                                 {msg.message}
//                             </div>
//                             {msg.buttons.length > 0 && (
//                                 <div>
//                                     <strong>Buttons:</strong>
//                                     <div style={{ marginTop: '10px' }}>
//                                         {msg.buttons.map((button, index) => (
//                                             <span key={index} style={{ marginRight: '10px' }}>
//                                                 {button.type === "URL" ? (
//                                                     <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
//                                                         {button.text}
//                                                     </a>
//                                                 ) : (
//                                                     <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
//                                                         {button.text}
//                                                     </a>
//                                                 )}
//                                             </span>
//                                         ))}
//                                     </div>
//                                 </div>
//                             )}
//                             <div className="text-end" style={{ fontSize: '15px', color: 'black', marginTop: '5px' }}>
//                                 {msg.timestamp}
//                             </div>
//                         </li>
//                     ))}
//                 </ul> */}

//                    {/* Display all sent messages */}

// <ul style={{ listStyleType: 'none', padding: 0 }}>
//     {messages.map((msg, index) => (
//         <li key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
//             <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
//                 Template Name: <strong>{msg.templateName}</strong>
//             </div>
//             <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
//                 Group Name: <strong>{msg.groupName}</strong>
//             </div>
//             <div style={{ marginTop: '5px', fontSize: '15px', color: '#555' }}>
//                 {msg.message}
//             </div>
//             {msg.buttons && msg.buttons.length > 0 && (
//                 <div style={{ marginTop: '5px' }}>
//                     <strong>Buttons:</strong>
//                     {msg.buttons.map((button, index) => (
//                         <span key={index} style={{ marginRight: '10px' }}>
//                             <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
//                                 {button.text}
//                             </a>
//                         </span>
//                     ))}
//                 </div>
//             )}
//             <div style={{ fontSize: '12px', color: '#555' }}>
//                 <strong>Timestamp:</strong> {msg.timestamp}
//             </div>
//         </li>
//     ))}
// </ul>
//                 </div>


//             </div>

//         </>
//     );
// };

// export default Message;

import React, { useState, useEffect } from "react";
import Sidebar from "../Chat/Sidebar";
import { useNavigate } from "react-router-dom";
import { IoIosNotifications, IoIosSearch } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";

const Message = () => {
    const navigate = useNavigate();

    const [userDropdownVisible, setUserDropdownVisible] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [groups, setGroups] = useState([]);
    const [templateDetails, setTemplateDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [messages, setMessages] = useState([]);
    const [groupSearchTerm, setGroupSearchTerm] = useState('');
    const [groupsDropdownVisible, setGroupsDropdownVisible] = useState(false);
    const [templateDropdownVisible, setTemplateDropdownVisible] = useState(false);
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [selectedGroupName, setSelectedGroupName] = useState('');


    useEffect(() => {
        const storedMessages = JSON.parse(localStorage.getItem('messages')) || [];
        setMessages(storedMessages);
    }, []);


    // Fetch templates from API
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch("https://emedha.com/echatbot/get-template-status.php?limit=10");
                const data = await response.json();
                setTemplates(data.data);
            } catch (error) {
                console.error("Error fetching templates:", error);
            }
        };
        fetchTemplates();
    }, []);

    // Fetch groups using userId from localStorage
    useEffect(() => {
        const fetchGroups = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`);
                    const data = await response.json();
                    setGroups(data.groups || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        fetchGroups();
    }, []);

    // Fetch groups using token
    // useEffect(() => {
    //     const fetchGroupsWithToken = async () => {
    //         try {
    //             const token = localStorage.getItem('token');
    //             if (token) {
    //                 const response = await fetch('https://demochatapi.emedha.in/api/userGroups', {
    //                     headers: {
    //                         'Authorization': `Bearer ${token}`,
    //                     },
    //                 });
    //                 const data = await response.json();
    //                 setGroups(prevGroups => [...prevGroups, ...(data.results || [])]); // Combine results
    //                 setSelectedGroup();
    //             }
    //         } catch (error) {
    //             console.error('Error fetching groups:', error);
    //         }
    //     };

    //     fetchGroupsWithToken();
    // }, []); // Runs once on component mount

    useEffect(() => {
        const fetchGroupsWithToken = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/userGroups', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const data = await response.json();
                    setGroups(data.results || []);
                    setSelectedGroup();
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroupsWithToken();
    }, []); // Runs once on component mount

    // Fetch template details based on the selected template
    const fetchTemplateDetails = async (name) => {
        try {
            const response = await fetch(`https://emedha.com/echatbot/get-template.php?name=${name}`);
            const data = await response.json();
            setTemplateDetails(data.data[0]);
        } catch (error) {
            console.error("Error fetching template details:", error);
        }
    };


    // Send message with selected group and template
    // const sendMessage = () => {
    //     if (!selectedGroup || !templateDetails) return;

    //     const message = {
    //         groupName: selectedGroup.groupName,
    //         templateName: templateDetails.name,
    //         message: templateDetails.components.map(comp => comp.text).join(' '),
    //         timestamp: new Date().toLocaleString(),
    //     };

    //     setMessages(prevMessages => {
    //         const newMessages = [...prevMessages, message];
    //         localStorage.setItem('messages', JSON.stringify(newMessages));
    //         return newMessages;
    //     });
    //     setSelectedGroup(null);
    //     setTemplateDetails(null);
    //     setTemplateDropdownVisible(null);
    //     setGroupsDropdownVisible('');
    //     setSelectedTemplateName('');
    //     setSelectedGroupName('');
    //     // Close the modal
    //     setShowModal(false);

    // };

    const sendMessage = () => {
        if (!selectedGroup || !templateDetails) return;

        const messageComponents = templateDetails.components.map(comp => {
            if (comp.type === "BODY") {
                return comp.text;
            } else if (comp.type === "BUTTONS") {
                return comp.buttons.map(button => ({
                    text: button.text,
                    url: button.type === "URL" ? button.url : undefined,
                    phone_number: button.type === "CALL" ? button.phone_number : undefined
                }));
            }
            return null;
        }).filter(Boolean);

        const message = {
            groupName: selectedGroup.groupName,
            templateName: templateDetails.name,
            message: messageComponents.filter(comp => typeof comp === 'string').join('\n'),
            buttons: messageComponents.find(comp => Array.isArray(comp)) || [],
            timestamp: new Date().toLocaleString(),
        };

        setMessages(prevMessages => {
            const newMessages = [...prevMessages, message];
            localStorage.setItem('messages', JSON.stringify(newMessages));
            return newMessages;
        });

        // Reset states
        setSelectedGroup(null);
        setTemplateDetails(null);
        setTemplateDropdownVisible(false);
        setGroupsDropdownVisible(false);
        setSelectedTemplateName('');
        setSelectedGroupName('');

        // Close the modal
        setShowModal(false);
    };






    const handleTemplateClick = async (name) => {
        setTemplateDropdownVisible(false);
        await fetchTemplateDetails(name);
        setSelectedTemplateName(name); // Set the selected template name
    };


    const handleGroupSelect = (group) => {
        setSelectedGroup(group);
        setGroupsDropdownVisible(false);
        setSelectedGroupName(group.groupName); // Set the selected group name
    };

    const toggleGroupsDropdown = () => {
        setGroupsDropdownVisible(!groupsDropdownVisible);
    };

    const toggleTemplateDropdown = () => {
        setTemplateDropdownVisible(!templateDropdownVisible);
    };

    return (
        <>
            <Sidebar />
            <div id='main'>
                <div className="container-fluid bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong>Message</strong></h3>
                        </div>
                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <MdAccountCircle
                                size={30}
                                onClick={() => setUserDropdownVisible(!userDropdownVisible)}
                                style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                            />
                            {userDropdownVisible && (
                                <div className="dropdown-menu show">
                                    <div className="dropdown-item" onClick={() => alert("Edit Profile")}>
                                        <FaUserEdit style={{ marginRight: '8px' }} />
                                        Edit Profile
                                    </div>
                                    <div className="dropdown-item" onClick={() => alert("Change Password")}>
                                        <FaLock style={{ marginRight: '8px' }} />
                                        Change Password
                                    </div>
                                    <div className="dropdown-item" onClick={() => {
                                        localStorage.clear();
                                        navigate('/');
                                    }}>
                                        <FaSignOutAlt style={{ marginRight: '8px' }} />
                                        Logout
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <hr />
                <Row>
                    <Col>
                        <Button
                            style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}
                            onClick={() => setShowModal(true)}
                        >
                            Send Message
                        </Button>
                    </Col>
                </Row>

                {/* Modal for Template Selection */}
                <Modal  className='ModelShoww' show={showModal} onHide={() => setShowModal(false)} >
                    <Modal.Dialog style={{ maxWidth: '800px', width: '100%' }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Select Template and Groups to Send Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="templateSelect">
                                    <Form.Label>Select Template</Form.Label>
                                    <div>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="button"
                                                    style={{ marginBottom: '15px', backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}
                                                    onClick={toggleTemplateDropdown}
                                                >
                                                    Show Templates
                                                </button>
                                            </Col>
                                            <Col>
                                                {selectedTemplateName && (
                                                    <div className="text-end" style={{ marginBottom: '10px', padding: '10px' }}>
                                                        Template Name: <strong>{selectedTemplateName}</strong>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                        {templateDropdownVisible && (
                                            <div style={{
                                                position: 'relative',
                                                zIndex: 1,
                                                backgroundColor: 'white',
                                                border: '1px solid #ccc',
                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                maxHeight: '300px',
                                                overflowY: 'auto',
                                                transition: 'all 0.3s ease',
                                                marginTop: '-5px', // Adjust as needed to fit into the form
                                            }}>
                                                <div style={{ position: 'relative', marginBottom: '10px' }}>
                                                    <IoIosSearch
                                                        style={{
                                                            position: 'absolute',
                                                            left: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            color: '#6c757d'
                                                        }}
                                                        size={20}
                                                    />
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        placeholder="Search"
                                                        className="form-control"
                                                        style={{
                                                            paddingLeft: '40px',
                                                            paddingRight: '40px',
                                                            borderRadius: '25px',
                                                            border: '1px solid black',
                                                            transition: 'border-color 0.3s',
                                                            height: '40px',
                                                        }}
                                                    />
                                                </div>

                                                <div className="dropdown-menu-scrollable">
                                                    {templates
                                                        .filter(template => template.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                        .map((template) => (
                                                            <div
                                                                key={template.id}
                                                                onClick={() => {
                                                                    handleTemplateClick(template.name);
                                                                    setSearchTerm(''); // Clear search term on click
                                                                }}
                                                                className="dropdown-item"
                                                                style={{
                                                                    padding: '8px 12px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {template.name}
                                                            </div>
                                                        ))}

                                                    {templates.filter(template => template.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 && (
                                                        <div className="dropdown-item" style={{ padding: '8px 12px' }}>
                                                            No templates found
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>


                                {/* Editable Message Body */}
                                {templateDetails && templateDetails.components && (
                                    <Form.Group controlId="messageBody">
                                        <Form.Label>Message Body</Form.Label>
                                        {templateDetails.components.map((component, index) => (
                                            <div key={index}>
                                                {component.type === "BODY" && (
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ height: '100px' }}
                                                        value={component.text}
                                                        onChange={(e) => {
                                                            const updatedComponents = [...templateDetails.components];
                                                            updatedComponents[index].text = e.target.value; // Update text in component
                                                            setTemplateDetails({ ...templateDetails, components: updatedComponents });
                                                        }}
                                                    />
                                                )}
                                                {component.type === "BUTTONS" && (
                                                    <span>
                                                        {component.buttons && Array.isArray(component.buttons) ? (
                                                            component.buttons.map((button, btnIndex) => (
                                                                <span key={btnIndex} style={{ marginRight: '10px' }}>
                                                                    {button.type === "URL" ? (
                                                                        <a href={button.url} style={{ textDecoration: 'none', color: 'blue' }}>
                                                                            {button.text}
                                                                        </a>
                                                                    ) : (
                                                                        <a href={`tel:${button.phone_number}`} style={{ textDecoration: 'none', color: 'blue' }}>
                                                                            {button.text}
                                                                        </a>
                                                                    )}
                                                                </span>
                                                            ))
                                                        ) : null}
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </Form.Group>
                                )}


                                {/* Group Selection */}
                                <Form.Group controlId="groupSelect">
    <Form.Label>Select Group</Form.Label>
    <div>
        <Row>
            <Col>
                <button
                    type="button"
                    style={{ marginBottom: '15px', backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}
                    onClick={toggleGroupsDropdown}
                >
                    Show Groups
                </button>
            </Col>
            <Col>
                {selectedGroupName && (
                    <div className="text-end" style={{ marginBottom: '10px', padding: '10px' }}>
                        Selected Group: <strong>{selectedGroupName}</strong>
                    </div>
                )}
            </Col>
        </Row>

        {groupsDropdownVisible && (
            <div style={{
                backgroundColor: 'white',
                border: '1px solid #ccc',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
                padding: '10px',
                maxHeight: '280px',
                overflowY: 'auto',
                transition: 'all 0.3s ease',
                marginTop: '0px', // Aligns it directly under the button
            }}>
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                    <IoIosSearch
                        style={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#6c757d'
                        }}
                        size={20}
                    />
                    <input
                        type="text"
                        value={groupSearchTerm}
                        onChange={(e) => setGroupSearchTerm(e.target.value)}
                        placeholder="Search Groups"
                        className="form-control"
                        style={{
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            borderRadius: '25px',
                            border: '1px solid black',
                            transition: 'border-color 0.3s',
                            height: '40px',
                        }}
                    />
                </div>
                <div className="dropdown-menu-scrollable" style={{ overflowY: 'auto' }}>
                    {groups
                        .filter(group => group.groupName.toLowerCase().includes(groupSearchTerm.toLowerCase()))
                        .map((group) => (
                            <div
                                key={group.id}
                                onClick={() => handleGroupSelect(group)}
                                className="dropdown-item"
                                style={{
                                    cursor: 'pointer',
                                    padding: '8px 12px',
                                }}
                            >
                                {group.groupName}
                            </div>
                        ))}
                    {groups.filter(group => group.groupName.toLowerCase().includes(groupSearchTerm.toLowerCase())).length === 0 && (
                        <div className="dropdown-item" style={{ padding: '8px 12px' }}>
                            No groups found
                        </div>
                    )}
                </div>
            </div>
        )}
    </div>
</Form.Group>



                                <div className="text-end">
                                    <Button style={{ backgroundColor: 'rgb(125, 133, 195)' }} variant="primary" onClick={sendMessage}>
                                        Send Message
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal.Dialog>
                </Modal>

                <div>
                    <h3>Sent Messages</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {messages.map((msg, index) => (
                            <li key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Template Name: <strong>{msg.templateName}</strong>
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Group Name: <strong>{msg.groupName}</strong>
                                </div>
                                <div style={{ marginTop: '5px', fontSize: '15px', color: '#555' }}>
                                    {msg.message}
                                </div>
                                {/* Display buttons if they exist */}
                                {msg.buttons && msg.buttons.length > 0 && (
                                    <div style={{ marginTop: '5px' }}>
                                        <strong>Buttons:</strong>
                                        {msg.buttons.map((button, btnIndex) => (
                                            <span key={btnIndex} style={{ marginRight: '10px' }}>
                                                <a
                                                    href={button.type === "URL" ? button.url : `tel:${button.phone_number}`}
                                                    style={{ textDecoration: 'none', color: 'blue' }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {button.text}
                                                </a>
                                            </span>
                                        ))}
                                    </div>
                                )}
                                <div style={{ fontSize: '12px', color: '#555' }}>
                                    <strong>Timestamp:</strong> {msg.timestamp}
                                </div>
                            </li>
                        ))}
                    </ul>

                </div>


            </div>
        </>
    );
};

export default Message;

