

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './Login.css'; 
// import { toast } from 'react-toastify';
// import { Alert } from 'react-bootstrap';



// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [rememberMe, setRememberMe] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const [error, setError] = useState("");
//   const handlePasswordToggle = () => setShowPassword(!showPassword);

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     const storedUserData = JSON.parse(localStorage.getItem('userData'));

//     if (storedUserData) {
//       const { username: storedEmail, password: storedPassword } = storedUserData;

//       if (username === storedEmail && password === storedPassword) {
//         if (rememberMe) {
//           localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
//         }
//        toast.success("Login successful!");
//         navigate('/chatpanel'); // Navigate to home page after successful login
//       } else {
//         toast.error("Invalid credentials. Please try again.");
//       }
//     } else {
//       toast.error("User not found. Please sign up first.");
//     }
//   };

// //   const handleLogin = async (e) => {
// //     e.preventDefault();

// //     // const { email, password } = formData; // Get the email and password from the form data

// //     if (!email || !password) {
// //         toast.error("Please enter email and password.");
// //         return;
// //     }

// //     try {
// //         const response = await fetch('https://demochatapi.emedha.in/api/login', {
// //             method: 'POST',
// //             headers: {
// //                 'Content-Type': 'application/json',
// //             },
// //             body: JSON.stringify({ email, password }),
// //         });
// //         localStorage.setItem('loginEmail',email)
// //         const data = await response.json();

// //         if (response.status === 200) {
// //             localStorage.setItem('token', data.token);
// //             localStorage.setItem('userData', JSON.stringify({ email, password }));  
// //             if (rememberMe) {
// //                 localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
// //             }

// //             toast.success("Login successful!");
// //             navigate('/dashbord'); // Navigate to home page after successful login
// //           } else if (response.status === 500) {
// //             // Internal Server Error
// //             setError("Server error occurred. Please try again later.");
// //            toast.error("Internal Server Error");
            
// //           } else if (response.status === 503) {
// //             // Service Unavailable
// //             setError("Service is currently unavailable. Please try again later.");
// //             toast.error("Service Unavailable");
            
// //           } else {
// //             // Handle other statuses (e.g., 400 Bad Request, 401 Unauthorized)
// //             const data = await response.json();
// //             setError(data.error || "Login failed. Please try again.");
// //           }
// //         } catch (error) {
// //           console.error("Error logging in:", error);
// //           alert('Login failed. Please try again later.');
// //           setError("Login failed. Please try again.");
// //         }
// //       };

//   return (
//     <div className="auth-container">
//       <div className="auth-card ">
       
//         <div className="auth-header">
//           <h3>Welcome Back</h3>
//           <p className="subheading">Please login to continue</p>
//         </div>
//         <div className="auth-body">
//           <form onSubmit={handleLogin}>
//             <div className="form-group">
//               <label htmlFor="username">User Name</label>
//               <input
//                 className="form-control"
//                 id="username"
//                 type="text"
//                   name="username"
//                 value={username}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter your username"
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="inputPassword">Password</label>
//               <input
//                 className="form-control"
//                 id="inputPassword"
//                 type={showPassword ? 'text' : 'password'}
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 placeholder="Enter your password"
//                 required
//               />
//               {/* <button type="button" onClick={handlePasswordToggle}>
//                 {showPassword ? <ImEye /> : <PiEyeClosedBold />}
//               </button> */}
//             </div>
         
//             {error && <Alert variant="danger">{error}</Alert>}
//             <div className="form-group">
//               <button className="btn-auth">Login</button>
//             </div>
//             {/* <div className="auth-footer">
              
//               <p className="sign-up-prompt">
//                 Don't have an account? <a href="/signup">Sign up</a>
//               are you Admin Login <a href="/signup">Admin Login</a>
//               </p>
//             </div> */}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  // const handleLogin = async (e) => {
  //   e.preventDefault(); // Prevent form submission

  //   setLoading(true);
  //   setError(''); // Reset any previous error messages

  //   try {
  //     const response = await fetch('https://demochatapi.emedha.in/api/departuser');
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     localStorage.setItem('loginEmail',username)
  //     const usersData = await response.json();
  //     const user = usersData.find(user => user.username === username && user.password === password);

  //     if (user) {
  //       // Store user data in sessionStorage/localStorage
  //       sessionStorage.setItem('username', user.username);
  //       // sessionStorage.setItem('userName', user.username); // Use username instead of userName
  //       localStorage.setItem('userData', JSON.stringify(user));
        
  //       // Redirect based on user type
  //       switch (user.username) {
  //         case 'admin':
  //           navigate('/addAdmin');
  //           break;
        //  default:
        //     navigate('/dashbord'); // Default redirect
  //       }

  //       toast.success("Login successful!");
  //     } else {
  //       toast.error("Invalid credentials");
  //     }
  //   } catch (error) {
  //     console.error('Login error:', error);
  //     toast.error("Error during login, please try again later.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // const handleLogin = async (e) => {
  //   e.preventDefault(); // Prevent form submission
  

  //   setError(''); // Reset any previous error messages
  
  //   try {
  //     const response = await fetch('https://demochatapi.emedha.in/api/departuser');
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  
  //     // localStorage.setItem('loginEmail', username);
      
      // const usersData = await response.json();
      // console.log(usersData)
      // const user = usersData.find(user => user.username === username && user.password === password );
      // localStorage.setItem('userId', user.id);
    
  
  //     if (user) {
  //       // Store user data in sessionStorage/localStorage
  //       sessionStorage.setItem('username', user.username);
  //       localStorage.setItem('userData', JSON.stringify(user));
  
  //       // Redirect based on username or email
  //       // if (user.username === 'admin') {
  //       //   navigate('/dashbord'); // Redirect admin to addAdmin page
  //       // } else if (user.loginEmail === 'satyavarma0611@gmail.com') {
  //       //   navigate('/addAdmin'); // Redirect user with specific email to dashboard
  //       // } else {
  //       //   navigate('/dashbord'); // Redirect all other users to dashboard
  //       // }
      //   const token = localStorage.getItem('token');
      //   if (token) {
      //     navigate('/dashboard'); // Redirect user with specific email to dashboard
      //   } else {
      //     navigate('/dashbord'); // Redirect all other users to dashboard
      //   }
        
  
      //   toast.success("Login successful!");
      // } else {
      //   toast.error("Invalid credentials");
      // }
  //   } catch (error) {
    //   console.error('Login error:', error);
    //   toast.error("Error during login, please try again later.");
    // } finally {
  //     setLoading(false);
  //   }
  // };
  

//   const handleLogin = async (e) => {
//     e.preventDefault();
//    // Reset alert

//     try {
//         const response = await axios.post('http://localhost:3009/api/user-login', { username, password });
//         // Save user info to local storage or state
//         localStorage.setItem('userId', response.data.userId);
//         localStorage.setItem('userName', response.data.userName);
     
//         alert( 'Login successful!' );
//         const token = localStorage.getItem('token');
//         if (token) {
//           navigate('/dashboard'); // Redirect user with specific email to dashboard
//         } else {
//           navigate('/dashbord'); // Redirect all other users to dashboard
//         }
     

//     } catch (error) {
//       console.error('Login error:', error);
//       alert("Error during login, please try again later.");
//     } 
    
//     finally {
//         if (error.response) {
//             switch (error.response.status) {
//                 case 401:
//                   alert('Invalid username or password.')
//                     break;
//                 case 403:
//                   alert('User is inactive.');
//                     break;
//                 case 500:
//                   alert('Database.');
//                     break;
//                 default:
//                     alert('An unexpected error occurred. Please try again.');
//             }
//         } 
//     }
// };

// const handleLogin = async (e) => {
//   e.preventDefault();

//   try {
//     const response = await axios.post('https://demochatapi.emedha.in/api/user-login', { username, password });

//     // Save user info to local storage
//     localStorage.setItem('userId', response.data.userId);
//     localStorage.setItem('userName', response.data.userName);
//     localStorage.setItem('loginEmail', response.data.userName);

//     // Save token if provided
//     const token = localStorage.getItem('token'); // Adjust this line based on your API response
//     if (token) {
//       // localStorage.setItem('token', token); // Save the token
//       alert('Hello Super Admin Login successful!');
//       navigate('/dashboard'); // Redirect to dashboard
//     } else {
//       alert('Hello Admin Login successful ');
//       navigate('/dashbord'); // Redirect to dashboard
//     }

//   } catch (error) {
//     console.error('Login error:', error);
//     if (error.response) {
//       switch (error.response.status) {
//         case 401:
//           alert('Invalid username or password.');
//           break;
//         case 403:
//           alert('User is inactive. Please Contact through Super admin to Active');
          
//           break;
//         case 500:
//           alert('Database error.');
//           break;
//         default:
//           alert('An unexpected error occurred. Please try again.');
//       }
//     } else {
//       alert("Error during login, please try again later.");
//     }
//   }
// };

const handleLogin = async (e) => {
  e.preventDefault();
  // Make sure to initialize useNavigate

  try {
    const response = await axios.post('https://demochatapi.emedha.in/api/user-login', { username, password });

    // Save user info to local storage
    localStorage.setItem('userId', response.data.userId);
    localStorage.setItem('userName', response.data.userName);
    localStorage.setItem('loginEmail', response.data.userName);

    // Assuming token is part of the response
    const token = response.data.token; // Adjust this line based on your API response
    if (token) {
      localStorage.setItem('token', token); // Save the token
      await Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Hello Super Admin Login successful!',
      });
      navigate('/dashboard'); // Redirect to dashboard
    } else {
      await Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Hello Admin Login successful!',
      });
      navigate('/dashbord'); // Redirect to dashboard
    }

  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      let message;
      switch (error.response.status) {
        case 401:
          message = 'Invalid username or password.';
          break;
        case 403:
          message = 'User is inactive. Please contact Super Admin to activate.';
          break;
        case 500:
          message = 'Database error.';
          break;
        default:
          message = 'An unexpected error occurred. Please try again.';
      }
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
      });
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error during login, please try again later.',
      });
    }
  }
};


  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h3>Welcome Back</h3>
          <p className="subheading">Only Admin login to continue</p>
        </div>
        <div className="auth-body">
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="username">User Name</label>
              <input
                className="form-control"
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword">Password</label>
              <input
                className="form-control"
                id="inputPassword"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                
              />
           
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="form-group">
              <button className="btn-auth" >
                Login
              </button>
            </div>
          </form>
          <p>Go Back <a href="/">Click here</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
