

import React, { useEffect, useState } from "react";
import Sidebar from "../Chat/Sidebar";
import './Credits.css';
import { Col, Row, Table, Card, Dropdown, Pagination, FormControl } from "react-bootstrap";
import { GrMoney } from "react-icons/gr";
import { IoIosNotifications, IoIosSearch } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import Swal from 'sweetalert2';



const Credits = () => {
  const [superAdminCredits, setSuperAdminCredits] = useState('1000');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [amountToGive, setAmountToGive] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of users per page
  const navigate = useNavigate();

  
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://demochatapi.emedha.in/api/departuser');
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data); // Initialize filtered users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    useEffect(() => {

    fetchUsers();
  }, []);

  // Search filter
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    const filtered = users.filter(user => {
      const usernameMatch = user.username.toLowerCase().includes(value.toLowerCase());
      const creditsMatch = user.credits && user.credits.toString().includes(value);
      return usernameMatch || creditsMatch;
    });

    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // const handleGiveCredits = async () => {
  //   if (!selectedUser) {
  //     alert('Please select an admin.');
  //     return;
  //   }

  //   const credits = parseInt(amountToGive);

  //   if (isNaN(credits) || credits <= 0 || credits > superAdminCredits) {
  //     alert('Invalid amount. Please enter a valid credit amount.');
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`https://demochatapi.emedha.in/api/transactions/${selectedUser.id}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ username: selectedUser.username, credits }),
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       alert(error.message || 'Failed to give credits. Please try again.');
  //       return;
  //     }

  //     const result = await response.json();
  //     setSuperAdminCredits(superAdminCredits - credits);
  //     setAmountToGive('');
  //     setSelectedUser(null);
  //     alert(result.message || 'Credits allocated to User successfully!');
  //     window.location.reload();
  //   } catch (error) {
  //     console.error('Error giving credits:', error);
  //     alert('Server error. Please try again later.');
  //   }
  // };



  // Pagination logic
 
  const handleGiveCredits = async () => {
    if (!selectedUser) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please select an User.',
      });
      return;
    }
  
    const credits = parseInt(amountToGive);
  
    if (isNaN(credits) || credits <= 0 || credits > superAdminCredits) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Invalid amount. Please enter a valid Credit Amount.',
      });
      return;
    }
  
    try {
      const response = await fetch(`https://demochatapi.emedha.in/api/transactions/${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUser.username, credits, userId: selectedUser.id  }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'Failed to give credits. Please try again.',
        });
        return;
      }
  
      const result = await response.json();
setSuperAdminCredits(superAdminCredits - credits);
setAmountToGive('');
setSelectedUser(null);

Swal.fire({
  icon: 'success',
  title: 'Success',
  text: result.message || 'Credits allocated to User successfully!',
}).then(() => {
  // Reload the page after the user clicks "OK"
  fetchUsers();
});

    } catch (error) {
      console.error('Error giving credits:', error);
      Swal.fire({
        icon: 'error',
        title: 'Server Error',
        text: 'Please try again later.',
      });
    }
  };
  
 
 
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const items = [];

    // Previous button
    items.push(
      <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
        &lt;
      </Pagination.Item>
    );

    // Define range of pages to display
    const pageRange = 1; // Number of pages to show around the current page
    const startPage = Math.max(1, currentPage - pageRange);
    const endPage = Math.min(totalPages, currentPage + pageRange);

    // Page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Next button
    items.push(
      <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
        &gt;
      </Pagination.Item>
    );

    return items;
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleItemClick = (action) => {
    console.log(action);
    setDropdownVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('username');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const [searchTerm, setSearchTerm] = useState('');

  // Filter users based on the search term
  const filteredUserss = users.filter(user => 
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <Sidebar />
      <div id='main'>


        <div className="container-fluid bg-light p-3 shadow">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h3 className="mt-2"><strong>Credits</strong></h3>
            </div>
            <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
              <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
              <div style={{ position: 'relative' }}>
                <MdAccountCircle
                  size={30}
                  onClick={toggleDropdown}
                  style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                />
                {dropdownVisible && (
                  <div className="dropdown-menu show" style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                  }}>
                    <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                      <FaUserEdit style={{ marginRight: '8px' }} />
                      Edit Profile
                    </div>
                    <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                      <FaLock style={{ marginRight: '8px' }} />
                      Change Password
                    </div>
                    <div onClick={handleLogout} className="dropdown-item">
                      <FaSignOutAlt style={{ marginRight: '8px' }} />
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>




        <hr />

        <div>
          <Row>
           

            <Col className="text-end">
              <h2 style={{ color: 'black' }}>Credits <GrMoney style={{ fontSize: '20px', color: 'green' }} />: {superAdminCredits}</h2>
            </Col>
          </Row>


      <h4>Select User to Add Credits</h4> 
          <div style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
            {/* <div style={{ marginRight: '10px' }}>
              <Dropdown onSelect={(eventKey) => setSelectedUser(users.find(user => user.id.toString() === eventKey))}>
                <Dropdown.Toggle id="dropdown-basic" style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }}>
                  {selectedUser ? selectedUser.username : "Select an Admin"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {filteredUsers.map(user => (
                    <Dropdown.Item key={user.id} eventKey={user.id}>{user.username}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
                {/* <div style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '10px', width: '200px' }}>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }}>
            {selectedUser ? selectedUser.username : "Select an User"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <IoIosSearch

style={{ position: 'absolute',height:'70px', right: '10px', color: 'black' }}
size={20} 
/>
            <FormControl 
              type="text" 
              placeholder="Search..." 
              onChange={(e) => setSearchTerm(e.target.value)} 
              style={{ width: '100%', margin: '10px 0' }}
            />
            
            {filteredUserss.length > 0 ? (
              filteredUserss.map(user => (
                <Dropdown.Item 
                  key={user.id} 
                  eventKey={user.id} 
                  onClick={() => setSelectedUser(user)}
                >
                  
                  {user.username}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>No users found</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>

      </div>
               </div>
            <div style={{ height: '50px', padding: '5px', borderRadius: '10px', marginRight: '10px' }}>
              <input
                type="number"
                value={amountToGive}
                onChange={(e) => setAmountToGive(e.target.value)}
                placeholder="Enter Credits "
              />
            </div>


            <div>
              <button style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleGiveCredits}>Submit</button>
            </div> */}
<div style={{
  padding: '20px',
  display: 'flex',
  flexDirection: window.innerWidth < 768 ? 'column' : 'row', // Stack on small screens
  alignItems: 'center',
}}>
  <div style={{
    marginRight: '10px',
    width: window.innerWidth < 768 ? '100%' : '200px', // Full width on small screens
  }}>
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" style={{
        height: '50px',
        borderColor:'black',
        backgroundColor: 'white',
        textTransform: 'uppercase',
        color:'black',
        width: '100%', // Ensure it fills available space
      }}>
        {selectedUser ? selectedUser.username : "Select an User"}
      </Dropdown.Toggle>


      {/* <Dropdown.Menu className="dropdown-menu-scrollable">
        <IoIosSearch
          style={{ position: 'absolute', height: '70px', right: '10px', color: 'black' }}
          size={20}
        />
        {/* <FormControl
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '100%', margin: '10px 0' }}
        />  
         <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
              
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search "
                className="form-control"
                style={{
                  paddingLeft: '40px', // Space for the icon
                  paddingRight: '40px', // Space for any right-side elements
                  borderRadius: '25px', // Rounded corners
                  border: '1px solid black', // Border color

                  transition: 'border-color 0.3s',
                }}
              
              />
              <IoIosSearch

                style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
                size={20} 
              />
            </div>
        {filteredUserss.length > 0 ? (
          filteredUserss.map(user => (
            <Dropdown.Item 
              key={user.id}
              eventKey={user.id}
              onClick={() => setSelectedUser(user)}
            >
              {user.username}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>No users found</Dropdown.Item>
        )}
      </Dropdown.Menu> */}

<Dropdown.Menu  style={{ width: '300px' }}>
  <div className="dropdown-menu-scrollable" style={{ position: 'relative', marginBottom: '10px' }}>
    <IoIosSearch
      style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#6c757d' }}
      size={20}
    />
    <input
      type="text"
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search"
      className="form-control"
      style={{
        paddingLeft: '40px', // Space for the icon
        paddingRight: '40px', // Space for any right-side elements
        borderRadius: '25px', // Rounded corners
        border: '1px solid black', // Border color
        transition: 'border-color 0.3s',
        height: '40px', // Fixed height
      }}
    />
  </div>

  <div className="dropdown-menu-scrollable" style={{ maxHeight: '200px', overflowY: 'auto' }}>
    {filteredUserss.length > 0 ? (
      filteredUserss.map(user => (
        <Dropdown.Item 
          key={user.id}
          eventKey={user.id}
          onClick={() => setSelectedUser(user)}
        >
          {user.username}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item disabled>No users found</Dropdown.Item>
    )}
  </div>
</Dropdown.Menu>


    </Dropdown>
  </div>

  <div style={{
    height: '50px',
    padding: '5px',
    borderRadius: '10px',
    marginRight: '10px',
    width: window.innerWidth < 768 ? '100%' : 'auto', // Full width on small screens
  }}>
    <input
      type="number"
      value={amountToGive}
      onChange={(e) => setAmountToGive(e.target.value)}
      placeholder="Enter Credits"
      style={{ width: '100%',borderColor:'black' }} // Full width input
    />
  </div>

  <div>
    <button style={{
      height: '50px',
      backgroundColor: 'rgb(125, 133, 195)',
      width: '100%', // Full width on small screens
    }} onClick={handleGiveCredits}>Submit</button>
  </div>
</div>




          </div>



          <h3>User Credits Details</h3>
          <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={searchInput}
                onChange={handleSearch}
                placeholder="Search "
                className="form-control"
                style={{
                  paddingLeft: '40px', // Space for the icon
                  paddingRight: '40px', // Space for any right-side elements
                  borderRadius: '25px', // Rounded corners
                  border: '1px solid #ced4da', // Border color

                  transition: 'border-color 0.3s',
                }}
                onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
                onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
              />
              <IoIosSearch

                style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
                size={20} 
              />
            </div>
            <br></br>
          <Table  striped bordered hover>
            <thead>
              <tr>
                <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>ID</th>
                <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Admin Username</th>
                <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Total Credits</th>
              </tr>
            </thead>
            {/* <tbody>
              {currentUsers.map((user, index) => (
                <tr key={user.id}>
              
                  <td>{index + 1 + currentPage * usersPerPage}</td>
                  <td>{user.username}</td>
                  <td>{user.credits || 0}</td>
                </tr>
              ))}
            </tbody> */}
            <tbody>
  {currentUsers.map((user, index) => (
    <tr key={user.id}>
      <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
      <td>{user.username}</td>
      <td>{user.credits || 0}</td>
    </tr>
  ))}
</tbody>

          </Table>

          {/* Pagination Controls */}
          <Pagination className='d-flex flex-row justify-content-end '>
            {renderPaginationItems()}
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default Credits;


// import React, { useEffect, useState } from "react";
// import Sidebar from "../Chat/Sidebar";
// import './Credits.css';
// import { Col, Row, Table, Pagination } from "react-bootstrap";
// import { GrMoney } from "react-icons/gr";
// import { IoIosNotifications, IoIosSearch } from "react-icons/io";
// import { MdAccountCircle } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
// import Swal from 'sweetalert2';

// const Credits = () => {
//   const [superAdminCredits, setSuperAdminCredits] = useState('1000');
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [amountToGive, setAmountToGive] = useState('');
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [userSearchInput, setUserSearchInput] = useState('');
//   const [userSelectInput, setUserSelectInput] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;
//   const navigate = useNavigate();
//   const [userDropdownVisible, setUserDropdownVisible] = useState(false);


//     const fetchUsers = async () => {
//       try {
//         const response = await fetch('https://demochatapi.emedha.in/api/departuser');
//         const data = await response.json();
//         setUsers(data);
//         setFilteredUsers(data);
//       } catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };

//     useEffect(() => {

//     fetchUsers();
//   }, []);

//   // Handle search for user selection dropdown
//   const handleUserSelectInputChange = (e) => {
//     const value = e.target.value;
//     setUserSelectInput(value);
//     const filtered = users.filter(user =>
//       user.username.toLowerCase().includes(value.toLowerCase())
//     );
//     setFilteredUsers(filtered);
//   };

//   // Handle search for user table
//   const handleUserSearchInputChange = (e) => {
//     const value = e.target.value;
//     setUserSearchInput(value);
//     const filtered = users.filter(user =>
//       user.username.toLowerCase().includes(value.toLowerCase())
//     );
//     setFilteredUsers(filtered);
//     setCurrentPage(1); // Reset to first page on search
//   };

//   const handleGiveCredits = async () => {
//     if (!selectedUser) {
//       Swal.fire({
//         icon: 'warning',
//         title: 'Validation Error',
//         text: 'Please select a User.',
//       });
//       return;
//     }

//     const credits = parseInt(amountToGive);
//     if (isNaN(credits) || credits <= 0 || credits > superAdminCredits) {
//       Swal.fire({
//         icon: 'warning',
//         title: 'Validation Error',
//         text: 'Invalid amount. Please enter a valid Credit Amount.',
//       });
//       return;
//     }

//     try {
//       const response = await fetch(`https://demochatapi.emedha.in/api/transactions/${selectedUser.id}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ username: selectedUser.username, credits }),
//       });

//       if (!response.ok) {
//         const error = await response.json();
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: error.message || 'Failed to give credits. Please try again.',
//         });
//         return;
//       }

//       const result = await response.json();
//       setSuperAdminCredits(superAdminCredits - credits);
//       setAmountToGive('');
//       setSelectedUser(null);


//       Swal.fire({
//         icon: 'success',
//         title: 'Success',
//         text: result.message || 'Credits allocated to User successfully!',
//       }).then(() => {
//             fetchUsers();
//       });
//     } catch (error) {
//       console.error('Error giving credits:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Server Error',
//         text: 'Please try again later.',
//       });
//     }
//   };

//   const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
//   const currentUsers = filteredUsers.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPaginationItems = () => {
//     const items = [];
//     items.push(
//       <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//         &lt;
//       </Pagination.Item>
//     );

//     for (let i = 1; i <= totalPages; i++) {
//       items.push(
//         <Pagination.Item
//           key={i}
//           active={i === currentPage}
//           onClick={() => handlePageChange(i)}
//         >
//           {i}
//         </Pagination.Item>
//       );
//     }

//     items.push(
//       <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//         &gt;
//       </Pagination.Item>
//     );

//     return items;
//   };

//   const toggleUserDropdown = () => {
//     setUserDropdownVisible(!userDropdownVisible);
//   };

//   const handleUserSelect = (user) => {
//     setSelectedUser(user);
//     setUserSelectInput(user.username);
//     setUserDropdownVisible(false);
//     setUserDropdownVisiblee(false);
//     };

//     const [dropdownVisible, setDropdownVisible] = useState(false);

//     const toggleDropdown = () => {
//       setDropdownVisible(!dropdownVisible);
//     };
  
//     const handleItemClick = (action) => {
//       console.log(action);
//       setDropdownVisible(false);
//     };

//   const handleLogout = () => {
//     localStorage.removeItem('userData');
//     localStorage.removeItem('token');
//     localStorage.removeItem('loginEmail');
//     localStorage.removeItem('rememberMe');
//     sessionStorage.removeItem('username');
//     localStorage.removeItem('userId');
//     navigate('/');
//   };
  
//   const [userDropdownVisiblee, setUserDropdownVisiblee] = useState(false);
//   const toggleUserDropdownn = () => {
//     setUserDropdownVisiblee(!userDropdownVisiblee);
//   };
  

  
//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <div className="container-fluid bg-light p-3 shadow">
//           <div className="row justify-content-between align-items-center">
//             <div className="col-auto">
//               <h3 className="mt-2"><strong>Manage Credits</strong></h3>
//             </div>
//             <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//               <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
//               <div style={{ position: 'relative' }}>
//                 <MdAccountCircle
//                   size={30}
//                   onClick={toggleDropdown}
//                   style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
//                 />
//                 {userDropdownVisible && (
//                   <div className="dropdown-menu show" style={{
//                     position: 'absolute',
//                     right: 0,
//                     backgroundColor: 'white',
//                     border: '1px solid #ccc',
//                     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     zIndex: 1,
//                   }}>
//                                       <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
//                       <FaUserEdit style={{ marginRight: '8px' }} />
//                       Edit Profile
//                     </div>
//                     <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
//                       <FaLock style={{ marginRight: '8px' }} />
//                       Change Password
//                     </div>
//                     <div onClick={handleLogout} className="dropdown-item">
//                       <FaSignOutAlt style={{ marginRight: '8px' }} />
//                       Logout
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         <hr />

//         <div>
//           <Row>
//             <Col className="text-end">
//               <h2 style={{ color: 'black' }}>Credits <GrMoney style={{ fontSize: '20px', color: 'green' }} />: {superAdminCredits}</h2>
//             </Col>
//           </Row>

//           <h4>Select User to Add Credits</h4>
//           <div style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
//             <div style={{ position: 'relative', width: '200px', marginRight: '10px' }}>
//               <input
//                 type="text"
//                 value={userSelectInput}
//                 onChange={handleUserSelectInputChange}
//                 placeholder="Select User"
//                 className="form-control"
//                 style={{ width: '200px', padding: '10px', borderRadius: '5px', border: '1px solid #ced4da' }}
//                 onFocus={() => setUserDropdownVisiblee(true)}
//                 onBlur={() => setTimeout(() => setUserDropdownVisiblee(false), 100)}
//               />
//               <IoIosSearch
//                 style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#ced4da' }}
//               />
//               {userDropdownVisiblee && (
//                 <div className="dropdown-menu show" style={{ position: 'absolute', width: '100%', zIndex: 1 }}>
//                   {filteredUsers.length > 0 ? (
//                     filteredUsers.map((user) => (
//                       <div
//                         key={user.id}
//                         eventKey={user.id} 
//                         onClick={() => handleUserSelect(user)}
//                         className="dropdown-item"
//                       >
//                         {user.username}
//                       </div>
//                     ))
//                   ) : (
//                     <div className="dropdown-item disabled">No users found</div>
//                   )}
//                 </div>
//               )}
//             </div>

//             <div style={{ height: '50px', padding: '5px', borderRadius: '10px', marginRight: '10px' }}>
//               <input
//                 type="number"
//                 value={amountToGive}
//                 onChange={(e) => setAmountToGive(e.target.value)}
//                 placeholder="Enter Credits"
//                 style={{ width: '100%' }}
//               />
//             </div>

//             <div>
//               <button style={{ height: '50px', backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleGiveCredits}>Submit</button>
//             </div>
//           </div>

//           <h3>User Credits Details</h3>

//           {/* User search input for filtering table */}
//           <div style={{ marginBottom: '15px' }}>
//             <input
//               type="text"
//               value={userSearchInput}
//               onChange={handleUserSearchInputChange}
//               placeholder="Search Users"
//               style={{ width: '200px', padding: '10px', borderRadius: '5px', border: '1px solid #ced4da' }}
//             />
//           </div>

//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Admin Username</th>
//                 <th>Total Credits</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentUsers.map((user, index) => (
//                 <tr key={user.id}>
//                   <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
//                   <td>{user.username}</td>
//                   <td>{user.credits || 0}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           {/* Pagination Controls */}
//           <Pagination className='d-flex flex-row justify-content-end'>
//             {renderPaginationItems()}
//           </Pagination>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Credits;
