// import React, { useState } from 'react';

// const SendMessage = () => {
//   const [srcMobile, setSrcMobile] = useState('');
//   const [destMobile, setDestMobile] = useState('');
//   const [message, setMessage] = useState('');
//   const [response, setResponse] = useState(null);
//   const [error, setError] = useState(null);

//   const sendMessage = async () => {
//     try {
//       const res = await fetch(`https://emedha.com/chatapp/sendmgr.php?srcmobile=${srcMobile}&destmobile=${destMobile}&message=${encodeURIComponent(message)}`);
//       if (!res.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const contentType = res.headers.get("content-type");
//       let data;

//       if (contentType && contentType.includes("application/json")) {
//         data = await res.json(); // Parse as JSON if content type is JSON
//       } else {
//         data = await res.text(); // Otherwise, treat it as plain text
//       }

//       setResponse(data);
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   return (
//     <div>
//       <div>
//         <label>
//           Source Mobile:
//           <input type="text" value={srcMobile} onChange={(e) => setSrcMobile(e.target.value)} />
//         </label>
//       </div>
//       <div>
//         <label>
//           Destination Mobile:
//           <input type="text" value={destMobile} onChange={(e) => setDestMobile(e.target.value)} />
//         </label>
//       </div>
//       <div>
//         <label>
//           Message:
//           <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
//         </label>
//       </div>
//       <button onClick={sendMessage}>Send Message</button>
//       {error && <div>Error: {error}</div>}
//       {response && (
//         <div>
//           <h3>Response:</h3>
//           <p>{typeof response === 'string' ? response : JSON.stringify(response)}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SendMessage;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SendMessage = () => {
  const [srcMobile, setSrcMobile] = useState('');
  const [destMobile, setDestMobile] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const sendMessage = async () => {
    try {
      const res = await fetch(`https://emedha.com/chatapp/sendmgr.php?srcmobile=${srcMobile}&destmobile=${destMobile}&message=${encodeURIComponent(message)}`);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      const contentType = res.headers.get("content-type");
      let data;

      if (contentType && contentType.includes("application/json")) {
        data = await res.json(); // Parse as JSON if content type is JSON
      } else {
        data = await res.text(); // Otherwise, treat it as plain text
      }

      setResponse(data);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Send Message</h2>
      <div className="card p-4">
        <div className="mb-3">
          <label className="form-label">Source Mobile:</label>
          <input 
            type="text" 
            className="form-control" 
            value={srcMobile} 
            onChange={(e) => setSrcMobile(e.target.value)} 
            placeholder="Enter source mobile number" 
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Destination Mobile:</label>
          <input 
            type="text" 
            className="form-control" 
            value={destMobile} 
            onChange={(e) => setDestMobile(e.target.value)} 
            placeholder="Enter destination mobile number" 
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Message:</label>
          <textarea 
            className="form-control" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            placeholder="Enter your message" 
          />
        </div>
        <button className="btn btn-primary" onClick={sendMessage}>Send Message</button>
      </div>

      {error && <div className="alert alert-danger mt-3">Error: {error}</div>}
      {response && (
        <div className="alert alert-success mt-3">
          <h5>Response:</h5>
          <p>{typeof response === 'string' ? response : JSON.stringify(response)}</p>
        </div>
      )}
    </div>
  );
};

export default SendMessage;
